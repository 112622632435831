import { AuthenticationResult } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { message } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import './RegisterUserPage.css'

const RedirectMsPage = () => {
  const { t } = useTranslation()
  const { instance } = useMsal()
  const history = useHistory()
  const [isConfirmMe, setIsConfirmMe] = useState<
    AuthenticationResult | undefined
  >(undefined)

  useEffect(() => {
    message.info(t('マイクロソフト認証からリダイレクトされました'))
    instance
      .handleRedirectPromise()
      .then((response) => {
        if (response) {
          message.success(`Login success: ${JSON.stringify(response)}`)
          // history.push('/confirm-me')
          setIsConfirmMe(response)
        } else {
          message.error('No response, redirecting to login...')
          instance.loginRedirect() // Only redirect if no response
        }
      })
      .catch((error) => {
        console.error('Login error:', error)
        message.error('Login error')
        history.push('/register')
      })
  }, [])

  if (isConfirmMe) {
    return (
      <div>
        Confirm Me
        <div>Name: {isConfirmMe.account?.name}</div>
        <div>Email: {isConfirmMe.account?.username}</div>
      </div>
    )
  }

  return <p>...</p>
}

export default RedirectMsPage
