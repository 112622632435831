/**
 * For example, iPhone's height is 659, so it's a low height screen.
 */
export const isLowHeightScreen = window.innerHeight < 720

export const checkIfMobileScreen = () => window.innerWidth <= 540
export const checkIfSmallMobileScreen = () => window.innerWidth <= 400
// export const isTabletScreen = () =>
//   window.innerWidth > 540 && window.innerWidth <= 1024
export const isDesktopScreen = window.innerWidth > 1024
export const isSpScreen = checkIfMobileScreen()
