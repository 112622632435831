import { useCallback, useEffect, useRef, useState } from 'react'
import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from '~/alpha/core'
import { useAlphaStore } from '~/context'
import {
  LOCAL_STORAGE_WEB_APP_VER_KEY,
  WEB_APP_VERSION_CHECK_TIME_KEY,
} from '~/student-utils/const'
import { clearStorageAndAuthToken } from '~/student-utils/storages'
import Api from '~/utils/api'
import { CommonError } from '~/utils/error'
import { IStudentMe } from '~/utils/types/api/students'

interface QueryResult<T> {
  data: T | undefined
  loading: boolean
  error: CommonError | undefined
}

// Cache storage for useQuery
const queryCache = new Map<
  string,
  {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    data: any
    timestamp: number
    /** determines if the data is stale / not fresh / outdated */
    isStale: boolean
  }
>()

/**
 * Caching is done with a TTL (time to live) of number of seconds
 * in milliseconds
 */
const CACHE_TTL = 60000

export const useCustomQuery = <T>({
  queryFn,
  cacheKey,
}: {
  queryFn: () => Promise<T>
  cacheKey?: string
}): QueryResult<T> & { invalidateCache: () => void } => {
  const [data, setData] = useState<T | undefined>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<CommonError | undefined>()

  const invalidateCache = useCallback(() => {
    if (cacheKey) {
      const cached = queryCache.get(cacheKey)
      if (cached) {
        cached.isStale = true
      }
    }
  }, [cacheKey])

  useEffect(() => {
    const fetchData = async () => {
      // Check cache if cacheKey is provided
      if (cacheKey) {
        const cached = queryCache.get(cacheKey)

        if (cached && !cached.isStale) {
          const now = Date.now()

          if (now - cached.timestamp < CACHE_TTL) {
            setData(cached.data)
            return
          }
        }
      }

      try {
        setLoading(true)
        const res = await queryFn()
        setData(res)
        setError(undefined)

        // Update cache if cacheKey is provided
        if (cacheKey) {
          queryCache.set(cacheKey, {
            data: res,
            timestamp: Date.now(),
            isStale: false,
          })
        }
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      } catch (err: any) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    data,
    loading,
    error,
    invalidateCache,
  }
}

/**
 * @param apiUrl
 * @returns
 */
const useAuthApiGet = <TResponse = any>(
  apiUrl: string,
): {
  data: TResponse | undefined
  loading: boolean
  error: CommonError | undefined
} => {
  const authToken = window.localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)

  const [data, setData] = useState<TResponse | undefined>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<CommonError | undefined>()
  const { me } = useAlphaStore()

  const fetchedRef = useRef(false)

  useEffect(() => {
    if (authToken && !fetchedRef.current) {
      // biome-ignore lint/suspicious/noAsyncPromiseExecutor: <explanation>
      new Promise(async () => {
        try {
          setLoading(true)
          const { data }: { data: TResponse } = await Api.get(apiUrl)

          if (apiUrl.includes('/me')) {
            const newVer = (data as IStudentMe).version

            if (newVer) {
              localStorage.setItem(LOCAL_STORAGE_WEB_APP_VER_KEY, newVer)
            }

            localStorage.setItem(
              WEB_APP_VERSION_CHECK_TIME_KEY,
              new Date().getTime().toString(),
            )

            if (me && me.version !== (data as IStudentMe).version) {
              // Current webApp version is older than server, so have to reload.
              // By default, it performs a normal reload which may use cached
              // resources including JS bundles.
              // The actual caching behavior depends on:
              // - Your server's cache control headers
              // - How your bundler is configured (e.g., if it includes content
              // hashes in filenames)
              // setMe(data as IStudentMe)
              window.location.reload()
            }
          }

          setData(data)
          setError(undefined)
        } catch (err: any) {
          console.error(`apiUrl: ${apiUrl} - error:`, err)

          if (apiUrl.includes('/me') && err.response?.status === 401) {
            clearStorageAndAuthToken()

            // keep this log
            console.warn(
              'When response status is unauthorized (401) at useAuthApiGet, logout!',
            )
          }

          setError(err)
        } finally {
          fetchedRef.current = false
          setLoading(false)
        }
      })
    } else {
      // keep this log
      console.log(
        'Not found auth token and fetchedRef.current is true when useAuthApiGet, do nothing!',
      )
      setLoading(false)
      setError(undefined)
    }
  }, [apiUrl, authToken])

  return {
    data: authToken ? data : undefined,
    loading,
    error,
  }
}

/**
 * Alpha student apis
 * get studentWebRouteNames from req's params
 * @param routeNameIdParam string
 * @returns
 */
export const useStudentMeApiQuery = (routeNameIdParam: string) =>
  useAuthApiGet<IStudentMe>(`/alpha/v1/student/me?route=${routeNameIdParam}`)
