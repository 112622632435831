import { Button } from 'antd'
import { TFunction } from 'i18next'
import { memo } from 'react'
import { IStudentBirthdayAndAttributes } from '../types'

interface RegisterConfirmForSsoManyAccProps {
  isUsingJp: boolean
  t: TFunction<'translation', undefined>
  checkUser: (invitationCode: string, userId?: string) => void | Promise<void>
  inputtedCode: string | null
  user: IStudentBirthdayAndAttributes
  isSpScreen: boolean
}

const RegisterConfirmForSsoManyAcc = ({
  isUsingJp,
  t,
  checkUser,
  inputtedCode,
  user,
  isSpScreen,
}: RegisterConfirmForSsoManyAccProps) => {
  const studentAttr = user.attributes
  const userId = user._id

  return (
    <div className="bg-gray-150 py-10 rounded-10px">
      {!isSpScreen && (
        <div className="show-pc w-498px">
          <div className="space-y-7 pl-24 pr-16">
            <div className="flex">
              <div className="flex-1 text-left flex items-center">
                <ruby className="font-black">
                  {t('名前')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">なまえ</rt>
                  ) : null}
                </ruby>
              </div>

              <div className="text-lg text-center">
                {t('フルネーム', {
                  familyName: studentAttr.familyName,
                  givenName: studentAttr.givenName,
                })}
              </div>
              <div className="flex-1" />
            </div>
            <div className="flex">
              <div
                className="flex-1 text-left flex items-center"
                style={{ minWidth: 50 }}
              >
                <ruby className="font-black">
                  {t('学校名')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">がっこうめい</rt>
                  ) : null}
                </ruby>
              </div>
              <div className="text-lg text-center">
                {studentAttr.schoolName}
              </div>
              <div className="flex-1" />
            </div>
            <div className="flex">
              <div className="flex-1 text-left">
                <ruby className="font-black mr-5">
                  {t('学年')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">がくねん</rt>
                  ) : null}
                </ruby>
                <span className="text-lg">{studentAttr.schoolGrade}</span>
              </div>
              <div className="flex-1 text-left">
                <ruby className="font-black mr-5">{t('クラス')}</ruby>
                <span className="text-lg">{studentAttr.schoolClass}</span>
              </div>
              <div className="flex-1 text-left">
                <ruby className="font-black mr-5">
                  {t('出席番号')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">しゅっせきばんごう</rt>
                  ) : null}
                </ruby>
                <span className="text-lg">
                  {studentAttr.schoolAttendanceNumber}
                </span>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center mt-8">
            <Button
              type="primary"
              className="w-32"
              size="large"
              onClick={() => checkUser(inputtedCode as string, userId)}
            >
              {t('選択')}
            </Button>
          </div>
        </div>
      )}

      {isSpScreen && (
        <div className="show-sp px-8">
          <div className="space-y-4">
            <div className="flex">
              <div className="flex-1 text-left w-24 flex items-center">
                <ruby className="font-black">
                  {t('名前')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">なまえ</rt>
                  ) : null}
                </ruby>
              </div>

              <div className="text-lg text-center">
                {studentAttr.familyName} {studentAttr.givenName}
              </div>
              <div className="flex-1" />
            </div>

            <div className="flex">
              <div
                className="flex-1 text-left flex items-center"
                style={{ minWidth: 50 }}
              >
                <ruby className="font-black">
                  {t('学校名')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">がっこうめい</rt>
                  ) : null}
                </ruby>
              </div>
              <div className="text-lg text-center">
                {studentAttr.schoolName}
              </div>
              <div className="flex-1" />
            </div>

            <div className="flex">
              <div className="flex-1 text-left">
                <ruby className="font-black mr-5">
                  {t('学年')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">がくねん</rt>
                  ) : null}
                </ruby>
                <span className="text-lg">{studentAttr.schoolGrade}</span>
              </div>
              <div className="flex-1 text-left">
                <ruby className="font-black mr-5">クラス</ruby>
                <span className="text-lg">{studentAttr.schoolClass}</span>
              </div>
              <div className="flex-1 text-left">
                <ruby className="font-black mr-5">
                  {t('出席番号')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">しゅっせきばんごう</rt>
                  ) : null}
                </ruby>
                <span className="text-lg">
                  {studentAttr.schoolAttendanceNumber}
                </span>
              </div>
            </div>
          </div>

          <div className="space-x-4 flex items-center justify-center mt-3">
            <Button
              type="primary"
              className="w-30"
              onClick={() => checkUser(inputtedCode as string, userId)}
            >
              {t('選択')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(RegisterConfirmForSsoManyAcc)
