import axios from 'axios'
import {
  AUTH_TOKEN_KEY,
  LOCAL_STORAGE_AUTH_TOKEN_KEY,
  SCHOOL_LEVEL,
} from '~/alpha/core/auth/AuthProvider'
import {
  LOCAL_STORAGE_LANGUAGE_KEY,
  LOCAL_STORAGE_POPUP,
} from '~/utils/constants'
import { LOCAL_STORAGE_WEB_APP_VER_KEY } from './const'

const clearedStorageKeys = [
  LOCAL_STORAGE_AUTH_TOKEN_KEY,
  LOCAL_STORAGE_POPUP,
  SCHOOL_LEVEL,
  LOCAL_STORAGE_WEB_APP_VER_KEY,
  /**
   * {@link https://github.com/carvajalconsultants/apollo-link-offline}
   */
  'offlineLinkAttemptIds',
] as const

export const clearLocalStorage = () => {
  for (const key of clearedStorageKeys) {
    window.localStorage.removeItem(key)
  }
}

export const clearStorageAndAuthToken = () => {
  clearLocalStorage()
  delete axios.defaults.headers.common[AUTH_TOKEN_KEY]
}

export const getObjFromLocalStorage = <T = unknown>(
  key:
    | typeof LOCAL_STORAGE_AUTH_TOKEN_KEY
    | typeof LOCAL_STORAGE_WEB_APP_VER_KEY
    | typeof LOCAL_STORAGE_LANGUAGE_KEY
    | typeof SCHOOL_LEVEL,
): null | T => {
  try {
    return JSON.parse(window.localStorage.getItem(key) as string) as null | T
  } catch (err) {
    console.error(`getObjFromLocalStorage - ${key}`, err)

    return null
  }
}
