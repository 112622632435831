import { Rank } from './fitnessTest'
import { allTestKeys } from './index'

export const computeTotalPointIfHasEndurance = (
  baseTotalPoint: number,
  enduranceRun: number,
  shuttleRun: number,
  hasEnduranceRun: boolean,
): number => {
  if (hasEnduranceRun && enduranceRun > shuttleRun) {
    return baseTotalPoint + enduranceRun
  }

  return baseTotalPoint + shuttleRun
}

export type TEachTestRes =
  | {
      studentId?: string
      points?: number | null
      value?: null | number
      nextPoints?: number | null
      rating: number
      isNotMeasurable: boolean
      memo: null | string | undefined
      runningTime?: number | null
      runningTimeSeconds?: number | null
      sitUps?: number | null
    }
  | null
  | undefined

export type TTestResultWithPoint = Record<
  (typeof allTestKeys)[number] | 'sizeTest',
  TEachTestRes
> & {
  points?: number | null
  rank: Rank | ''
  nextPoints: number | undefined
  age: number
  rating?: number
  sizeTest?: { height?: null | number; weight?: number | null } | null
}

export const computeTotalPoint = (
  hasEnduranceRun: boolean,
  result: TTestResultWithPoint,
): number | undefined => {
  const grip = result.grip?.points ?? 0
  const bending = result.bending?.points ?? 0
  const handballThrow = result.handballThrow?.points ?? 0
  const shuttleRun = result.shuttleRun?.points ?? 0
  const sideJump = result.sideJump?.points ?? 0
  const sitUps = result.sitUps?.points ?? 0
  const sprintRun = result.sprintRun?.points ?? 0
  const standingJump = result.standingJump?.points ?? 0
  const enduranceRun = result.enduranceRun?.points ?? 0

  const baseTotalPoint =
    grip +
    bending +
    handballThrow +
    sideJump +
    sitUps +
    sprintRun +
    standingJump

  const totalPoint = computeTotalPointIfHasEndurance(
    baseTotalPoint,
    enduranceRun,
    shuttleRun,
    hasEnduranceRun,
  )

  return totalPoint === 0 ? undefined : totalPoint
}
