import { Form, message } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'
import { useTranslation } from 'react-i18next'
import Api from '~/utils/api'
import { getLastIndex, lastMonth, thisYear } from '~/utils/constants'
import { IGraphData, ISportRes } from './types'

interface MonthDay {
  date: string
  key: number
}

export const getDaysInMonth = (year: number, month: number) => {
  const monthDays: MonthDay[] = []
  const dateMonth = new Date(year, month, 0).getDate()

  for (let x = 1; x <= dateMonth; x++) {
    monthDays.push({
      date: `${month}/${x}`,
      key: x,
    })
  }

  return monthDays
}

export const getYearMonths = (year: number) => {
  const yearMonth: MonthDay[] = []

  for (let x = 1; x <= 12; x++) {
    yearMonth.push({
      date: `${year}/${x}`,
      key: x,
    })
  }

  return yearMonth
}

export const useMonthDaysChart = (
  userId: string,
  tabKey: string,
  activatedTab: string,
  dateTime: any,
  testKey: string,
) => {
  const { t } = useTranslation()

  const [form] = Form.useForm()

  const lineChart = useRef<ChartJSOrUndefined<'line', number[], string> | null>(
    undefined,
  )

  const [disabled, setDisabled] = useState(true)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [graphMonth, setGraphMonth] = useState<IGraphData>({} as IGraphData)
  const [graphYear, setGraphYear] = useState<IGraphData>({} as IGraphData)
  const [testResultResponse, setTestResultResponse] = useState<
    {
      date: string
      value: number
    }[]
  >([])

  const [active, setActive] = useState(0)

  const [month, setMonth] = useState(lastMonth + 1)
  const [year, setYear] = useState(thisYear)

  const [loading, setLoading] = useState(false)
  const [sportsValue, setSportValues] = useState<ISportRes | undefined>()

  const daysInMonth = useMemo(() => getDaysInMonth(year, month), [month, year])
  const monthsWithYear = useMemo(() => getYearMonths(year), [year])

  const dataChartMonth: Record<string, number> = {}
  const dataChartYear: Record<string, number> = {}

  graphMonth?.dataGraph?.forEach((item) => {
    dataChartMonth[moment(item.date).format('M/D')] = item.value
  })

  const parseGraphYear = graphYear?.dataGraph
    ?.filter((item) => item.value !== 0)
    .map((val) => ({
      ...val,
      date: moment(val.date).format('YYYY/M'),
    }))

  const arrGraphYear = _.map(_.groupBy(parseGraphYear, 'date'), (g) =>
    _.maxBy(g, 'value'),
  )

  arrGraphYear?.forEach((item) => {
    dataChartYear[item!.date] = item!.value
  })

  const daysInMonthLabel = daysInMonth.map((item) => item.date)
  const monthsInYearLabel = monthsWithYear.map((item) => item.date)

  const firstMonthIndex = daysInMonth.findIndex(
    (item) => !!dataChartMonth[item.date],
  )
  const lastMonthIndex = getLastIndex(
    daysInMonth,
    (item) => !!dataChartMonth[item.date],
  )
  const firstYearIndex = monthsWithYear.findIndex(
    (item) => !!dataChartYear[item.date],
  )
  const lastYearIndex = getLastIndex(
    monthsWithYear,
    (item) => !!dataChartYear[item.date],
  )
  const monthGraph = daysInMonth
    .filter(
      (item, index) =>
        index < firstMonthIndex ||
        index > lastMonthIndex ||
        (dataChartMonth[item.date] &&
          index >= firstMonthIndex &&
          index <= lastMonthIndex),
    )
    .map((item) => ({ x: item.date, y: dataChartMonth[item.date] || 0 }))
  const yearGraph = monthsWithYear
    .filter(
      (item, index) =>
        index < firstYearIndex ||
        index > lastYearIndex ||
        (dataChartYear[item.date] &&
          index >= firstYearIndex &&
          index <= lastYearIndex),
    )
    .map((item) => ({ x: item.date, y: dataChartYear[item.date] || 0 }))

  const chartData = {
    labels: active === 0 ? daysInMonthLabel : monthsInYearLabel,
    datasets: [
      {
        data: active === 0 ? monthGraph : yearGraph,
        borderColor: '#036EB8',
        backgroundColor: '#036EB8',
        radius: (ctx) => (ctx.parsed.y === 0 ? 0 : 4),
      },
    ],
  }

  const getValueData = async () => {
    setLoading(true)

    const apiUrl = `/alpha/v1/student/getValueTrainingSport/${userId}?trainingSport=${tabKey}`
    const { data: dataTraining } = await Api.get<{ data: ISportRes }>(apiUrl)

    if (dataTraining.data) {
      setSportValues(dataTraining.data)
    }

    setLoading(false)
  }

  const getTrainingMonth = async () => {
    const selectedDate = `${year}-${month}-15`

    const apiUrl = `/alpha/v1/student/${testKey}DataGraphMonth/${userId}/?date=${selectedDate}`
    const { data: dataTraining } = await Api.get(apiUrl)
    if (dataTraining.data) {
      setGraphMonth(dataTraining.data)
    }
  }

  const getTrainingYear = async () => {
    const selectedDate = `${year}-${month}-15`

    const apiUrl = `/alpha/v1/student/${testKey}DataGraphYear/${userId}/?date=${selectedDate}`
    const { data: dataTraining } = await Api.get(apiUrl)
    if (dataTraining.data) {
      setGraphYear(dataTraining.data)
    }
  }

  const getInputHistory = async () => {
    const apiUrl = `/alpha/v1/student/${testKey}InputHistory/${userId}`
    const { data: dataTraining } = await Api.get<{
      data: {
        date: string
        value: number
      }[]
    }>(apiUrl)

    if (dataTraining.data) {
      setTestResultResponse(dataTraining.data)
    }
  }

  useEffect(() => {
    if (!userId) {
      return
    }

    getInputHistory()
    getTrainingMonth()
    getTrainingYear()
    getValueData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, userId, month, year, tabKey])

  const onClickLeftArrow = async () => {
    if (active === 0) {
      setMonth(month < 2 ? 12 : month - 1)
      setYear(month < 2 ? year - 1 : year)
    } else {
      setYear(year - 1)
    }
  }

  const onClickRightArrow = async () => {
    if (active === 0) {
      setMonth(month > 11 ? 1 : month + 1)
      setYear(month > 11 ? year + 1 : year)
    } else {
      setYear(year + 1)
    }
  }

  useEffect(() => {
    if (activatedTab) {
      return
    }

    setActive(0)
    form.resetFields()
    form.setFieldsValue({
      left1: sportsValue?.left1 || '',
      left2: sportsValue?.left2 || '',
      right1: sportsValue?.right1 || '',
      right2: sportsValue?.right2 || '',
    })
    if (sportsValue?.studentID) {
      setDisabled(false)
    } else setDisabled(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activatedTab])

  useEffect(() => {
    if (sportsValue) {
      form.setFieldsValue({
        left1: sportsValue.left1 || '',
        left2: sportsValue.left2 || '',
        right1: sportsValue.right1 || '',
        right2: sportsValue.right2 || '',
      })
      setDisabled(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sportsValue])

  const onFinish = async (data: ISportRes) => {
    const submitData = {
      ...data,
      left1: data.left1 || 0,
      left2: data.left2 || 0,
      right1: data.right1 || 0,
      right2: data.right2 || 0,
      studentID: userId,
      date: dateTime,
    }

    try {
      const apiUrl = `/alpha/v1/student/${testKey}Input/`

      await Api.post(apiUrl, submitData)
      setSubmitSuccess(true)
      getInputHistory()
      getTrainingMonth()
      getTrainingYear()
      getValueData()
      setTimeout(() => {
        setSubmitSuccess(false)
      }, 5000)
    } catch (err) {
      console.error('/alpha/v1/student/ - test subject', err)
      message.error(t('エラーが発生しました。'))
    }
    form.resetFields()
  }

  const graphRecordLastestMonth = graphMonth?.dataLastest?.length
    ? graphMonth?.dataLastest?.map((item) => item.value)
    : 0
  const graphRecordLastestYear = graphYear?.dataLastest?.length
    ? graphYear?.dataLastest?.map((item) => item.value)
    : 0
  const graphRecordMaxMonth = graphMonth?.dataMax?.length
    ? graphMonth?.dataMax?.map((item) => item.maxValue)
    : 0
  const graphRecordMaxYear = graphYear?.dataMax?.length
    ? graphYear?.dataMax?.map((item) => item.maxValue)
    : 0

  return {
    chartData,
    active,
    setActive,
    month,
    year,
    loading,
    sportsValue,
    testResultResponse,
    onClickLeftArrow,
    onClickRightArrow,
    disabled,
    setDisabled,
    submitSuccess,
    t,
    form,
    lineChart,
    onFinish,
    graphRecordLastestMonth,
    graphRecordLastestYear,
    graphRecordMaxMonth,
    graphRecordMaxYear,
  }
}
