import Api from '~/utils/api'
import {
  LOCAL_STORAGE_WEB_APP_VER_KEY,
  VERSION_CHECKING_INTERVAL,
  WEB_APP_VERSION_CHECK_TIME_KEY,
} from './const'

export const checkWebAppVersion = async () => {
  const lastCheck = localStorage.getItem(WEB_APP_VERSION_CHECK_TIME_KEY)
  console.log('checkWebAppVersion - lastCheck:', lastCheck)
  if (!lastCheck) return
  const now = new Date().getTime()

  // Check if we need to perform the daily check
  if (now - parseInt(lastCheck) > VERSION_CHECKING_INTERVAL) {
    const { data } = await Api.get<{
      ver: string
    }>('/student-ver')

    localStorage.setItem(WEB_APP_VERSION_CHECK_TIME_KEY, now.toString())

    // get teacher data from local storage / global store state
    const webAppVer = localStorage.getItem(LOCAL_STORAGE_WEB_APP_VER_KEY)

    // If version mismatch, reload the app
    if (webAppVer && data.ver !== webAppVer) {
      window.location.reload()
    }
  }
}
