import { ENV } from '../constants';
import type { TPrefectureCityQuestions } from '../types';

/**
 * Search for your environment by query:
 * `{"attributes.schoolName":"山口県立小野田高等学校"}` at `alpha_school` collection.
 * Not `{"attributes.schoolName":"山口県立小野田"}`
 */
const yamaguchiPrefRitsuOnodaHighSchoolIdByEnv = {
  // set your environment's school ID to this:
  local: '660285d50f6a95a98c3d1937',
  stg: '6638ffb9922d3f208f95776f',
  prod: '6602bdeedf4dbcffbcd9c848',
} as const

const yamaguchiPrefRitsuOnodaHighSchoolId: string | undefined =
  yamaguchiPrefRitsuOnodaHighSchoolIdByEnv[ENV]

/**
 * School IDs of Production environment.
 *
 * @example
 * {A: ['6437d42f3246205bb143807a', '65656880e34862c8d1451301'],}
 */
export const customQuestionForSchoolIds = {
  /** 兵庫県芦屋市 */
  A: [
    '6611651506f6c0932a6b24bc',
    '6611652306f6c0932a6b2647',
    '6611653006f6c0932a6b283d',
    '6611653f06f6c0932a6b2a15',
    '6611654f06f6c0932a6b2bf7',
    '6611655e06f6c0932a6b2e0e',
    '6611656f06f6c0932a6b3005',
    '6611657f06f6c0932a6b31c9',
    '6611658f06f6c0932a6b341f',
    '6611659f06f6c0932a6b3699',
    '661165ad06f6c0932a6b381c',
    // testing school IDs:
    // 芦屋市デモ学校（小学校）
    '6618f1c806f6c0932aa89dfd',
    // 芦屋市デモ学校（中学校）
    '6618f1e406f6c0932aa8a17c',
    // end testing school IDs
  ],
  /** 山口県立小野田高等学校 */
  B: [
    yamaguchiPrefRitsuOnodaHighSchoolId
  ],
  C: [
    // Prod school ids
    '6644da600c8623934b7b01f7',
    '6644da750c8623934b7b056f',
    '6644da8b0c8623934b7b0955',
    '6644da9f0c8623934b7b0cce',
    '6644dab50c8623934b7b10dc',
    '6644dac90c8623934b7b142b',
    '6644dade0c8623934b7b179a',
    '6644daf20c8623934b7b1b5d',
    '6644db060c8623934b7b1ef4',
    '6644db250c8623934b7b247d',
    '6644db3e0c8623934b7b28ca',
    '6644db530c8623934b7b2c4e',

    // Prod demo school ids
    '6644dedf0c8623934b7bb5a6',
    '6644df010c8623934b7bbd22',
    // Stg school ids
    '6644ddfa922d3f208fb77f44',
    '6644de2e922d3f208fb78026'
  ],
  // 茨城県水戸市
  D: [
    // Prod School ids
    "62cccf4cb58ec4c7cd117d77",
    "660c25e306f6c0932ab0af5b",
    "660c25fb06f6c0932ab0b291",
    "660c260a06f6c0932ab0b436",
    "660c261e06f6c0932ab0b6d3",
    "660c262f06f6c0932ab0b8c7",
    "660c264006f6c0932ab0bb30",
    "660c265006f6c0932ab0bd28",
    "660c266006f6c0932ab0bf09",
    "660c267106f6c0932ab0c14a",
    "660c267f06f6c0932ab0c305",
    "660c269806f6c0932ab0c5c0",
    "660c26ac06f6c0932ab0c989",
    "660c26bf06f6c0932ab0cb8c",
    "660c26db06f6c0932ab0cf3a",
    "660c26eb06f6c0932ab0d116",
    "660c26fc06f6c0932ab0d372",
    "660c270f06f6c0932ab0d5a7",
    "660c271f06f6c0932ab0d7cf",
    "660c272f06f6c0932ab0da34",
    "660c273f06f6c0932ab0dc0a",
    "660c275206f6c0932ab0dead",
    "660c276306f6c0932ab0e0eb",
    "660c277306f6c0932ab0e2d7",
    "660c278306f6c0932ab0e53a",
    "660c279406f6c0932ab0e762",
    "660c27a406f6c0932ab0e949",
    "660c27bc06f6c0932ab0eca4",
    "660c27cc06f6c0932ab0ee81",
    "660c27e006f6c0932ab0f29c",
    "660c27f006f6c0932ab0f49d",
    "660c280306f6c0932ab0f6fa",
    "660c281c06f6c0932ab0fa58",
    "660c283406f6c0932ab0fd96",
    "660c284306f6c0932ab0ff62",
    "660c285406f6c0932ab10157",
    "660c286506f6c0932ab103b0",
    "660c287506f6c0932ab1057b",
    "660c288506f6c0932ab10757",
    "660c289a06f6c0932ab10a23",
    "660c28aa06f6c0932ab10c0a",
    "660c28bc06f6c0932ab10e99",
    "660c28cb06f6c0932ab11075",
    "660c28db06f6c0932ab1122a",
    "660c28ea06f6c0932ab11465",
    "660c28fe06f6c0932ab117ca",
    "660c290e06f6c0932ab119a3",
    "660c2faf06f6c0932ab1f790",
    "660c2fed06f6c0932ab1ff76",

    // Demo Prod school ids
    "66601e4ce02ebccabad65982",
    "66601edae02ebccabad68422",

    // Stg school ids
    "66601c834c22922649b6e979",
    "66601c514c22922649b6e895",
    
  ],
  // 茨城県牛久市
  E: [
    // production ids
    "64df1eb4c8133d33abee6805",
    "64df1ec6c8133d33abee6c9f",
    "64df1edbc8133d33abee713f",
    "64df1eecc8133d33abee749c",
    "64df1efdc8133d33abee78c5",
    "64df1f11c8133d33abee7d3a",
    "64df1f86c8133d33abeea158",
    "64df1fd6c8133d33abeeb424",
    "64df1ff7c8133d33abeebb08",
    "64df2008c8133d33abeebf51",
    "64df201dc8133d33abeec3c3",
    "64df2037c8133d33abeec9f0",
    "64df204cc8133d33abeecebe",
    "64df205fc8133d33abeed2f5",

    // STG ids
    "66601c9b4c22922649b6e9e1",
    "66601c694c22922649b6e8ea",

    // production demo ids
    "66601e5ce02ebccabad6633a",
    "66601eeae02ebccabad68fd5"
  ],
  // 岐阜県多治見市
  F: [
    // production ids
    // "660c293206f6c0932ab11e21",
    // "660c294306f6c0932ab12077",
    // "660c295306f6c0932ab1223c",
    // "660c296306f6c0932ab12401",
    // "660c297206f6c0932ab12646",
    // "660c298406f6c0932ab12854",
    // "660c299606f6c0932ab12a59",
    // "660c29a806f6c0932ab12cd5",
    // "660c29b806f6c0932ab12e9c",
    // "660c29ca06f6c0932ab13121",
    // "660c29d906f6c0932ab13307",
    // "660c29ea06f6c0932ab134ca",
    // "660c29f906f6c0932ab13706",
    // "660c2a0c06f6c0932ab13956",
    // "660c2a1c06f6c0932ab13aee",
    // "660c2a3006f6c0932ab13e7c",
    // "660c2a4106f6c0932ab14044",
    // "660c2a5006f6c0932ab1428e",
    // "660c2a6006f6c0932ab14454",
    // "660c2a7206f6c0932ab14648",
    // "660c2a8406f6c0932ab148fd",

    // STG ids
    // "66888975a0fc9f3e6fea7a31",
    // "66888959a0fc9f3e6fea79c9",
  ],
  // 茨城県かすみがうら市
  G: [
    // production ids
    "66bec406054a482b1757863c",
    "66bec417054a482b175788d1",
    "66bec427054a482b17578ac7",
    "66bec44e054a482b17579127",
    "66bec45c054a482b17579302",
    "66bec46d054a482b17579595",
    "66bec47d054a482b175797a2",
    "66bec48d054a482b17579a9f",

    // STG ids
    "66bed0a5dfa1eaec5a2c7cf6",
    "66bed0bedfa1eaec5a2c9694",
  ],
  // 岐阜県多治見市
  H: [
    // production ids
    "660c293206f6c0932ab11e21",
    "660c294306f6c0932ab12077",
    "660c295306f6c0932ab1223c",
    "660c296306f6c0932ab12401",
    "660c297206f6c0932ab12646",
    "660c298406f6c0932ab12854",
    "660c299606f6c0932ab12a59",
    "660c29a806f6c0932ab12cd5",
    "660c29b806f6c0932ab12e9c",
    "660c29ca06f6c0932ab13121",
    "660c29d906f6c0932ab13307",
    "660c29ea06f6c0932ab134ca",
    "660c29f906f6c0932ab13706",
    "660c2a0c06f6c0932ab13956",
    "660c2a1c06f6c0932ab13aee",
    "660c2a3006f6c0932ab13e7c",
    "660c2a4106f6c0932ab14044",
    "660c2a5006f6c0932ab1428e",
    "660c2a6006f6c0932ab14454",
    "660c2a7206f6c0932ab14648",
    "660c2a8406f6c0932ab148fd",
    // STG ids
    "66888975a0fc9f3e6fea7a31",
    "66888959a0fc9f3e6fea79c9",
  ],
} as const;

export const customQuestionForSchoolIdsKeys = Object.keys(
  customQuestionForSchoolIds,
)

/**
 * Copy from Excel file.
 */
export const prefectureCityQuestionForElementStudent: TPrefectureCityQuestions[] =
[
  [1, '北海道', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [2, '青森県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 202, 203, 204, 205, 206, 207, 208, 145, 146, 147, 148, 45]],
  [3, '岩手県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [4, '宮城県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [5, '秋田県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [6, '山形県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [7, '福島県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [8, '茨城県', [1, 5, 6, 37, 16, 21, 22, 289, 145, 146, 147, 148, 45]],
  [9, '栃木県', [35, 36, 1, 4, 6, 12, 15, 30, 24, 29, 21, 25, 16, 100, 19, 20, 22, 37, 33, 288,145, 146, 147, 148, 45]],
  [10, '群馬県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [11, '埼玉県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [12, '千葉県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [13, '東京都', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [14, '神奈川県', [1, 5, 6, 37, 7, 8, 9, 10, 13, 14, 16, 21, 22, 102, 145, 146, 147, 148, 45]],
  [15, '新潟県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 33, 22, 191, 145, 146, 147, 148, 45]],
  [16, '富山県', [1, 5, 6, 37, 16, 21, 22, 25, 36, 35, 33, 145, 146, 147, 148, 45]],
  [17, '石川県', [1, 5, 6, 16, 21, 25, 88, 145, 146, 147, 148, 45]],
  [18, '福井県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [19, '山梨県', [1, 103, 104, 16, 21, 105, 28, 34, 37, 22, 106, 107, 108, 109, 145, 146, 147, 148, 45]],
  [20, '長野県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [21, '岐阜県', [1, 4, 6, 16, 21, 25, 28, 34, 37, 22, 54, 55, 56, 57, 58, 59, 145, 146, 147, 148, 45]],
  [22, '静岡県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [23, '愛知県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [24, '三重県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [25, '滋賀県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [26, '京都府', [1, 5, 6, 48, 101, 21, 102, 145, 146, 147, 148, 45]],
  [27, '大阪府', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [28, '兵庫県', [1, 4, 6, 16, 21, 25, 28, 35, 37, 22, 18, 145, 146, 147, 148, 45]],
  [29, '奈良県', [35, 5, 6, 2, 21, 16, 145, 146, 147, 148, 45]],
  [30, '和歌山県', [1, 5, 6, 37, 16, 21, 22, 25, 28, 34, 145, 146, 147, 148, 45]],
  [31, '鳥取県', [1, 5, 6, 16, 21, 25, 82, 145, 146, 147, 148, 45]],
  [32, '島根県', [1, 85, 86, 87, 145, 146, 147, 148, 45]],
  [33, '岡山県', [1, 5, 6, 16, 21, 25, 28, 35, 37, 22, 33, 145, 146, 147, 148, 45]],
  [34, '広島県', [1, 89, 5, 6, 35, 140, 33, 21, 141, 149, 150, 144, 145, 146, 147, 148, 45]],
  [35, '山口県', [1, 5, 6, 16, 161, 21, 25, 37, 22, 158, 159, 160, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 175, 177, 178, 45]],
  [36, '徳島県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [37, '香川県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [38, '愛媛県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [39, '高知県', [1, 5, 6, 83, 16, 21, 25, 35, 287, 33, 22, 37, 145, 146, 147, 148, 45]],
  [40, '福岡県', [1, 5, 6, 16, 21, 25, 28, 35, 37, 22, 33, 145, 146, 147, 148, 45]],
  [41, '佐賀県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 145, 146, 147, 148, 45]],
  [42, '長崎県', [1, 5, 6, 16, 21, 25, 28, 151, 22, 40, 36, 33, 69, 70, 71, 145, 146, 147, 148, 45]],
  [43, '熊本県', [3, 5, 6, 16, 21, 25, 28, 22, 40, 33, 35, 80, 81, 145, 146, 147, 148, 45]],
  [44, '大分県', [1, 5, 6, 16, 21, 25, 28, 152, 37, 22, 153, 154, 155, 156, 157, 145, 146, 147, 148, 45]],
  [45, '宮崎県', [35, 33, 1, 5, 6, 38, 39, 43, 91, 46, 23, 47, 41, 145, 146, 147, 148, 45]],
  [46, '鹿児島県', [1, 5, 6, 16, 21, 25, 28, 34, 37, 22, 3, 13, 79, 33, 145, 146, 147, 148, 45]],
  [47, '沖縄県', [1, 112, 113, 16, 114, 115, 37, 22, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 33, 130, 131, 77, 132, 45]],
  // 兵庫県芦屋市:
  [48, 'A', [1, 5, 6, 16, 18, 21, 179, 37, 22, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 181, 130, 131, 157, 132, 182, 45]],
  // in case of elementary school of prefecture 49, 山口県立小野田高等学校 is same as 山口県
  // 茨城県下妻市
  [50, 'C', [1, 5, 6, 37, 16, 21, 22, 289, 193, 194, 195, 196, 197, 198, 199, 200, 201, 145, 146, 147, 148, 45]],
  // 茨城県水戸市
  [51, 'D', [1, 5, 6, 37, 16, 21, 22, 289, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 145, 146, 147, 148, 45]],
  // 茨城県牛久市
  [52, 'E', [1, 5, 6, 37, 16, 21, 22, 289, 236, 237, 238, 239, 145, 146, 147, 148, 45]],
  // 岐阜県多治見市
  [53, 'F', [1, 4, 6, 16, 21, 25, 28, 34, 37, 22, 54, 55, 56, 57, 58, 59, 240, 241, 242, 243, 244, 245, 246, 247, 248, 145, 146, 147, 148, 45]],
  // 茨城県かすみがうら市
  [54, 'G', [99, 1, 5, 6, 16, 21, 289]],
  // 岐阜県多治見市
  [55, 'H', [273, 274, 275, 276, 277, 278, 279, 280, 281, 282, 283, 284, 285, 286, 45]],
]

/**
 * Copy from Excel file.
 */
export const prefectureCityQuestionForJuniorOrHighSchoolStudent: TPrefectureCityQuestions[] =
[
  [1, '北海道', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [2, '青森県', [2, 5, 6, 16, 21, 25, 22, 40, 28, 35, 99, 202, 203, 204, 205, 206, 207, 208, 145, 146, 147, 148, 45]],
  [3, '岩手県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [4, '宮城県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [5, '秋田県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [6, '山形県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [7, '福島県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [8, '茨城県', [2, 5, 6, 16, 21, 289, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [9, '栃木県', [35, 36, 1, 4, 6, 12, 15, 30, 24, 29, 21, 25, 16, 100, 19, 20, 22, 40, 92, 288, 99, 145, 146, 147, 148, 45]],
  [10, '群馬県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [11, '埼玉県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 110, 111, 145, 146, 147, 148, 45]],
  [12, '千葉県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [13, '東京都', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [14, '神奈川県', [99, 3, 5, 6, 7, 95, 9, 96, 13, 14, 16, 21, 22, 102, 40, 145, 146, 147, 148, 45]],
  [15, '新潟県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 92, 191, 99, 145, 146, 147, 148, 45]],
  [16, '富山県', [2, 5, 6, 16, 21, 22, 25, 40, 36, 35, 33, 99, 145, 146, 147, 148, 45]],
  [17, '石川県', [2, 5, 6, 101, 21, 97, 88, 145, 146, 147, 148, 45]],
  [18, '福井県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [19, '山梨県', [1, 103, 104, 16, 106, 107, 108, 21, 109, 105, 99, 28, 34, 22, 40, 145, 146, 147, 148, 45]],
  [20, '長野県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [21, '岐阜県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [22, '静岡県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [23, '愛知県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [24, '三重県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [25, '滋賀県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [26, '京都府', [2, 5, 6, 48, 101, 21, 102, 99, 145, 146, 147, 148, 45]],
  [27, '大阪府', [2, 4, 6, 16, 22, 21, 25, 40, 99, 145, 146, 147, 148, 45]],
  [28, '兵庫県', [2, 4, 6, 35, 41, 16, 18, 21, 22, 25, 40, 99, 145, 146, 147, 148, 45]],
  [29, '奈良県', [35, 5, 6, 1, 21, 16, 99, 145, 146, 147, 148, 45]],
  [30, '和歌山県', [2, 5, 6, 16, 21, 22, 25, 40, 28, 35, 99, 145, 146, 147, 148, 45]],
  [31, '鳥取県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 82, 99, 145, 146, 147, 148, 45]],
  [32, '島根県', [2, 85, 86, 87, 99, 145, 146, 147, 148, 45]],
  [33, '岡山県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 33, 99, 145, 146, 147, 148, 45]],
  [34, '広島県', [2, 90, 5, 6, 35, 140, 92, 21, 141, 142, 143, 144, 145, 146, 147, 148, 45]],
  // 山口県（中学校）
  [35, '山口県', [2, 5, 6, 16, 21, 25, 40, 22, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 176, 177, 178, 45]],
  [36, '徳島県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [37, '香川県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [38, '愛媛県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [39, '高知県', [2, 5, 6, 16, 21, 25, 35, 36, 33, 22, 40, 83, 209, 99, 145, 146, 147, 148, 45]],
  [40, '福岡県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 33, 99, 145, 146, 147, 148, 45]],
  [41, '佐賀県', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 45]],
  [42, '長崎県', [2, 5, 6, 16, 21, 25, 28, 151, 22, 40, 36, 33, 69, 70, 71, 99, 145, 146, 147, 148, 45]],
  [43, '熊本県', [3, 5, 6, 16, 21, 25, 28, 22, 40, 33, 35, 80, 81, 99, 145, 146, 147, 148, 45]],
  [44, '大分県', [2, 5, 6, 16, 21, 25, 28, 152, 22, 40, 99, 153, 154, 155, 156, 157, 145, 146, 147, 148, 45]],
  [45, '宮崎県', [35, 92, 1, 5, 6, 43, 91, 46, 23, 47, 31, 41, 99, 145, 146, 147, 148, 45]],
  [46, '鹿児島県', [2, 5, 6, 16, 21, 25, 28, 34, 22, 40, 3, 13, 79, 33, 99, 145, 146, 147, 148, 45]],
  [47, '沖縄県', [1, 112, 113, 16, 114, 115, 133, 22, 118, 119, 120, 121, 122, 123, 134, 135, 136, 126, 127, 137, 138, 33, 130, 131, 77, 40, 139, 45]],
  // 兵庫県芦屋市:
  [48, 'A', [2, 5, 6, 183, 18, 21, 184, 22, 40, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 185, 186, 33, 187, 188, 41, 157, 189, 190, 45]],
  // 山口県立小野田高等学校:
  [49, 'B', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 192, 45]],
  // 茨城県下妻市
  [50, 'C', [2, 5, 6, 16, 21, 289, 28, 35, 22, 40, 193, 194, 195, 196, 197, 198, 199, 200, 201, 145, 146, 147, 148, 45]],
  // 茨城県水戸市
  [51, 'D', [2, 5, 6, 16, 21, 289, 28, 35, 22, 40, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 145, 146, 147, 148, 45]],
  // 茨城県牛久市
  [52, 'E', [2, 5, 6, 16, 21, 289, 28, 35, 22, 40, 99, 236, 237, 238, 239, 145, 146, 147, 148, 45]],
  // 岐阜県多治見市
  [53, 'F', [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 145, 146, 147, 148, 45]],
  // 茨城県かすみがうら市
  [54, 'G', [99, 2, 5, 6, 16, 21, 289]],
  // 岐阜県多治見市
  [55, 'H', [259, 260, 261, 262, 263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 45]],
]

/** 山口県（高校） */
export const yamaguchiPrefHighSchoolQuestions = [2, 5, 6, 16, 21, 25, 28, 35, 22, 40, 99, 145, 146, 147, 148, 192, 45]
