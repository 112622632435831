import './QrCodeForm.css'

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { useMsal } from '@azure/msal-react'
import { useGoogleLogin } from '@react-oauth/google'
import { Button, Form, Input, message, Row } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { memo, useEffect, useState } from 'react'
import { isIOS } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import QrReader from 'react-qr-reader'
import { handleQRReaderError } from '~/student-utils/logger'
import Api from '~/utils/api'
import { ISsoResponse } from '../types'

type Props = {
  onScan: (data: string | null) => void
  form: FormInstance
  loading: boolean
  onFormSubmit: (values: { invitationCode: string }) => void
  onSsoLoginSuccess: (ssoRes: ISsoResponse) => void
  isRedirect?: boolean
}

const QrCodeForm = memo(
  ({
    onScan,
    form,
    loading,
    onFormSubmit,
    onSsoLoginSuccess,
    // isRedirect,
  }: Props) => {
    const { instance } = useMsal()
    const { t } = useTranslation()
    const [isRedirect, setIsRedirect] = useState(false)

    // Check for redirects on component mount
    useEffect(() => {
      // Alternative: check sessionStorage flag
      const msRedirectFlag = sessionStorage.getItem('ms_redirect_pending')
      if (msRedirectFlag === 'true') {
        setIsRedirect(true)
        // Clear the flag
        sessionStorage.removeItem('ms_redirect_pending')
      }
    }, [])

    const loginByGg = useGoogleLogin({
      onSuccess: (tokenResponse) => {
        Api.post<ISsoResponse>('auth/gg', {
          token: tokenResponse.access_token,
        }).then((response) => {
          // console.log('Backend token exchange successful:', response.data)
          onSsoLoginSuccess(response.data)
        })
      },
      onError: (error) => {
        console.error('Login by Google Failed:', error)
        message.error(
          `${t('エラーが発生しました。')} [Login by Google Failed]`,
          8,
        )
      },
    })

    useEffect(() => {
      if (!isRedirect) {
        return
      }
      message.info(t('マイクロソフト認証からリダイレクトされました'))
      instance
        .handleRedirectPromise()
        .then(async (response) => {
          if (response) {
            // message.success(`Login success: ${JSON.stringify(response)}`)
            // history.push('/confirm-me')
            // setIsConfirmMe(response)
            if (!response?.idToken) {
              throw new Error('No ID token received from Microsoft')
            }

            // // console.log('MS Login success:', loginResponse)
            const msResponse = await Api.post<ISsoResponse>('auth/ms', {
              token: response.idToken, // Use ID token instead of access token
            })

            onSsoLoginSuccess(msResponse.data)
          } else {
            message.error('No response, redirecting to login...')
            // instance.loginRedirect() // Only redirect if no response
          }
        })
        .catch((error) => {
          console.error('Login error:', error)
          message.error(
            `${t('エラーが発生しました。')} [Login error] ${JSON.stringify(error)}`,
          )
          // history.push('/register')
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instance, isRedirect, t])

    const handleMsLogin = async () => {
      try {
        if (isIOS) {
          // Set a flag in sessionStorage before redirecting
          sessionStorage.setItem('ms_redirect_pending', 'true')

          instance
            .loginRedirect({
              // No need for 'openid', 'profile'
              scopes: ['User.Read'],
              // select_account: will interrupt single sign-=on providing account selection
              // experience listing all the accounts in session or any remembered accounts or
              // an option to choose to use a different account
              prompt: 'select_account',
              // redirectUri: `${window.location.origin}/redirect-ms`,
              // resourceRequestUri: `${window.location.origin}/redirect-ms`,
            })
            .then(() => {
              setIsRedirect(true)
            })
        } else {
          // For desktop browsers, use popup authentication instead of redirect
          const loginResponse = await instance.loginPopup({
            scopes: ['User.Read'],
            prompt: 'select_account',
          })

          if (!loginResponse?.idToken) {
            throw new Error('No ID token received from Microsoft')
          }

          // Send token to backend
          const response = await Api.post<ISsoResponse>('auth/ms', {
            token: loginResponse.idToken, // Use ID token instead of access token
          })

          onSsoLoginSuccess(response.data)
        }

        // onSsoLoginSuccess(response.data)
      } catch (error) {
        console.error('MS Login failed:', error)
        // handle case `user_cancelled`
        if ((error as { errorCode: string }).errorCode === 'user_cancelled') {
          message.error(`${t('エラーが発生しました。')} user_cancelled`, 8)
        } else {
          message.error(
            `${t('エラーが発生しました。')} [MS Login failed] ${JSON.stringify(error)}`,
            8,
          )
        }
      }
    }

    return (
      <div
        className="w-full h-screen flex justify-center qr-code-form"
        style={{
          background: 'url(/images/SignInBackground.png) no-repeat bottom',
          backgroundSize: 'contain',
        }}
      >
        <div className="flex flex-col w-400px sp:w-11/12 pt-11 sp:pt-4">
          <h1 className="pb-4 text-center title text-2xl sp:text-lg font-black">
            {t('サインイン')}
          </h1>

          <div className="flex justify-center items-center">
            {isRedirect ? (
              // mocked unusable qr code rectangle
              <div
                style={{
                  width: 348,
                  height: 348,
                  backgroundColor: '#0d2784',
                  border: '48px solid #030b70',
                }}
              >
                <div className="flex justify-center items-center w-full h-full bg-0d2784 border-4 border-red-600" />
              </div>
            ) : (
              <QrReader
                delay={300}
                onError={handleQRReaderError}
                onScan={onScan}
                className="qr-reader"
                style={{
                  backgroundColor: '#0d2784',
                }}
              />
            )}
          </div>

          <Form
            form={form}
            layout="vertical"
            initialValues={{ invitationCode: null }}
            onFinish={onFormSubmit}
          >
            <div className="mt-6 sp:mt-4 mb-1 items-center mx-7">
              <span className="text-red-600">* </span>
              <label className="dark:text-white font-black">
                {t('サインインコード')}
              </label>
            </div>

            <div className="mx-7">
              <Form.Item
                name="invitationCode"
                rules={[
                  {
                    required: true,
                    message: t('サインインコードを入力して下さい。'),
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  className="h-10 bg-gray-150 mt-1"
                  disabled={loading}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </div>

            {/* サインイン button */}
            <Row justify="center" className="mt-6">
              <Button
                type="primary"
                className="w-60"
                size="large"
                htmlType="submit"
                loading={loading}
              >
                {t('次へ')}
              </Button>
            </Row>
          </Form>

          <div className="flex justify-center items-center mt-6 sp:mt-2">
            <div className="border-gray-300 m-2 border-t-2 w-1/3 h-0" />
            <div className="text-gray-500 m-2">{t('または')}</div>
            <div className="border-gray-300 m-2 border-t-2 w-1/3 h-0" />
          </div>

          <div className="flex justify-center mx-4 my-2 sso-group">
            <div className="mx-2 sp:mx-1 w-1/2">
              <button
                type="button"
                onClick={() => loginByGg()}
                className="border-1px border-gray-300 border-solid rounded-md px-2 py-1.5 text-sm sp:text-xs font-medium text-gray-500 hover:bg-blue-50 hover:text-gray-900 hover:border-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-200 w-full flex items-center justify-center bg-white"
              >
                <img
                  src="/icons/gg.svg"
                  alt="Google"
                  width={28}
                  className="mr-1"
                />
                {t('Googleで続行')}
              </button>
            </div>

            <div className="mx-2 sp:mx-1 w-1/2">
              <button
                type="button"
                onClick={handleMsLogin}
                className="border-1px border-gray-300 border-solid rounded-md px-2 py-1.5 text-sm sp:text-xs font-medium text-gray-500 hover:bg-blue-50 hover:text-gray-900 hover:border-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-200 w-full flex items-center justify-center bg-white"
              >
                <img
                  src="/icons/ms.svg"
                  alt="Microsoft"
                  width={28}
                  className="mr-1"
                />
                {t('Microsoftで続行')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  },
)

export default QrCodeForm
