import { allTestKeys } from '.'

export const currentTests = {
  grip: { title: '握力', suffix: 'kg' },
  sitUps: { title: '上体起こし', suffix: '回' },
  bending: { title: '長座体前屈', suffix: 'cm' },
  enduranceRun: { title: '持久走', suffix: '' },
  shuttleRun: { title: '20mシャトルラン', suffix: '回' },
  sprintRun: { title: '50m走', suffix: '秒' },
  sideJump: { title: '反復横跳び', suffix: '点' },
  standingJump: { title: '立ち幅跳び', suffix: 'cm' },
  handballThrow: { title: 'ボール投げ', suffix: 'm' },
} as const

export const testsTitleAndUnit = {
  grip: { title: '握力', suffix: 'kg' },
  sitUps: { title: '上体起こし', suffix: '回' },
  bending: { title: '長座体前屈', suffix: 'cm' },
  shuttleRun: { title: 'シャトルラン', suffix: '回' },
  sprintRun: { title: '50m走', suffix: '秒' },
  sideJump: { title: '反復横跳び', suffix: '回' },
  standingJump: { title: '立ち幅跳び', suffix: 'cm' },
  handballThrow: { title: 'ボール投げ', suffix: 'm' },
  enduranceRun: { title: '持久走', suffix: '' },
} as const

export type TAllTestKey = (typeof allTestKeys)[number]
export type TAllTestKeyHeightWeight = TAllTestKey | 'height' | 'weight'
export type TAllTestKeySizeTest = TAllTestKey | 'sizeTest'

export const testKeysHasEnduranceRunOnly = [
  'grip',
  'sitUps',
  'bending',
  'enduranceRun',
  'sprintRun',
  'sideJump',
  'standingJump',
  'handballThrow',
] as const

export const testKeysHasShuttleRunOnly = [
  'grip',
  'sitUps',
  'bending',
  'shuttleRun',
  'sprintRun',
  'sideJump',
  'standingJump',
  'handballThrow',
] as const

export const testKeysWithoutShuttleRunEnduranceRun = [
  'grip',
  'sitUps',
  'bending',
  'sprintRun',
  'sideJump',
  'standingJump',
  'handballThrow',
] as const

/**
 * For award printing
 * The order of the keys is important!
 */
export const awardTestKeysHasEnduranceRunOnly = [
  'grip',
  'sitUps',
  'bending',
  'sideJump',
  'enduranceRun',
  'sprintRun',
  'standingJump',
  'handballThrow',
] as const

/**
 * For award printing
 * The order of the keys is important!
 */
export const awardTestKeysHasShuttleRunOnly = [
  'grip',
  'sitUps',
  'bending',
  'sideJump',
  'shuttleRun',
  'sprintRun',
  'standingJump',
  'handballThrow',
] as const

/**
 * 8 items in list
 */
export const trainingSportJapaneseTxtWithoutEnduranceRun = {
  GRIP_STRENGTH: '握力',
  SIT_UPS: '上体起こし',
  BENDING: '長座体前屈',
  SIDE_JUMP: '反復横跳び',
  SHUTTLE_RUN: '20mシャトルラン',
  SPRINT_RUN: '50m走',
  STANDING_JUMP: '立ち幅跳び',
  HANDBALL_THROW: 'ボール投げ',
} as const

/**
 * 9 items in list
 */
export const trainingSportJapaneseTxtWithEnduranceRun = {
  GRIP_STRENGTH: '握力',
  SIT_UPS: '上体起こし',
  BENDING: '長座体前屈',
  SIDE_JUMP: '反復横跳び',
  ENDURANCE_RUN: '持久走',
  SHUTTLE_RUN: '20mシャトルラン',
  SPRINT_RUN: '50m走',
  STANDING_JUMP: '立ち幅跳び',
  HANDBALL_THROW: 'ボール投げ',
} as const
