import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from '@alpha/core'
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom'

// Create a namespace on the window object for our global variables
// declare global {
//   interface Window {
//     alphaControlCenter: {
//       publicPopupShowing: boolean
//       setPublicPopupShowing: (value: boolean) => void
//     }
//   }
// }

// // Initialize the global variable if it doesn't exist
// if (typeof window !== 'undefined') {
//   window.alphaControlCenter = window.alphaControlCenter || {
//     publicPopupShowing: true,
//     setPublicPopupShowing: (value: boolean) => {
//       window.alphaControlCenter.publicPopupShowing = value
//       // Dispatch an event so that any component listening can update
//       window.dispatchEvent(
//         new CustomEvent('publicPopupStateChanged', { detail: value }),
//       )
//     },
//   }
// }

// interface PopupProps {
//   children: ReactNode
// }

// const Popup = ({ children }: PopupProps) => {
//   return (
//     <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//       <div
//         className="bg-white rounded-lg shadow-xl flex flex-col p-6 relative"
//         style={{
//           maxWidth: '800px',
//         }}
//       >
//         <div className="flex-1 overflow-auto m-12 sp:m-2">{children}</div>
//       </div>
//     </div>
//   )
// }

interface PublicRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ComponentType<RouteComponentProps>
}

export const PublicRoute = ({
  component: Component,
  ...rest
}: PublicRouteProps) => {
  // Initialize state from global variable
  // const [showPopup, setShowPopup] = useState(
  //   window.alphaControlCenter.publicPopupShowing,
  // )
  const authToken = window.localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)

  // Listen to changes in the global variable
  // useEffect(() => {
  //   const handleGlobalStateChange = (event: CustomEvent<boolean>) => {
  //     // Here's how you can control the popup from the browser console:
  //     // To hide the popup
  //     // window.alphaControlCenter.setPublicPopupShowing(false);

  //     // // To show the popup again
  //     // window.alphaControlCenter.setPublicPopupShowing(true);

  //     // // To check the current state
  //     // window.alphaControlCenter.publicPopupShowing;
  //     setShowPopup(event.detail)
  //   }

  //   // TypeScript needs us to cast the event
  //   window.addEventListener(
  //     'publicPopupStateChanged',
  //     handleGlobalStateChange as EventListener,
  //   )

  //   return () => {
  //     window.removeEventListener(
  //       'publicPopupStateChanged',
  //       handleGlobalStateChange as EventListener,
  //     )
  //   }
  // }, [])

  // Update global state when local state changes
  // useEffect(() => {
  //   window.alphaControlCenter.publicPopupShowing = showPopup
  // }, [showPopup])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authToken) {
          return (
            <>
              <Component {...props} />
              {/* {showPopup && (
                <Popup>
                  <div className="text-center">
                    <h3 className="text-2xl mb-8">
                      来年度に向けた準備中のため、ご利用いただけません。
                    </h3>

                    <h3 className="mb-8 text-2xl">
                      ご迷惑をおかけし申し訳ございません。
                    </h3>

                    <h3 className="text-2xl">
                      4月1日以降に担任の先生からログイン情報をもらって
                      <br />
                      ログインしてください。
                    </h3>
                  </div>
                </Popup>
              )} */}
            </>
          )
        }
        return <Redirect to="/movie" />
      }}
    />
  )
}
