import './RegisterUserPage.css'

import {
  AuthContext,
  useQrCodeStudentLazyQuery,
  useStudentLinkSchoolMutation,
} from '@alpha/core'
import { Button, Form, Select, message } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import {
  getListData,
  monthSelections,
  yearSelections,
} from '~/student-utils/string'
import Api from '~/utils/api'
import isJapanese from '~/utils/isJapanese'
import Dropdown from '../common/Dropdown'
import CompletionLayout from '../layout/CompletionLayout'
import { LoadingSpin } from '../loading-spin'
import QrCodeForm from './qr-code/QrCodeForm'
import RegisterConfirm from './register/register-confirm'
import { ISsoResponse, IStudentBirthdayAndAttributes } from './types'

let inputtedCode: string | null = null

const RegisterUserPage = () => {
  const history = useHistory()
  // const location = useLocation()
  const { t, i18n } = useTranslation()

  const auth = useContext(AuthContext)

  const isLevelB = auth.schoolLevel === 'B'
  const isUsingJp = isLevelB && isJapanese(i18n)

  // Check if this is a redirect from Microsoft authentication
  // const isRedirect = !!location.hash

  // if (isRedirect) {
  //   message.info('Redirecting to Microsoft login...')
  // }

  // const mockUser = {
  //   _id: '1d3fff32',
  //   token: 'token',
  //   attributes: {
  //     yearBirth: 2000,
  //     monthBirth: 1,
  //     dayBirth: 1,
  //     schoolName: 'schoolName',
  //     familyName: 'familyName',
  //     givenName: 'givenName',
  //     schoolGrade: 1,
  //     schoolClass: 1,
  //     schoolAttendanceNumber: 1,
  //   },
  // }

  const [studentData, setStudentData] =
    useState<null | IStudentBirthdayAndAttributes>(null)
  const [linkComplete, setLinkComplete] = useState(false)
  const [isBirthdayScreen, setIsBirthdayScreen] = useState(false)
  const [ssoRes, setSsoRes] = useState<ISsoResponse | undefined>()
  // {
  //   users: [
  //     mockUser,
  //     {
  //       _id: '2',
  //       token: 'token2',
  //       attributes: {
  //         yearBirth: 2000,
  //         monthBirth: 1,
  //         dayBirth: 1,
  //         schoolName: 'schoolName2',
  //         familyName: 'familyName2',
  //         givenName: 'givenName2',
  //         schoolGrade: 2,
  //         schoolClass: 2,
  //         schoolAttendanceNumber: 2,
  //       },
  //     },
  //     {
  //       _id: '3',
  //       token: 'token3',
  //       attributes: {
  //         yearBirth: 2000,
  //         monthBirth: 1,
  //         dayBirth: 1,
  //         schoolName: 'schoolName3',
  //         familyName: 'familyName3',
  //         givenName: 'givenName3',
  //         schoolGrade: 3,
  //         schoolClass: 3,
  //         schoolAttendanceNumber: 3,
  //       },
  //     },
  //     {
  //       _id: '4',
  //       token: 'token4',
  //       attributes: {
  //         yearBirth: 2000,
  //         monthBirth: 1,
  //         dayBirth: 1,
  //         schoolName: 'schoolName4',
  //         familyName: 'familyName4',
  //         givenName: 'givenName4',
  //         schoolGrade: 4,
  //         schoolClass: 4,
  //         schoolAttendanceNumber: 4,
  //       },
  //     },
  //   ],
  // }

  const [getStudent, { data, error }] = useQrCodeStudentLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  })
  const [linkSchool, { loading }] = useStudentLinkSchoolMutation()

  const [form] = Form.useForm()

  useEffect(() => {
    inputtedCode = null
  }, [])

  // sync data from `useQrCodeStudentLazyQuery`
  useEffect(() => {
    if (error) {
      console.error('useQrCodeStudentLazyQuery - error:', error)
      message.error(t('エラーが発生しました。'))
      return
    }

    if (!data) {
      return
    }

    if (data.qrCodeStudent?.error === 'USER_NOT_FOUND') {
      message.error(t('エラー： USER_NOT_FOUND!'))
      return
    } else if (data.qrCodeStudent?.error) {
      message.error(t('エラーが発生しました。'))
      console.error('data.qrCodeStudent?.error', data.qrCodeStudent.error)
      return
    }

    const _studentData = data.qrCodeStudent?.data
    if (_studentData) {
      setStudentData(_studentData as IStudentBirthdayAndAttributes)

      formEdit.setFieldsValue({
        yearBirth: _studentData.attributes?.yearBirth,
        monthBirth: _studentData.attributes?.monthBirth,
        dayBirth: _studentData.attributes?.dayBirth,
      })
    }
    // No need to add `t` to dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error])

  const onFinish = async (invitationCode: string) => {
    try {
      const linkVariables = {
        input: {
          invitationCode,
        },
      }
      const res = await linkSchool({ variables: linkVariables })
      if (res.errors) {
        message.error(
          `${t('エラーが発生しました。')} [${res.errors.join(',')}]`,
        )
        return
      }

      const studentLinkSchool = res.data?.studentLinkSchool
      if (!studentLinkSchool?.token) {
        message.error(
          `${t('エラーが発生しました。')} [${studentLinkSchool?.error}]`,
        )
        return
      }
      if (studentLinkSchool?.token) {
        auth.setAccessToken(studentLinkSchool.token)
      }
      message.success(t('登録しました。'))
      setLinkComplete(true)

      setTimeout(() => {
        history.push('/movie')
      }, 2000)
    } catch (err) {
      message.error(`${t('エラーが発生しました。')} [${err}]`)
    }
  }

  const handleReturn = () => {
    form.setFieldsValue({ invitationCode: null })
    inputtedCode = null
    setStudentData(null)
  }

  const checkUser = async (invitationCode: string, userId?: string) => {
    if (ssoRes && userId) {
      const user = ssoRes.users.find((u) => u._id === userId)
      if (!user) {
        message.error(
          `${t('エラーが発生しました。')} [NO SSO USER ID - ${userId}]`,
        )
        return
      }

      setStudentData(user)

      auth.setAccessToken(user.token)
      message.success(t('登録しました。'))
      setLinkComplete(true)

      setTimeout(() => {
        history.push('/movie')
      }, 2000)
      return
    }

    if (
      studentData?.attributes?.yearBirth &&
      studentData.attributes.monthBirth &&
      studentData.attributes.dayBirth
    ) {
      await onFinish(invitationCode)
    } else {
      try {
        const linkVariables = {
          input: {
            invitationCode,
          },
        }
        const res = await linkSchool({ variables: linkVariables })
        if (res.errors) {
          message.error(
            `${t('エラーが発生しました。')} [${res.errors.join(',')}]`,
          )
          return
        }

        const studentLinkSchool = res.data?.studentLinkSchool
        if (!studentLinkSchool?.token) {
          message.error(
            `${t('エラーが発生しました。')} [${studentLinkSchool?.error}]`,
          )
          return
        }
        if (studentLinkSchool?.token) {
          auth.setAccessToken(studentLinkSchool.token)
          setToken(studentLinkSchool.token)
        }
      } catch (err) {
        message.error(`${t('エラーが発生しました。')} [${err}]`)
      }

      setIsBirthdayScreen(true)
    }
  }

  const studentAttr = studentData?.attributes

  const [formEdit] = Form.useForm()

  const [listDay, setListDay] = useState(getListData(1, 31))
  const [loadingSubmit, setLoading] = useState<boolean>(false)
  const [token, setToken] = useState('')

  const handleOnEditBirthday = async () => {
    const submitData = formEdit.getFieldsValue()

    if (submitData.yearBirth || submitData.monthBirth || submitData.dayBirth) {
      if (
        !submitData.yearBirth ||
        !submitData.monthBirth ||
        !submitData.dayBirth
      ) {
        message.error(
          t('無効な生年月日が含まれています。確認し、再度登録してください。'),
          8,
        )
        return
      }
      submitData.yearBirth = Number(submitData.yearBirth)
      submitData.monthBirth = Number(submitData.monthBirth)
      submitData.dayBirth = Number(submitData.dayBirth)
    }

    setLoading(true)

    const apiUrl = '/alpha/v1/student/me/'

    try {
      await Api.patch(apiUrl, submitData, {
        headers: {
          'x-auth-token': `Bearer ${token}`,
        },
      })

      // if (ssoRes && ssoEmailUserId) {
      //   auth.setAccessToken(ssoRes.users[ssoEmailUserId].token)
      //   message.success(t('登録しました。'))
      //   setLinkComplete(true)

      //   setTimeout(() => {
      //     history.push('/movie')
      //   }, 2000)
      //   return
      // }

      await onFinish(inputtedCode as string)
    } catch (err) {
      message.error(`${t('エラーが発生しました。')} [${apiUrl}]`, 5)
      console.error(`${apiUrl} error:`, err)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (studentData?._id) {
      formEdit.setFieldsValue({
        yearBirth: studentData.attributes?.yearBirth,
        monthBirth: studentData.attributes?.monthBirth,
        dayBirth: studentData.attributes?.dayBirth,
      })

      handleChange()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentData?._id])

  const handleChange = () => {
    const data = formEdit.getFieldsValue()
    if (!data) return

    if (!(data.yearBirth && data.monthBirth)) {
      return
    }
    const date = new Date(data.yearBirth, data.monthBirth, 0).getDate()

    setListDay(getListData(1, date))
    if (data.dayBirth && data.dayBirth > date) {
      formEdit.setFieldsValue({
        yearBirth: data.yearBirth,
        monthBirth: data.monthBirth,
        dayBirth: '',
      })
    }
  }

  const isLoginScreen = !linkComplete && !isBirthdayScreen && !studentData
  const isConfirmScreen = !linkComplete && !isBirthdayScreen && studentData

  // To fix: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.
  // Keep the `div` tag to wrap the content.
  return (
    <div>
      {isLoginScreen ? (
        <div>
          <QrCodeForm
            onScan={(scannedCode: string | null) => {
              if (scannedCode && scannedCode !== inputtedCode) {
                form.setFieldsValue({ invitationCode: scannedCode })
                inputtedCode = scannedCode

                getStudent({
                  variables: { input: { qrCode: scannedCode } },
                })

                auth.setSchoolLevel(
                  scannedCode.length >= 2 ? scannedCode[1] : '',
                )
              }
            }}
            form={form}
            loading={loading}
            onFormSubmit={(values: { invitationCode: string }) => {
              const currCode = values.invitationCode
              if (!currCode || currCode === inputtedCode) return

              inputtedCode = currCode

              getStudent({
                variables: { input: { qrCode: currCode } },
              })

              auth.setSchoolLevel(currCode.length >= 2 ? currCode[1] : '')
            }}
            onSsoLoginSuccess={(ssoRes: ISsoResponse) => {
              // default value
              setStudentData(ssoRes.users[0])
              setSsoRes(ssoRes)
            }}
          />
        </div>
      ) : linkComplete ? (
        <div>
          <div
            className={`w-full h-screen flex justify-center register-page ${isLoginScreen ? '' : 'overflow-hidden'}`}
            style={{
              background: 'url(/images/SignInBackground.png) no-repeat bottom',
              backgroundSize: 'contain',
            }}
          >
            <div className="flex flex-col relative">
              <div className="w-full text-center absolute mt-11">
                <ruby className="text-2xl font-black">
                  {t('本人確認完了')}
                  {isUsingJp ? (
                    <rt className="text-10px">ほんにんかくにんかんりょう</rt>
                  ) : null}
                </ruby>
              </div>
              <div className="flex-1">
                <CompletionLayout message={t('ようこそ！')} />
              </div>
              <div className="" />
            </div>
          </div>
        </div>
      ) : isConfirmScreen ? (
        <div>
          <div
            className={`w-full flex justify-center relative min-h-screen ${isLoginScreen ? '' : 'overflow-hidden'}`}
          >
            <div className="pt-11 py-16">
              <h1 className="pb-4 text-center">
                <ruby className="text-2xl font-black">
                  {t('本人確認')}
                  {isUsingJp ? (
                    <rt className="text-10px">ほんにんかくにん</rt>
                  ) : null}
                </ruby>
              </h1>

              <RegisterConfirm
                isUsingJp={isUsingJp}
                t={t}
                studentAttr={studentAttr!}
                schoolName={studentAttr!.schoolName}
                checkUser={checkUser}
                inputtedCode={inputtedCode}
                handleReturn={handleReturn}
                ssoRes={ssoRes}
              />
            </div>

            <div
              className="absolute bottom-0 left-0 right-0 w-full"
              style={{
                background:
                  'url(/images/SignInBackground.png) no-repeat bottom',
                backgroundSize: 'contain',
                height: '300px',
                zIndex: -1,
              }}
            />
          </div>
        </div>
      ) : isBirthdayScreen ? (
        <div>
          <div
            className={`w-full h-screen flex justify-center register-page ${isLoginScreen ? '' : 'overflow-hidden'}`}
            style={{
              background: 'url(/images/SignInBackground.png) no-repeat bottom',
              backgroundSize: 'contain',
            }}
          >
            <div className="pt-11 w-800px">
              <h1 className="pb-4 text-center">
                <label className="text-2xl font-black">{t('サインイン')}</label>
              </h1>

              <div className="bg-gray-150 py-20 modal-mypage mx-auto rounded-10px">
                {loadingSubmit ? (
                  <LoadingSpin />
                ) : (
                  <>
                    <div className="show-pc">
                      <div className="space-y-9 formEditUser">
                        <div className="text-xl font-bold text-center">
                          {isUsingJp ? (
                            <span>
                              <ruby>
                                生年月日
                                <rt>せいねんがっぴ</rt>
                              </ruby>
                              <ruby>
                                を入力して
                                <rt
                                  style={{ paddingLeft: 10, marginRight: 30 }}
                                >
                                  にゅうりょく
                                </rt>
                              </ruby>
                              ください
                            </span>
                          ) : (
                            t('生年月日を入力してください。')
                          )}
                        </div>

                        <div>
                          <Form
                            form={formEdit}
                            initialValues={{
                              yearBirth: '',
                              monthBirth: '',
                              dayBirth: '',
                            }}
                          >
                            <div className="space-x-4 flex items-center justify-center">
                              <div className="relative">
                                <span
                                  className="inputDateLabel"
                                  style={isUsingJp ? { marginTop: -6 } : {}}
                                >
                                  {isUsingJp ? (
                                    <ruby>
                                      生年月日
                                      <rt className="text-tiny font-black">
                                        せいねんがっぴ
                                      </rt>
                                    </ruby>
                                  ) : (
                                    t('生年月日')
                                  )}
                                </span>
                                <Form.Item name="yearBirth">
                                  <Select
                                    style={{ width: 120 }}
                                    onChange={handleChange}
                                    options={yearSelections}
                                  />
                                </Form.Item>
                              </div>
                              <span className="mx-2 textDate">
                                {isUsingJp ? (
                                  <ruby>
                                    年<rt>ねん</rt>
                                  </ruby>
                                ) : (
                                  t('年')
                                )}
                              </span>
                              <Form.Item name="monthBirth">
                                <Select
                                  style={{ width: 120 }}
                                  onChange={handleChange}
                                  options={monthSelections}
                                />
                              </Form.Item>
                              <span className="mx-2 textDate">
                                {isUsingJp ? (
                                  <ruby>
                                    月<rt>がつ</rt>
                                  </ruby>
                                ) : (
                                  t('月')
                                )}
                              </span>
                              <Form.Item name="dayBirth">
                                <Select
                                  style={{ width: 120 }}
                                  onChange={handleChange}
                                  options={listDay}
                                />
                              </Form.Item>
                              <span className="mx-2 textDate">
                                {isUsingJp ? (
                                  <ruby>
                                    日<rt>にち</rt>
                                  </ruby>
                                ) : (
                                  t('日')
                                )}
                              </span>
                            </div>

                            <div className="space-x-4 flex items-center justify-center mt-6">
                              <Form.Item>
                                <Button
                                  type="primary"
                                  className="w-30"
                                  onClick={handleOnEditBirthday}
                                >
                                  {isUsingJp ? (
                                    <ruby>
                                      登録
                                      <rt>とうろく</rt>
                                    </ruby>
                                  ) : (
                                    t('登録')
                                  )}
                                </Button>
                              </Form.Item>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>

                    <div className="show-sp">
                      <div className="space-y-9 formEditUser">
                        <div className="text-lg font-bold text-center">
                          {t('生年月日を入力してください。')}
                        </div>

                        <div>
                          <Form
                            form={formEdit}
                            initialValues={{
                              yearBirth: '',
                              monthBirth: '',
                              dayBirth: '',
                            }}
                          >
                            <div className="flex align-center item-end justify-center">
                              <div className="relative">
                                <span className="inputDateLabel">
                                  {t('生年月日')}
                                </span>

                                <Form.Item name="yearBirth">
                                  <Dropdown
                                    onChange={handleChange}
                                    options={yearSelections}
                                  />
                                </Form.Item>
                              </div>

                              <span className="mx-2 textDate">{t('年')}</span>
                              <Form.Item name="monthBirth">
                                <Dropdown
                                  onChange={handleChange}
                                  options={monthSelections}
                                  className={'w-20'}
                                />
                              </Form.Item>
                              <span className="mx-2 textDate">{t('月')}</span>
                              <Form.Item name="dayBirth">
                                <Dropdown
                                  onChange={handleChange}
                                  options={listDay}
                                  className={'w-20'}
                                  value={form.getFieldValue('dayBirth')}
                                />
                              </Form.Item>
                              <span className="mx-2 textDate">{t('日')}</span>
                            </div>
                            <div className="space-x-4 flex items-center justify-center mt-6">
                              <Form.Item>
                                <Button
                                  type="primary"
                                  className="w-30"
                                  onClick={handleOnEditBirthday}
                                >
                                  {t('登録')}
                                </Button>
                              </Form.Item>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default RegisterUserPage
