import { Row, Table } from 'antd'
import { ColumnType } from 'antd/lib/table'
import type { TFunction } from 'i18next'
import { memo, useMemo } from 'react'
import { Rt } from '~/components/common/Rt'

const StandardTable = ({
  t,
  isUsingJpRuby,
  isLevelB,
  testData,
}: {
  t: TFunction<'translation', undefined>
  isUsingJpRuby: boolean
  isLevelB: boolean
  testData: {
    column: ColumnType<object>[]
    test: object[]
    standard_column: ColumnType<object>[]
    standard: object[]
  }
}) => {
  const filteredColumns = useMemo(() => {
    if (!isLevelB) return testData.column
    return testData.column.filter(
      (col) => (col.key as string) !== 'enduranceRun',
    )
  }, [isLevelB, testData.column])

  return (
    <div id="core-table" className="bg-white p-4 rounded-10px">
      <ruby className="font-black">
        {t('項目別得点表')}
        {isUsingJpRuby ? (
          <rt className="text-xxs">こうもくべつとくてんひょう</rt>
        ) : null}
      </ruby>

      <Row className="form-table-sp">
        <Table
          className="table-core-result"
          rowKey="uid"
          columns={filteredColumns}
          dataSource={testData.test}
          size="small"
          rowClassName={(_, idx) => {
            return `h-5 text-10px text-black font-bold ${
              idx % 2 === 0 ? 'ant-table-row-dark' : 'ant-table-row-light'
            }`
          }}
          pagination={{
            hideOnSinglePage: true,
            defaultPageSize: 10,
          }}
        />
      </Row>

      <Row justify="start" className="pt-3">
        <ruby className="font-black">
          {t('総合評価基準表')}
          {isUsingJpRuby ? (
            <Rt className="text-xxs">そうごうひょうかきじゅんひょう</Rt>
          ) : null}
        </ruby>
      </Row>

      <Row className="form-table-sp">
        <Table
          rowKey="uid"
          columns={testData.standard_column}
          dataSource={testData.standard}
          style={{ minWidth: 564 }}
          size="small"
          rowClassName={(_, idx) => {
            return `h-5 text-10px text-black font-bold ${
              idx % 2 === 0 ? 'ant-table-row-dark' : 'ant-table-row-light'
            }`
          }}
          pagination={{
            hideOnSinglePage: true,
            defaultPageSize: 10,
          }}
        />
      </Row>

      <Row justify="end" className="mt-2 text-black">
        {t('引用：文部科学省新体力テスト実施要項')}
      </Row>
    </div>
  )
}

export default memo(StandardTable)
