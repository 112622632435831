import { useEffect, useState } from 'react'
import { checkIfMobileScreen } from '~/student-utils/screen'

export const useResize = () => {
  const [isSpScreen, setIsSpScreen] = useState(checkIfMobileScreen())

  useEffect(() => {
    const handleResize = () => {
      setIsSpScreen(checkIfMobileScreen())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return isSpScreen
}
