import { IVideoRes } from '~/utils/types'

interface VideoThumbProps {
  video:
    | IVideoRes
    | {
        _id?: string | number
        title: string
        thumb: string
        url: string
        lowUrl: string
        desc?: string
        sportName?: string
        ability?: string[]
      }

  onClick: () => void
  onThumbnailLoaded?: () => void
}

export const VideoThumb = ({
  video,
  onClick,
  onThumbnailLoaded,
}: VideoThumbProps) => {
  return (
    <div onClick={onClick} className="relative cursor-pointer">
      <img
        alt=""
        src={video.thumb}
        width={292}
        height={164}
        className="rounded-10px overflow-hidden pointer-events-none cursor-pointer"
        onLoadedData={onThumbnailLoaded}
      />
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="w-20 h-12 border-2 border-solid rounded-lg border-white bg-black bg-opacity-50 flex items-center justify-center transition-opacity">
          <img
            src="/images/play.svg"
            alt="Play"
            width={24}
            height={24}
            className="opacity-90"
          />
        </div>
      </div>
    </div>
  )
}
