import { Button } from 'antd'
import { TFunction } from 'i18next'
import { memo, useEffect, useState } from 'react'
import { chunkMutated } from '~/student-utils/chunk'
import { checkIfMobileScreen } from '~/student-utils/screen'
import { ISsoResponse, IStudentAttr } from '../types'
import RegisterConfirmForSsoManyAcc from './RegisterConfirmForSsoManyAcc'

interface RegisterConfirmProps {
  isUsingJp: boolean
  t: TFunction<'translation', undefined>
  studentAttr: IStudentAttr
  schoolName: string
  checkUser: (invitationCode: string, userId?: string) => void | Promise<void>
  inputtedCode: string | null
  handleReturn: () => void
  ssoRes: ISsoResponse | undefined
}

const RegisterConfirm = ({
  isUsingJp,
  t,
  studentAttr,
  schoolName,
  checkUser,
  inputtedCode,
  handleReturn,
  ssoRes,
}: RegisterConfirmProps) => {
  // Track screen size changes
  const [isSpScreen, setIsSpScreen] = useState(checkIfMobileScreen())

  useEffect(() => {
    const handleResize = () => {
      setIsSpScreen(checkIfMobileScreen())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (!ssoRes?.users) {
    return (
      <div className="bg-gray-150 py-20 modal-mypage mx-auto rounded-10px">
        <div className="space-y-9">
          <div className="text-xl font-bold text-center">
            {t('あなたですか？')}
          </div>

          <div className="show-pc">
            <div className="space-y-7 pl-40 pr-20">
              <div className="flex">
                <div className="flex-1 text-left">
                  <ruby className="font-black">
                    {t('名前')}
                    {isUsingJp ? (
                      <rt className="text-xxs font-black">なまえ</rt>
                    ) : null}
                  </ruby>
                </div>
                <div className="text-lg text-center">
                  {t('フルネーム', {
                    familyName: studentAttr.familyName,
                    givenName: studentAttr.givenName,
                  })}
                </div>
                <div className="flex-1" />
              </div>
              <div className="flex">
                <div className="flex-1 text-left" style={{ minWidth: 50 }}>
                  <ruby className="font-black">
                    {t('学校名')}
                    {isUsingJp ? (
                      <rt className="text-xxs font-black">がっこうめい</rt>
                    ) : null}
                  </ruby>
                </div>
                <div className="text-lg text-center">{schoolName}</div>
                <div className="flex-1" />
              </div>
              <div className="flex">
                <div className="flex-1 text-left">
                  <ruby className="font-black mr-5">
                    {t('学年')}
                    {isUsingJp ? (
                      <rt className="text-xxs font-black">がくねん</rt>
                    ) : null}
                  </ruby>
                  <span className="text-lg">{studentAttr.schoolGrade}</span>
                </div>
                <div className="flex-1 text-left">
                  <ruby className="font-black mr-5">{t('クラス')}</ruby>
                  <span className="text-lg">{studentAttr.schoolClass}</span>
                </div>
                <div className="flex-1 text-left">
                  <ruby className="font-black mr-5">
                    {t('出席番号')}
                    {isUsingJp ? (
                      <rt className="text-xxs font-black">
                        しゅっせきばんごう
                      </rt>
                    ) : null}
                  </ruby>
                  <span className="text-lg">
                    {studentAttr.schoolAttendanceNumber}
                  </span>
                </div>
              </div>
            </div>

            <div className="space-x-4 flex items-center justify-center mt-8">
              <Button
                type="primary"
                className="w-60"
                size="large"
                onClick={() => checkUser(inputtedCode as string)}
              >
                {t('はい')}
              </Button>

              <Button
                type="primary"
                className="w-60"
                size="large"
                onClick={handleReturn}
              >
                {t('いいえ')}
              </Button>
            </div>
          </div>

          <div className="show-sp">
            <div className="space-y-4 px-8">
              <div className="flex">
                <div className="flex-1 text-left flex items-center">
                  <ruby className="font-black">
                    {t('名前')}
                    {isUsingJp ? (
                      <rt className="text-xxs font-black">なまえ</rt>
                    ) : null}
                  </ruby>
                </div>

                <div className="text-lg text-center">
                  {studentAttr.familyName && (
                    <div>
                      {studentAttr.familyName} {studentAttr.givenName}
                    </div>
                  )}
                </div>
                <div className="flex-1" />
              </div>
              <div className="flex">
                <div
                  className="flex-1 text-left flex items-center"
                  style={{ minWidth: 50 }}
                >
                  <ruby className="font-black">
                    {t('学校名')}
                    {isUsingJp ? (
                      <rt className="text-xxs font-black">がっこうめい</rt>
                    ) : null}
                  </ruby>
                </div>
                <div className="text-lg text-center">{schoolName}</div>
                <div className="flex-1" />
              </div>

              <div className="flex">
                <div className="flex-1 text-left">
                  <ruby className="font-black mr-5">
                    {t('学年')}
                    {isUsingJp ? (
                      <rt className="text-xxs font-black">がくねん</rt>
                    ) : null}
                  </ruby>
                  <span className="text-lg">{studentAttr.schoolGrade}</span>
                </div>
                <div className="flex-1 text-left">
                  <ruby className="font-black mr-5">クラス</ruby>
                  <span className="text-lg">{studentAttr.schoolClass}</span>
                </div>
                <div className="flex-1 text-left">
                  <ruby className="font-black mr-5">
                    {t('出席番号')}
                    {isUsingJp ? (
                      <rt className="text-xxs font-black">
                        しゅっせきばんごう
                      </rt>
                    ) : null}
                  </ruby>
                  <span className="text-lg">
                    {studentAttr.schoolAttendanceNumber}
                  </span>
                </div>
              </div>
            </div>
            <div className="space-x-8 flex items-center justify-center mt-8">
              <Button
                type="primary"
                className="w-30"
                onClick={() => checkUser(inputtedCode as string)}
              >
                {t('はい')}
              </Button>

              <Button type="primary" className="w-30" onClick={handleReturn}>
                {t('いいえ')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (ssoRes.users.length ?? 0) > 1 ? (
    <>
      <div className="flex justify-center mb-8 font-bold text-gray-900 text-lg sp:text-base">
        {t('ログインするアカウントを選択してください')}
      </div>

      {!isSpScreen && (
        <div className="w-1024px space-y-8 show-pc">
          {chunkMutated(ssoRes!.users, 2).map((users) => (
            <div className="flex space-x-8" key={users[0]._id}>
              <RegisterConfirmForSsoManyAcc
                isUsingJp={isUsingJp}
                t={t}
                checkUser={checkUser}
                inputtedCode={inputtedCode}
                user={users[0]}
                isSpScreen={isSpScreen}
              />

              {users[1] && (
                <RegisterConfirmForSsoManyAcc
                  isUsingJp={isUsingJp}
                  t={t}
                  checkUser={checkUser}
                  inputtedCode={inputtedCode}
                  user={users[1]}
                  isSpScreen={isSpScreen}
                />
              )}
            </div>
          ))}
        </div>
      )}

      {isSpScreen && (
        <div className="flex flex-col space-y-8 show-sp">
          {ssoRes!.users.map((user) => (
            <RegisterConfirmForSsoManyAcc
              key={user._id}
              isUsingJp={isUsingJp}
              t={t}
              checkUser={checkUser}
              inputtedCode={inputtedCode}
              user={user}
              isSpScreen={isSpScreen}
            />
          ))}
        </div>
      )}

      <div className="flex justify-center">
        <Button className="mt-8 mb-6" type="primary" onClick={handleReturn}>
          {t('ログイン画面へもどる')}
        </Button>
      </div>
    </>
  ) : (
    // 1 account
    <div className="bg-gray-150 py-20 modal-mypage mx-auto rounded-10px">
      <div className="space-y-9">
        <div className="text-xl font-bold text-center">
          {t('あなたですか？')}
        </div>

        <div className="show-pc">
          <div className="space-y-7 pl-40 pr-20">
            <div className="flex">
              <div className="flex-1 text-left">
                <ruby className="font-black">
                  {t('名前')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">なまえ</rt>
                  ) : null}
                </ruby>
              </div>
              <div className="text-lg text-center">
                {t('フルネーム', {
                  familyName: studentAttr.familyName,
                  givenName: studentAttr.givenName,
                })}
              </div>
              <div className="flex-1" />
            </div>
            <div className="flex">
              <div className="flex-1 text-left" style={{ minWidth: 50 }}>
                <ruby className="font-black">
                  {t('学校名')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">がっこうめい</rt>
                  ) : null}
                </ruby>
              </div>
              <div className="text-lg text-center">{schoolName}</div>
              <div className="flex-1" />
            </div>
            <div className="flex">
              <div className="flex-1 text-left">
                <ruby className="font-black mr-5">
                  {t('学年')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">がくねん</rt>
                  ) : null}
                </ruby>
                <span className="text-lg">{studentAttr.schoolGrade}</span>
              </div>
              <div className="flex-1 text-left">
                <ruby className="font-black mr-5">{t('クラス')}</ruby>
                <span className="text-lg">{studentAttr.schoolClass}</span>
              </div>
              <div className="flex-1 text-left">
                <ruby className="font-black mr-5">
                  {t('出席番号')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">しゅっせきばんごう</rt>
                  ) : null}
                </ruby>
                <span className="text-lg">
                  {studentAttr.schoolAttendanceNumber}
                </span>
              </div>
            </div>
          </div>

          <div className="space-x-4 flex items-center justify-center mt-8">
            <Button
              type="primary"
              className="w-60"
              size="large"
              onClick={() =>
                checkUser(inputtedCode as string, ssoRes.users[0]._id)
              }
            >
              {t('はい')}
            </Button>

            <Button
              type="primary"
              className="w-60"
              size="large"
              onClick={handleReturn}
            >
              {t('いいえ')}
            </Button>
          </div>
        </div>

        <div className="show-sp">
          <div className="space-y-4 px-8">
            <div className="flex">
              <div className="flex-1 text-left flex items-center">
                <ruby className="font-black">
                  {t('名前')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">なまえ</rt>
                  ) : null}
                </ruby>
              </div>

              <div className="text-lg text-center">
                {studentAttr.familyName && (
                  <div>
                    {studentAttr.familyName} {studentAttr.givenName}
                  </div>
                )}
              </div>
              <div className="flex-1" />
            </div>
            <div className="flex">
              <div
                className="flex-1 text-left flex items-center"
                style={{ minWidth: 50 }}
              >
                <ruby className="font-black">
                  {t('学校名')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">がっこうめい</rt>
                  ) : null}
                </ruby>
              </div>
              <div className="text-lg text-center">{schoolName}</div>
              <div className="flex-1" />
            </div>

            <div className="flex">
              <div className="flex-1 text-left">
                <ruby className="font-black mr-5">
                  {t('学年')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">がくねん</rt>
                  ) : null}
                </ruby>
                <span className="text-lg">{studentAttr.schoolGrade}</span>
              </div>
              <div className="flex-1 text-left">
                <ruby className="font-black mr-5">クラス</ruby>
                <span className="text-lg">{studentAttr.schoolClass}</span>
              </div>
              <div className="flex-1 text-left">
                <ruby className="font-black mr-5">
                  {t('出席番号')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">しゅっせきばんごう</rt>
                  ) : null}
                </ruby>
                <span className="text-lg">
                  {studentAttr.schoolAttendanceNumber}
                </span>
              </div>
            </div>
          </div>
          <div className="space-x-8 flex items-center justify-center mt-8">
            <Button
              type="primary"
              className="w-30"
              onClick={() =>
                checkUser(inputtedCode as string, ssoRes.users[0]._id)
              }
            >
              {t('はい')}
            </Button>

            <Button type="primary" className="w-30" onClick={handleReturn}>
              {t('いいえ')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(RegisterConfirm)
