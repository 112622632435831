export const studentWebRouteNames = [
  'home',
  'advise',
  'my_page',
  'questionnaire',
  'questionnaire_result',
  'training',
  'test',
  'result',
  'logout',
  'movie',
  'videos',
  'view',
  'tests',
  'past_result',
] as const
