import { ReactElement } from 'react'
import { StudentQuestionExtraText } from '~/utils/types'

export type TPrefectureCityQuestions = [number, string, number[]]

export interface Question {
  question: string
  id: number
  name: string
  options: Record<string, number>
  kanjiQuestion: Record<string, string>[]
  kanjiOption: Record<string, Record<string, string>[]>

  /**
   * @example
   * {
   *   '1': {
   *    name: '所属していない',
   *    hiragana: '所属していない_しょぞくしていない',
   *  }
   * }
   */
  extra?: Record<string, StudentQuestionExtraText>
}

export enum AnswerType {
  options = 'options',
  text = 'text', // can enter text value
  number = 'number', // can enter number value
  multipleSelect = 'multipleSelect',
}

export interface StudentProcessingQuestion {
  question: string | ReactElement
  answerType?: AnswerType // default is options
  answerUnit?: string
  id: number | string
  _id?: number
  name: string
  options: Record<string, number>
  kanjiQuestion: Record<string, string>[]
  kanjiOption: Record<string, Record<string, string>[]>

  /**
   * @example
   * {
   *   '1': {
   *    name: '所属していない',
   *    hiragana: '所属していない_しょぞくしていない',
   *  }
   * }
   */
  extra?: Record<string, StudentQuestionExtraText>
  extra25?: Record<string, StudentQuestionExtraText>
}
