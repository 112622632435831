import { createBlankArr } from '.'
import { allQuestions } from './questions/all-questions'
import {
  customQuestionForSchoolIds,
  customQuestionForSchoolIdsKeys,
  prefectureCityQuestionForElementStudent,
  prefectureCityQuestionForJuniorOrHighSchoolStudent,
  yamaguchiPrefHighSchoolQuestions,
} from './questions/prefecture-question'
import type { TPrefectureCityQuestions } from './types'

export const getCurrentPrefectureQuestions = (
  prefectureCode: number,
  schoolId: string,
  isElementarySchool: boolean,
  schoolCategoryCode: string | undefined,
): number[] => {
  const prefectureCityQuestions = isElementarySchool
    ? prefectureCityQuestionForElementStudent
    : prefectureCityQuestionForJuniorOrHighSchoolStudent

  let findingQuestions: TPrefectureCityQuestions | undefined

  for (const key of customQuestionForSchoolIdsKeys) {
    if (customQuestionForSchoolIds[key].includes(schoolId)) {
      findingQuestions = prefectureCityQuestions.find((item) => item[1] === key)
      break
    }
  }

  if (findingQuestions === undefined) {
    if (prefectureCode === 35 && schoolCategoryCode === 'D1') {
      // 山口県（高校）
      return yamaguchiPrefHighSchoolQuestions
    }

    findingQuestions = prefectureCityQuestions.find(
      (item) => item[0] === prefectureCode,
    )
  }

  const questionList: number[] =
    findingQuestions !== undefined ? findingQuestions[2] : []

  if (questionList.length === 0) {
    console.error('No questions found in the prefecture data!')
  }

  return questionList
}

export const getCurrentPrefectureQuestionsWithPrefName = (
  prefectureCode: number,
  schoolId: string,
  isElementarySchool: boolean,
): { questions: number[]; name: string } => {
  const prefectureCityQuestions = isElementarySchool
    ? prefectureCityQuestionForElementStudent
    : prefectureCityQuestionForJuniorOrHighSchoolStudent

  let findingQuestions: TPrefectureCityQuestions | undefined

  for (const key of customQuestionForSchoolIdsKeys) {
    if (customQuestionForSchoolIds[key].includes(schoolId)) {
      findingQuestions = prefectureCityQuestions.find((item) => item[1] === key)
      break
    }
  }

  if (findingQuestions === undefined) {
    findingQuestions = prefectureCityQuestions.find(
      (item) => item[0] === prefectureCode,
    )
  }

  const questionList: number[] =
    findingQuestions !== undefined ? findingQuestions[2] : []

  if (questionList.length === 0) {
    console.error('No questions found in the prefecture data!')
  }

  return { questions: questionList, name: findingQuestions?.[1] ?? '' }
}

export const getIsOptionalQuestions = (questionList: number[]): boolean[] => {
  const isOptionalQuestions: boolean[] = []

  for (const questionId of questionList) {
    const findingQuestion = allQuestions.find(
      (question) => question.id === questionId,
    )

    isOptionalQuestions.push(findingQuestion?.isOptional ?? false)
  }

  return isOptionalQuestions
}

export const countRequiredQuestionsLength = (
  questionList: number[],
): number => {
  let count = 0

  for (const questionId of questionList) {
    const isFoundQuestion = allQuestions.some(
      (question) => question.id === questionId && !question.isOptional,
    )

    if (isFoundQuestion) count++
  }

  return count
}

/**
 * @returns array of number from 1 to length of questions list
 */
export const getSortedQuestionnairesIdArr = (
  prefectureCode: number,
  schoolCode: string,
  isElementary: boolean,
  schoolCategoryCode: string | undefined,
) => {
  const questionList = getCurrentPrefectureQuestions(
    prefectureCode,
    schoolCode,
    isElementary,
    schoolCategoryCode,
  )

  return createBlankArr(questionList.length)
}
