import { SchoolCategoryCode, TUserGender } from '../../utils/constants'
import { averagePref2025 } from './average-each-pref'
import { IPrefectureAverage } from './prefectureCodes'

interface PrefectureAverageData {
  gender: TUserGender
  schoolCategoryCode: SchoolCategoryCode
  grade: number
  age: number
  measurements: number[]
}

const parseAverageData = (
  data: readonly (string | number)[],
): PrefectureAverageData => ({
  gender: data[0] as TUserGender,
  schoolCategoryCode: data[1] as SchoolCategoryCode,
  grade: data[2] as number,
  age: data[3] as number,
  measurements: data.slice(4).map(Number),
})

export const getPrefectureAverage = (
  prefectureCode: number | undefined,
  gender: TUserGender | undefined,
  schoolGrade: number | undefined | null,
  schoolCategoryCode: string | undefined,
): false | IPrefectureAverage | undefined => {
  if (!prefectureCode || !gender || !schoolGrade || !schoolCategoryCode) {
    console.error('getPrefectureAverage - missing required parameters')
    return false
  }

  const prefectureData = averagePref2025[prefectureCode.toString()]

  if (!prefectureData || prefectureData.length === 0) {
    console.warn(
      `getPrefectureAverage - no data for prefecture ${prefectureCode}`,
    )
    return false
  }

  const matchingData = prefectureData
    .map(parseAverageData)
    .find(
      (data) =>
        data.gender === gender &&
        data.grade === schoolGrade &&
        data.schoolCategoryCode === schoolCategoryCode,
    )

  if (!matchingData) {
    console.warn(
      `getPrefectureAverage - no matching data for prefecture ${prefectureCode}, gender ${gender}, grade ${schoolGrade}, category ${schoolCategoryCode}`,
    )
    return false
  }

  const [
    grip,
    sitUps,
    bending,
    sideJump,
    shuttleRun,
    enduranceRun,
    sprintRun,
    standingJump,
    handballThrow,
    totalScore,
    height,
    weight,
  ] = matchingData.measurements

  return {
    grade: matchingData.grade,
    age: matchingData.age,
    schoolCategoryCode: matchingData.schoolCategoryCode as SchoolCategoryCode,
    grip: grip ?? null,
    sitUps: sitUps ?? null,
    bending: bending ?? null,
    sideJump: sideJump ?? null,
    shuttleRun: shuttleRun ?? null,
    enduranceRun: enduranceRun ?? null,
    sprintRun: sprintRun ?? null,
    standingJump: standingJump ?? null,
    handballThrow: handballThrow ?? null,
    totalScore: totalScore ?? null,
    height: height ?? null,
    weight: weight ?? null,
  }
}
