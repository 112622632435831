// export const prefectureCodes = [
//   [1, '北海道'],
//   [2, '青森県'],
//   [3, '岩手県'],
//   [4, '宮城県'],
//   [5, '秋田県'],
//   [6, '山形県'],
//   [7, '福島県'],
//   [8, '茨城県'],
//   [9, '栃木県'],
//   [10, '群馬県'],
//   [11, '埼玉県'],
//   [12, '千葉県'],
//   [13, '東京都'],
//   [14, '神奈川県'],
//   [15, '新潟県'],
//   [16, '富山県'],
//   [17, '石川県'],
//   [18, '福井県'],
//   [19, '山梨県'],
//   [20, '長野県'],
//   [21, '岐阜県'],
//   [22, '静岡県'],
//   [23, '愛知県'],
//   [24, '三重県'],
//   [25, '滋賀県'],
//   [26, '京都府'],
//   [27, '大阪府'],
//   [28, '兵庫県'],
//   [29, '奈良県'],
//   [30, '和歌山県'],
//   [31, '鳥取県'],
//   [32, '島根県'],
//   [33, '岡山県'],
//   [34, '広島県'],
//   [35, '山口県'],
//   [36, '徳島県'],
//   [37, '香川県'],
//   [38, '愛媛県'],
//   [39, '高知県'],
//   [40, '福岡県'],
//   [41, '佐賀県'],
//   [42, '長崎県'],
//   [43, '熊本県'],
//   [44, '大分県'],
//   [45, '宮崎県'],
//   [46, '鹿児島県'],
//   [47, '沖縄県'],
// ] as const

/**
 * Average for prefecture (平均値（三重県 - Mie県）...).
 * Make it possible to compare with the latest national average
 *
 * To create this data, please run the script below from API repository:
 * ```sh
 * cd scripts/mongo-db/
 * bun read-average
 * ```
 */
export const averagePref2025 = {
  '1': [],
  '2': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.89,
      11.85,
      25.69,
      28.14,
      19.5,
      null,
      11.87,
      113.46,
      8.14,
      30.21,
      117.5,
      22.4,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.66,
      14.19,
      27.19,
      32.61,
      28.84,
      null,
      10.98,
      124.44,
      10.98,
      37.14,
      123.6,
      25.5,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.31,
      16.1,
      28.86,
      35.81,
      35.88,
      null,
      10.5,
      133.79,
      14.34,
      42.53,
      129.4,
      29.3,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.26,
      17.74,
      30.77,
      39.56,
      42.98,
      null,
      10.12,
      141.49,
      17.37,
      57.66,
      134.9,
      33.3,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.4,
      19.4,
      32.69,
      42.61,
      50.3,
      null,
      9.68,
      151.23,
      20.74,
      52.93,
      140.8,
      37.6,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19.39,
      20.96,
      34.44,
      45.48,
      58.81,
      null,
      9.25,
      161.71,
      24.19,
      58.24,
      147.3,
      41.9,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      24.47,
      22.64,
      40.54,
      48.27,
      64.43,
      457.85,
      8.66,
      179.4,
      16.77,
      33.79,
      155.5,
      48.1,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      30.1,
      25.88,
      45.75,
      52.14,
      77.94,
      422.06,
      7.96,
      199.51,
      20.33,
      42.71,
      162.5,
      52.8,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.87,
      28.59,
      49.25,
      54.76,
      86.26,
      407.01,
      7.51,
      213.06,
      22.85,
      49.55,
      166.7,
      57.1,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      37.17,
      28.32,
      49.15,
      55.85,
      79.93,
      413.68,
      7.44,
      216.19,
      23.17,
      50.2,
      169.1,
      60.6,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      39.54,
      30.18,
      51.44,
      57.33,
      85.92,
      412.35,
      7.32,
      224.22,
      24.86,
      54.14,
      170.6,
      62.4,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      40.93,
      31.39,
      52.49,
      57.57,
      86.22,
      403.91,
      7.21,
      226.14,
      25.96,
      56.07,
      171.2,
      64.4,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.44,
      11.37,
      28.32,
      27.38,
      16.83,
      null,
      12.08,
      105.73,
      5.68,
      30.73,
      116.9,
      22.1,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      10.01,
      13.91,
      30.24,
      31.98,
      23.86,
      null,
      11.18,
      116.58,
      7.44,
      38.3,
      122.9,
      24.9,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.77,
      15.78,
      32.17,
      35.11,
      29.36,
      null,
      10.66,
      126.53,
      9.48,
      44.21,
      129.1,
      28.4,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.93,
      17.4,
      34.4,
      38.38,
      35.89,
      null,
      10.23,
      135.2,
      11.51,
      49.9,
      135.6,
      32.5,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      16.53,
      18.68,
      37.33,
      41.46,
      42.82,
      null,
      9.78,
      145.12,
      13.81,
      55.77,
      142.8,
      37.3,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      19.24,
      19.44,
      38.95,
      43.31,
      46.69,
      null,
      9.48,
      151.12,
      15.55,
      59.58,
      149,
      42.2,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.37,
      19.46,
      43.69,
      44.94,
      47.37,
      323.67,
      9.2,
      160.68,
      10.95,
      42.98,
      153.2,
      46.1,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.33,
      21.66,
      47.04,
      46.57,
      51.23,
      309.51,
      8.96,
      166.85,
      12.39,
      48.15,
      155.6,
      48.8,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.86,
      22.81,
      49.84,
      47.14,
      56.21,
      315.02,
      8.83,
      168.38,
      13.26,
      50.9,
      156.9,
      51,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.09,
      22.61,
      49.28,
      47.9,
      47.2,
      331.18,
      8.9,
      169.6,
      13.18,
      50.08,
      157.4,
      51.6,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      26.07,
      23.7,
      50.46,
      48.53,
      47.97,
      337.93,
      8.88,
      171.06,
      13.6,
      51.78,
      158,
      52.5,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.65,
      24.08,
      51.52,
      47.85,
      47.19,
      335.49,
      8.9,
      169.97,
      14.07,
      52.33,
      158.4,
      53.2,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '3': [],
  '4': [
    [
      'MALE',
      'B1',
      1,
      6,
      9.16,
      11.36,
      27.07,
      26.79,
      17.98,
      null,
      11.82,
      111.54,
      8.21,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.7,
      13.46,
      28.24,
      30.55,
      26,
      null,
      10.97,
      121.81,
      11.29,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.47,
      15.65,
      29.95,
      34.12,
      33.37,
      null,
      10.43,
      131.89,
      14.64,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.24,
      17.06,
      31.66,
      37.33,
      38.34,
      null,
      9.98,
      139.35,
      17.98,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.32,
      18.62,
      34.31,
      40.88,
      45.53,
      null,
      9.61,
      148.18,
      21.06,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19.44,
      20.42,
      35.92,
      44.25,
      52.06,
      null,
      9.17,
      160.67,
      24.43,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      24.14,
      22.74,
      41.01,
      48.27,
      61.75,
      452.66,
      8.65,
      179.49,
      17,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.43,
      26.22,
      45.87,
      51.96,
      76.44,
      421.83,
      8.08,
      198.25,
      20.04,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.01,
      28.61,
      49.38,
      54.47,
      82.74,
      414.18,
      7.73,
      210.13,
      22.42,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.68,
      28.04,
      48.95,
      56.58,
      79.36,
      437.56,
      7.55,
      217.14,
      22.71,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.64,
      29.63,
      51.31,
      57.98,
      86.68,
      425.01,
      7.35,
      223.51,
      24.56,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      39.91,
      30.38,
      52.27,
      58.49,
      85.44,
      420.29,
      7.28,
      225.05,
      25.43,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.66,
      10.9,
      29.12,
      25.91,
      14.92,
      null,
      12.16,
      103.93,
      5.58,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      10.12,
      13.06,
      30.89,
      29.57,
      20.64,
      null,
      11.23,
      113.51,
      7.37,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.74,
      14.71,
      33.18,
      32.62,
      25.44,
      null,
      10.7,
      123.62,
      9.36,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.78,
      16.34,
      35.48,
      36.01,
      29.93,
      null,
      10.22,
      132.27,
      11.37,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      16.39,
      17.56,
      38.58,
      39.12,
      35.32,
      null,
      9.81,
      141.29,
      13.34,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      19.13,
      18.7,
      40.45,
      41.66,
      39.4,
      null,
      9.47,
      149.95,
      14.97,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.15,
      19.16,
      43.72,
      44.02,
      41.75,
      332.8,
      9.29,
      159.37,
      10.3,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.09,
      21.71,
      47.11,
      45.69,
      48.35,
      314.12,
      9.04,
      164.88,
      11.77,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.4,
      22.84,
      48.93,
      46.3,
      49.48,
      311.44,
      8.93,
      166.7,
      12.62,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      24.82,
      22.13,
      48.83,
      48.18,
      45.34,
      337.21,
      9.02,
      169.4,
      12.7,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      25.61,
      22.71,
      49.41,
      47.96,
      46.38,
      340.42,
      9.01,
      169.29,
      13.15,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.13,
      23.35,
      50.39,
      48.24,
      44.97,
      334.48,
      9.01,
      169.22,
      13.5,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '5': [],
  '6': [
    [
      'MALE',
      'B1',
      1,
      6,
      9.18,
      12.08,
      26.51,
      27.79,
      19.19,
      null,
      11.77,
      114.98,
      8.74,
      30.92,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.73,
      14.46,
      28.02,
      31.71,
      28.84,
      null,
      10.92,
      126.74,
      11.89,
      37.63,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.68,
      16.38,
      29.79,
      35.61,
      37.33,
      null,
      10.36,
      136.19,
      15.37,
      43.64,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.41,
      17.73,
      31.37,
      39.19,
      42.28,
      null,
      9.97,
      143.72,
      18.17,
      48.21,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.51,
      19.31,
      33.96,
      42.09,
      49.13,
      null,
      9.62,
      151.86,
      21.44,
      53.08,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19.42,
      21.06,
      35.13,
      45.09,
      57.33,
      null,
      9.25,
      162.85,
      24.45,
      58.33,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      24.33,
      22.23,
      40.88,
      47.83,
      65.61,
      444.91,
      8.62,
      181.04,
      17.82,
      33.34,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      30.22,
      25.65,
      44.55,
      50.85,
      80.43,
      404.09,
      7.98,
      198.89,
      20.78,
      41.85,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.62,
      27.68,
      48.12,
      53.79,
      87.57,
      384.92,
      7.59,
      211.92,
      22.98,
      48.21,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.49,
      27.78,
      47.01,
      54.86,
      83.1,
      421.08,
      null,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.72,
      29.68,
      49.7,
      57.45,
      87.97,
      405.57,
      null,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      39.87,
      30.46,
      51.81,
      57.74,
      88.81,
      420.35,
      null,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.53,
      11.75,
      28.91,
      27.2,
      16.48,
      null,
      12.01,
      108.24,
      5.83,
      31.26,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.97,
      13.91,
      30.47,
      30.58,
      23.16,
      null,
      11.2,
      119.06,
      7.75,
      38.04,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.9,
      15.59,
      32.94,
      34.33,
      28.82,
      null,
      10.62,
      128.76,
      9.9,
      44.37,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.81,
      17.14,
      35.24,
      37.86,
      34.01,
      null,
      10.19,
      137.65,
      11.96,
      50.03,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      16.36,
      18.55,
      38.33,
      40.58,
      40.46,
      null,
      9.72,
      145.58,
      14.34,
      55.65,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      19.23,
      19.71,
      39.94,
      43.19,
      46.45,
      null,
      9.4,
      153.86,
      16.19,
      60.41,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.22,
      18.82,
      43.63,
      46.28,
      46.28,
      317.17,
      9.18,
      160.26,
      11.17,
      41.82,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.49,
      21.29,
      46.72,
      52.99,
      52.99,
      301.95,
      8.94,
      164.97,
      12.63,
      47.31,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      25.07,
      22.83,
      49.28,
      54.25,
      54.25,
      298.81,
      8.81,
      169.51,
      13.65,
      50.79,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      24.91,
      22.34,
      48.34,
      47.47,
      50.71,
      361.89,
      8.96,
      168.77,
      13.41,
      49.45,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      25.72,
      23.67,
      49.9,
      48.52,
      52.05,
      365.82,
      8.94,
      169.96,
      14.11,
      51.57,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      25.94,
      24.39,
      50.2,
      48.02,
      48.69,
      344.93,
      8.96,
      169.47,
      14.33,
      51.63,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '7': [],
  '8': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.88,
      12.84,
      26.85,
      27.22,
      20.49,
      null,
      11.68,
      114.96,
      8.18,
      31.2,
      116.66,
      21.63,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.52,
      15.37,
      28.9,
      31.18,
      29.46,
      null,
      10.83,
      125.42,
      11.25,
      38.07,
      122.7,
      24.67,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.34,
      17.92,
      31.38,
      35.05,
      37.21,
      null,
      10.19,
      135.54,
      14.53,
      44.36,
      128.51,
      28.06,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.31,
      19.45,
      33.76,
      39.03,
      44.08,
      null,
      9.76,
      144.92,
      17.67,
      49.92,
      134.09,
      31.92,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.64,
      20.62,
      36.4,
      42.29,
      49.72,
      null,
      9.38,
      153.39,
      20.77,
      54.84,
      139.85,
      36.2,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19.86,
      22.43,
      39.17,
      45.1,
      56.66,
      null,
      8.95,
      164.49,
      23.89,
      60.52,
      146.24,
      40.81,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      24.42,
      24.12,
      43.42,
      48.37,
      64.66,
      null,
      8.45,
      182.82,
      17.78,
      35.67,
      154.33,
      46.17,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      30.05,
      27.17,
      47.5,
      52.32,
      80.77,
      null,
      7.8,
      199.51,
      21.19,
      44.53,
      161.18,
      51.12,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.87,
      29.19,
      51.16,
      54.91,
      87.19,
      null,
      7.42,
      213.84,
      23.9,
      51.18,
      165.93,
      55.54,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.61,
      28.09,
      48.16,
      56.47,
      79.95,
      null,
      7.48,
      216.32,
      23.12,
      49.47,
      168.26,
      59.8,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.53,
      29.16,
      50.35,
      57.4,
      85.39,
      null,
      7.37,
      221.91,
      24.37,
      52.62,
      169.57,
      61.23,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      39.85,
      30.02,
      51.63,
      58.24,
      86.15,
      null,
      7.28,
      225.19,
      25.54,
      54.67,
      170.32,
      62.76,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.37,
      12.17,
      29.1,
      26.32,
      17.05,
      null,
      11.97,
      107.86,
      5.86,
      31.44,
      115.69,
      21.18,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.98,
      14.69,
      31.54,
      30.2,
      23.44,
      null,
      11.09,
      117.78,
      7.65,
      38.69,
      121.74,
      24.02,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.68,
      17.11,
      34.83,
      33.5,
      29.09,
      null,
      10.51,
      127.4,
      9.78,
      45.26,
      127.8,
      27.29,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.72,
      18.75,
      37.66,
      37.56,
      35.71,
      null,
      10.02,
      136.85,
      11.92,
      51.55,
      134.2,
      31.17,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      16.3,
      19.71,
      40.78,
      40.56,
      41.32,
      null,
      9.59,
      146.48,
      13.95,
      57.03,
      141.27,
      35.78,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      19.52,
      20.97,
      43.67,
      42.76,
      45.72,
      null,
      9.23,
      154.15,
      15.86,
      61.94,
      147.64,
      40.7,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.7,
      21.24,
      46.8,
      44.39,
      47.58,
      null,
      9.02,
      162.67,
      11.53,
      45.27,
      152.25,
      44.82,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.87,
      22.67,
      49.52,
      46.06,
      52.97,
      null,
      8.74,
      168.35,
      13.02,
      50.28,
      154.72,
      47.64,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      25.22,
      23.82,
      51.59,
      46.96,
      53.11,
      null,
      8.67,
      170.62,
      13.98,
      52.87,
      156.09,
      49.67,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      24.97,
      22.22,
      49.19,
      48.11,
      46.28,
      null,
      8.94,
      169.15,
      13.06,
      49.64,
      156.62,
      51.4,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      25.78,
      22.86,
      50.6,
      48.48,
      48,
      null,
      8.98,
      170.58,
      13.46,
      51.17,
      157.14,
      52.14,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.27,
      23.51,
      52.1,
      48.89,
      46.94,
      null,
      8.98,
      171.46,
      14.07,
      52.21,
      157.51,
      53.1,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '9': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.71,
      11.39,
      25.75,
      27.17,
      18.35,
      null,
      11.91,
      111.31,
      7.85,
      29.33,
      116.5,
      21.48,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.4,
      13.89,
      26.69,
      31.08,
      27.45,
      null,
      10.99,
      122.91,
      10.95,
      36.08,
      122.6,
      24.49,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.13,
      15.91,
      28.93,
      34.48,
      33.81,
      null,
      10.42,
      132.14,
      14.3,
      41.87,
      128.39,
      28.05,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      13.83,
      17.81,
      30.32,
      38.62,
      40.61,
      null,
      9.98,
      140.15,
      17.28,
      47.06,
      133.83,
      31.74,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      15.99,
      19.18,
      32.5,
      41.7,
      47.19,
      null,
      9.58,
      148.64,
      20.59,
      52.15,
      139.5,
      35.91,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      18.92,
      20.98,
      35.23,
      44.84,
      54.97,
      null,
      9.16,
      159.82,
      23.63,
      57.86,
      145.72,
      40.25,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      23.95,
      22.99,
      39.75,
      49.2,
      64.44,
      null,
      8.72,
      178.75,
      17.12,
      33.22,
      153.63,
      45.95,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.31,
      25.9,
      44.34,
      52.55,
      79.16,
      null,
      8.05,
      197.18,
      19.93,
      41.63,
      160.55,
      50.5,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.34,
      28.04,
      48.38,
      56,
      86.37,
      null,
      7.6,
      211.75,
      22.56,
      48.75,
      165.66,
      55.41,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.14,
      27.53,
      49.05,
      56.53,
      79.91,
      null,
      7.57,
      217.92,
      22.48,
      48.93,
      168.13,
      59.5,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.62,
      29.09,
      51.78,
      58.02,
      86.21,
      null,
      7.41,
      225.57,
      24.11,
      53.21,
      169.57,
      60.98,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      39.96,
      30.11,
      53.16,
      59.02,
      86.64,
      null,
      7.36,
      228.28,
      25.13,
      55.04,
      170.31,
      63.02,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.28,
      11.23,
      28.14,
      26.37,
      15.49,
      null,
      12.15,
      104.82,
      5.4,
      29.62,
      115.7,
      21.17,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.8,
      13.55,
      29.73,
      30.08,
      21.62,
      null,
      11.28,
      115.16,
      7.26,
      36.73,
      121.61,
      23.93,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.46,
      15.4,
      32.21,
      33.38,
      25.79,
      null,
      10.69,
      124.43,
      9.35,
      42.72,
      127.58,
      27.3,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.33,
      17.34,
      34.34,
      37.14,
      31.98,
      null,
      10.21,
      133.79,
      11.42,
      48.75,
      133.86,
      31.06,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      15.86,
      18.47,
      37.14,
      40.11,
      38.4,
      null,
      9.76,
      142.9,
      13.67,
      54.5,
      140.88,
      35.54,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      18.93,
      19.55,
      40.3,
      42.49,
      43.27,
      null,
      9.4,
      150.68,
      15.53,
      59.53,
      147.52,
      40.65,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.37,
      20.19,
      43.67,
      45.56,
      47.56,
      null,
      9.22,
      162.98,
      11.2,
      43.48,
      151.92,
      44.5,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.57,
      21.76,
      46.17,
      47.01,
      52.91,
      null,
      8.96,
      168.05,
      12.47,
      48.22,
      154.63,
      47.78,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      25.04,
      22.99,
      48.43,
      48.39,
      54.07,
      null,
      8.83,
      172.19,
      13.5,
      51.56,
      156,
      49.84,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.06,
      22.14,
      48.51,
      48.69,
      47.42,
      null,
      9.06,
      171.8,
      13.03,
      49.74,
      156.73,
      51.36,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      25.87,
      22.97,
      50.83,
      49.41,
      48.79,
      null,
      9.06,
      173.9,
      13.48,
      51.63,
      157.21,
      52.14,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.43,
      23.76,
      51.85,
      49.77,
      48.83,
      null,
      9.06,
      175.02,
      13.97,
      52.83,
      157.68,
      52.93,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '10': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.93,
      1.76,
      26.41,
      26.47,
      18.34,
      null,
      11.71,
      111.97,
      7.98,
      29.68,
      119.16,
      22.25,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.48,
      14.03,
      27.92,
      30.08,
      26,
      null,
      10.87,
      123.18,
      11,
      36.08,
      123.47,
      25.21,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.58,
      15.66,
      29.88,
      33.76,
      33.33,
      null,
      10.42,
      132.35,
      14.3,
      41.66,
      129.34,
      29.58,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.13,
      17.35,
      31.95,
      37.66,
      40.27,
      null,
      9.88,
      140.76,
      17.32,
      46.98,
      135.37,
      32.56,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.74,
      19.29,
      34.56,
      41.1,
      47.56,
      null,
      9.49,
      149.66,
      20.3,
      52.44,
      140.96,
      37.83,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19.6,
      21.24,
      37.61,
      44.81,
      55.01,
      null,
      8.98,
      162.65,
      23.72,
      58.81,
      147.12,
      41.31,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      23.84,
      23.04,
      40.72,
      48.11,
      61.58,
      443.68,
      8.62,
      181.01,
      16.69,
      32.79,
      153.85,
      45.81,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.24,
      25.99,
      44.31,
      51.83,
      77.76,
      407.47,
      7.98,
      198.74,
      19.73,
      41.05,
      160.81,
      50.81,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      33.63,
      27.89,
      47.93,
      54.3,
      85.61,
      387.26,
      7.59,
      211.96,
      22.2,
      47.47,
      165.46,
      54.81,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.81,
      27.83,
      48.15,
      55.83,
      79.84,
      391.02,
      7.43,
      216.35,
      22.92,
      49.17,
      168.11,
      58.98,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.81,
      29.08,
      49.29,
      57.01,
      85.17,
      384.85,
      7.29,
      221.76,
      23.99,
      52.15,
      169.66,
      60.35,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      40.37,
      30.62,
      51.74,
      58.27,
      88.84,
      381.7,
      7.19,
      226.62,
      25.35,
      55.2,
      170.38,
      62.34,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.35,
      11.47,
      28.8,
      25.57,
      15.28,
      null,
      12.04,
      105.2,
      5.5,
      29.85,
      118.49,
      21.59,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.9,
      13.5,
      30.93,
      29.36,
      20.42,
      null,
      11.24,
      115.91,
      7.26,
      36.67,
      122.69,
      24.62,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.83,
      15.4,
      33.46,
      32.71,
      25.59,
      null,
      10.7,
      125.06,
      9.29,
      42.74,
      129.12,
      28.42,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.73,
      16.89,
      35.86,
      36.22,
      31.93,
      null,
      10.13,
      135.5,
      11.46,
      48.81,
      135.37,
      31.92,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      16.61,
      18.9,
      39.24,
      40.02,
      38.84,
      null,
      9.63,
      144.72,
      13.76,
      55.35,
      142.08,
      36.9,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      19.38,
      19.85,
      42.53,
      42.53,
      42.56,
      null,
      9.34,
      153.26,
      15.44,
      60.14,
      148.82,
      41.46,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.54,
      20.19,
      44.84,
      44.51,
      46.52,
      312.43,
      9.15,
      164.01,
      10.87,
      43,
      152.07,
      44.51,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.62,
      22.14,
      47.34,
      46.45,
      53.33,
      297.97,
      8.86,
      170.8,
      12.38,
      48.61,
      154.75,
      47.58,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      25.13,
      23.6,
      49.75,
      47.64,
      55.17,
      294.19,
      8.76,
      173.86,
      13.52,
      52.13,
      156.21,
      49.8,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.48,
      22.63,
      50.53,
      48.79,
      47.95,
      302.35,
      8.78,
      173.44,
      13.2,
      51.43,
      157,
      51.04,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      26.24,
      23.41,
      51.24,
      48.94,
      49.71,
      303.51,
      8.79,
      173.13,
      13.67,
      52.44,
      157.66,
      51.98,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.56,
      24.25,
      53.29,
      49.59,
      49.17,
      306.15,
      8.81,
      174.48,
      14.1,
      53.74,
      157.76,
      52.12,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '11': [
    [
      'MALE',
      'B1',
      1,
      6,
      9.01,
      12.27,
      27.47,
      27.88,
      21.09,
      null,
      11.56,
      116.53,
      8.04,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.59,
      15.34,
      29.23,
      31.97,
      30.3,
      null,
      10.72,
      127.8,
      10.85,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.34,
      17.49,
      31.14,
      35.52,
      37.87,
      null,
      10.18,
      137,
      13.76,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.06,
      19.18,
      33.19,
      39.23,
      44.69,
      null,
      9.77,
      145.64,
      16.8,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.17,
      20.92,
      35.85,
      43.02,
      51.4,
      null,
      9.39,
      155.05,
      19.65,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19.07,
      22.69,
      38.04,
      46.16,
      59.23,
      null,
      6.93,
      165.55,
      22.78,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      23.85,
      24.76,
      43.1,
      48.05,
      69.61,
      426.39,
      8.55,
      182.81,
      17.79,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.68,
      28.25,
      48.09,
      51.89,
      81.88,
      393.24,
      7.9,
      201.91,
      21.26,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.49,
      30.75,
      52.19,
      54.71,
      85.65,
      377.98,
      7.49,
      216.06,
      24.25,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.15,
      29.23,
      48.89,
      56.25,
      79.6,
      377.68,
      7.47,
      218.84,
      23.36,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.31,
      31.28,
      50.68,
      57.71,
      85.88,
      364.34,
      7.31,
      225.46,
      24.82,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      39.67,
      32.32,
      52.34,
      58.53,
      86.07,
      361.75,
      7.22,
      229.83,
      25.87,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.53,
      11.8,
      29.98,
      26.71,
      17.79,
      null,
      11.87,
      109.22,
      5.69,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      10.06,
      14.82,
      32.34,
      30.54,
      24.14,
      null,
      11.06,
      119.72,
      7.43,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.79,
      16.96,
      34.9,
      33.82,
      29.41,
      null,
      10.5,
      129.96,
      9.36,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.65,
      18.66,
      37.46,
      37.64,
      35.62,
      null,
      10.04,
      139.8,
      11.34,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      16.22,
      20.14,
      40.89,
      41.16,
      41.87,
      null,
      9.59,
      149.33,
      13.29,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      19.1,
      21.25,
      43.67,
      43.62,
      46.9,
      null,
      9.19,
      157.13,
      14.94,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.58,
      22.13,
      46.89,
      44.46,
      51.95,
      307.56,
      9.07,
      165,
      11.62,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      24.06,
      24.71,
      50.25,
      46.58,
      56.72,
      293.3,
      8.75,
      172.42,
      13.52,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      25.57,
      26.27,
      53.07,
      47.47,
      56.44,
      291.61,
      8.64,
      175.73,
      14.64,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.35,
      24.37,
      50.36,
      48.76,
      49.83,
      293.59,
      8.85,
      173.89,
      13.81,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      26.15,
      25.56,
      51.27,
      49.37,
      53.98,
      287.58,
      8.78,
      175.61,
      14.28,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.71,
      26.4,
      52.44,
      49.65,
      54.62,
      286.28,
      8.75,
      177.68,
      14.69,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '12': [
    [
      'MALE',
      'B1',
      1,
      6,
      9.55,
      12.27,
      26.65,
      27.11,
      19.02,
      null,
      11.3,
      117.5,
      8.73,
      31.57,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      11.09,
      14.47,
      28.71,
      31.09,
      26.86,
      null,
      10.55,
      126.08,
      11.72,
      38.11,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      13.01,
      16.39,
      30.62,
      34.76,
      34.61,
      null,
      10.01,
      137.68,
      15.16,
      44.25,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.85,
      18.05,
      32.23,
      38.5,
      41.12,
      null,
      9.62,
      145.17,
      18.31,
      49.15,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      17.13,
      20.32,
      35.48,
      42.66,
      49.54,
      null,
      9.23,
      155.87,
      21.25,
      55.44,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      20.98,
      22.38,
      38.82,
      45.84,
      57.27,
      null,
      8.77,
      170.39,
      24.57,
      61.9,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      23.62,
      23.16,
      41.55,
      48.18,
      64.52,
      null,
      8.45,
      180.77,
      16.62,
      33.95,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.79,
      26.72,
      47.01,
      51.92,
      80.97,
      null,
      7.77,
      200.28,
      20.04,
      43.58,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.19,
      28.16,
      46.69,
      54.07,
      85.54,
      null,
      7.44,
      213.18,
      22.61,
      49.45,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      35.73,
      27.36,
      49.6,
      53.99,
      81.96,
      null,
      7.55,
      212.61,
      21.76,
      47.9,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      37.98,
      29.36,
      50.46,
      54.68,
      84.67,
      null,
      7.38,
      219.4,
      23.27,
      51.24,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      39.34,
      30,
      50.82,
      54.26,
      85.07,
      null,
      7.3,
      221.71,
      23.85,
      52.56,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.83,
      11.51,
      29.46,
      26.28,
      15.65,
      null,
      11.67,
      108.39,
      5.95,
      31.46,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      10.27,
      13.8,
      31.8,
      29.54,
      21.18,
      null,
      10.9,
      118.31,
      7.84,
      38.22,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      12.46,
      15.93,
      34.6,
      33.48,
      26.38,
      null,
      10.38,
      128.94,
      9.92,
      44.96,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      14.34,
      17.21,
      36.74,
      36.55,
      31.92,
      null,
      9.95,
      138.43,
      11.94,
      50.5,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      17.1,
      19.13,
      40.14,
      40.31,
      38.93,
      null,
      9.51,
      148.03,
      13.52,
      56.72,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      19.86,
      20.09,
      43.19,
      42.98,
      42.93,
      null,
      9.21,
      156.11,
      15.25,
      61.44,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.3,
      20.23,
      45.91,
      44.23,
      46.24,
      null,
      9.07,
      160.7,
      10.73,
      43.22,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.48,
      22.16,
      48.36,
      45.94,
      52.2,
      null,
      8.79,
      168.12,
      12.24,
      48.65,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.84,
      23.47,
      49.78,
      46.56,
      52.94,
      null,
      8.67,
      171.5,
      13.33,
      51.55,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      24.38,
      21.89,
      47.73,
      45.85,
      47.45,
      null,
      9.03,
      164.87,
      12.65,
      47.67,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      25.88,
      24.02,
      50.42,
      47.61,
      51.26,
      null,
      8.85,
      172.41,
      13.81,
      52.28,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.55,
      24.98,
      52.53,
      47.38,
      51.63,
      null,
      8.82,
      170.47,
      13.79,
      53.19,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '13': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.7,
      10.9,
      26.1,
      26.3,
      16.2,
      null,
      11.5,
      113.2,
      7.4,
      29.1,
      117.2,
      21.2,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.4,
      13.6,
      27.7,
      30.1,
      25.1,
      null,
      10.6,
      124,
      10.5,
      36.4,
      123.3,
      24,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.2,
      15.7,
      30,
      33.6,
      32.3,
      null,
      10.1,
      133.8,
      13.7,
      42.4,
      129,
      27.1,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.1,
      17.7,
      31.8,
      37.2,
      39.1,
      null,
      9.6,
      142.5,
      16.8,
      47.8,
      134.5,
      30.6,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.2,
      19.3,
      34.2,
      40.8,
      45.7,
      null,
      9.3,
      151.6,
      19.8,
      53.1,
      139.9,
      34.1,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19,
      21.2,
      36.4,
      44.3,
      53.1,
      null,
      8.9,
      163.1,
      23.2,
      58.9,
      146.4,
      39,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      23.6,
      23.2,
      39.4,
      48.7,
      64.3,
      438.8,
      8.6,
      181,
      17.4,
      32.7,
      154.6,
      44.4,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      28.9,
      25.9,
      43.2,
      51.8,
      77.9,
      397.5,
      7.9,
      198.3,
      20.4,
      40.9,
      161.8,
      49.3,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      33.4,
      28,
      46.5,
      54.4,
      84.9,
      386.4,
      7.5,
      211.6,
      23,
      47.4,
      166.7,
      53.8,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      35.9,
      27.7,
      46.6,
      55.6,
      78.8,
      399.8,
      7.5,
      218.5,
      22.8,
      47.8,
      169,
      57,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      37.8,
      29,
      48.5,
      57,
      86,
      389.8,
      7.3,
      224.3,
      24.1,
      51.3,
      170.4,
      58.5,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      39.4,
      30.2,
      49.7,
      58.1,
      86.6,
      384,
      7.3,
      227.7,
      25.1,
      53.6,
      171.2,
      60.3,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.1,
      10.5,
      28.5,
      25.3,
      13.1,
      null,
      11.9,
      104.9,
      5.1,
      28.7,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.8,
      13,
      30.8,
      28.9,
      18.7,
      null,
      11,
      115.3,
      6.8,
      36.2,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.5,
      15.1,
      33.6,
      31.7,
      23.4,
      null,
      10.4,
      125,
      8.6,
      42.5,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.5,
      17,
      36,
      35.5,
      28.9,
      null,
      10,
      134.9,
      10.5,
      48.6,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      16,
      18.5,
      38.8,
      39,
      34.7,
      null,
      9.5,
      144.6,
      12.4,
      54.5,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      18.9,
      19.6,
      41.7,
      41.7,
      39.6,
      null,
      9.2,
      153.1,
      14,
      59.6,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.1,
      20.2,
      43.4,
      44.7,
      44.5,
      321,
      9.2,
      162.8,
      10.7,
      41.9,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      22.9,
      22,
      45.6,
      45.9,
      50.3,
      301.7,
      8.9,
      167.4,
      12.1,
      46.8,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.1,
      23,
      47.2,
      46.6,
      50.7,
      302.4,
      8.8,
      169.4,
      13.1,
      49.2,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      24.7,
      22.5,
      47.3,
      48,
      47.6,
      317.8,
      8.9,
      171.5,
      13,
      48.7,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      25.5,
      23.5,
      48.5,
      48.9,
      50.2,
      313.2,
      8.9,
      173.8,
      13.5,
      50.6,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      25.9,
      23.9,
      49.1,
      49.1,
      49.9,
      314.8,
      8.9,
      174.2,
      13.9,
      51.2,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '14': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.59,
      10.95,
      27.78,
      24.81,
      15.87,
      null,
      11.78,
      112,
      7.59,
      28.87,
      116.98,
      21.47,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.41,
      13.79,
      30.04,
      28.22,
      23.46,
      null,
      10.79,
      124.19,
      10.72,
      36.12,
      123.21,
      24.48,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.08,
      15.9,
      30.86,
      31.43,
      31.12,
      null,
      10.19,
      133.49,
      13.82,
      41.57,
      128.7,
      27.41,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.23,
      17.55,
      33.85,
      34.53,
      37.56,
      null,
      9.83,
      142.28,
      17.09,
      46.9,
      134.47,
      31.32,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.35,
      19.34,
      34.83,
      37.97,
      45.53,
      null,
      9.48,
      151.47,
      20.19,
      52,
      139.98,
      35.21,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19.17,
      21.19,
      37.22,
      41.98,
      52.66,
      null,
      9.02,
      160.38,
      23.56,
      57.87,
      146.49,
      39.64,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      23.79,
      22.82,
      40.57,
      46.52,
      62.99,
      450.75,
      8.59,
      179.28,
      17.38,
      32.43,
      154.62,
      45,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      28.84,
      25.74,
      43.52,
      49.93,
      77.33,
      406.16,
      8.04,
      193.82,
      20.12,
      40.13,
      161.58,
      50.04,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      33.66,
      27.76,
      46.24,
      52.64,
      84.45,
      390.81,
      7.62,
      207.83,
      22.84,
      46.66,
      166.23,
      54.66,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      35.31,
      26.28,
      44.77,
      52.11,
      77.36,
      430.01,
      7.71,
      205.69,
      22.08,
      44.64,
      168.62,
      58.43,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      37.35,
      27.75,
      46.03,
      53.55,
      81.63,
      413.23,
      7.6,
      208.91,
      23.35,
      47.69,
      169.78,
      59.66,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      38.37,
      28.48,
      47.38,
      53.86,
      82.02,
      422.68,
      7.52,
      213.68,
      24.28,
      49.46,
      170.72,
      61.38,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      36.41,
      19.54,
      41.91,
      42.58,
      50.46,
      null,
      8.15,
      168.65,
      19.56,
      35.33,
      167.59,
      60.33,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      7.99,
      10.81,
      29.86,
      23.85,
      12.92,
      null,
      12.14,
      104.65,
      5.17,
      28.46,
      116.17,
      21,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.77,
      13.24,
      32.61,
      26.47,
      17.45,
      null,
      11.22,
      115.28,
      6.98,
      35.61,
      122.33,
      24.98,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.32,
      15.21,
      34.31,
      29.78,
      22.71,
      null,
      10.56,
      124.32,
      8.99,
      41.27,
      127.94,
      27.02,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.6,
      16.57,
      37.26,
      32.41,
      26.99,
      null,
      10.16,
      133.68,
      10.81,
      47.1,
      134.31,
      30.78,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      16.2,
      18.3,
      39.19,
      36.05,
      33.14,
      null,
      9.69,
      144.73,
      12.91,
      53.36,
      141.66,
      35.21,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      18.63,
      18.77,
      41.36,
      38.5,
      36.7,
      null,
      9.38,
      149.15,
      13.77,
      57.12,
      148.16,
      40.12,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.09,
      19.24,
      43.81,
      42.01,
      40.41,
      334.35,
      9.25,
      157.78,
      10.66,
      39.9,
      142.78,
      43.71,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      22.88,
      21.17,
      44.64,
      43.99,
      49.27,
      311.53,
      8.99,
      162.43,
      12.05,
      44.94,
      155.13,
      46.31,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.42,
      22.81,
      47.09,
      46.15,
      51.39,
      304.07,
      8.84,
      168.28,
      13.4,
      49.32,
      157.04,
      48.93,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      23.86,
      20.96,
      45.49,
      45.17,
      44.69,
      312.02,
      9.15,
      162.99,
      12.41,
      45.56,
      157.25,
      50.3,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      24.59,
      21.8,
      46.57,
      45.8,
      46.41,
      347.55,
      9.14,
      163.46,
      13.04,
      47,
      157.73,
      51.48,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      25.36,
      22.32,
      47.52,
      45.78,
      45.91,
      344.36,
      9.23,
      164.62,
      13.52,
      47.85,
      158.25,
      52.12,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      25.71,
      14.07,
      40.12,
      33.47,
      18.56,
      null,
      10.92,
      137.15,
      11.25,
      28.6,
      157.27,
      54.33,
    ],
  ],
  '15': [
    [
      'MALE',
      'B1',
      1,
      6,
      9,
      12.25,
      27.13,
      28.04,
      21.71,
      null,
      11.77,
      114.93,
      8.32,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.77,
      14.66,
      29.6,
      32.75,
      32.68,
      null,
      10.86,
      126.19,
      11.67,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.67,
      16.45,
      30.92,
      35.17,
      38.42,
      null,
      10.28,
      135.57,
      14.9,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.55,
      18.42,
      32.74,
      39.65,
      46.09,
      null,
      9.85,
      145.38,
      17.81,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.74,
      19.86,
      34.96,
      43.18,
      51.36,
      null,
      9.49,
      153.28,
      21,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19.82,
      21.66,
      37.46,
      46.23,
      58.97,
      null,
      9.05,
      165.09,
      24.16,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      24.48,
      23.74,
      43.48,
      48.66,
      67.05,
      null,
      8.58,
      185.75,
      17.92,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.75,
      26.74,
      47.61,
      51.64,
      79.56,
      null,
      7.96,
      204.15,
      21.27,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.44,
      28.86,
      50.62,
      54.26,
      85.62,
      null,
      7.57,
      216.47,
      23.89,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.71,
      27.5,
      48.35,
      55.14,
      81.81,
      null,
      7.56,
      220.28,
      23.46,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.76,
      29.37,
      50.08,
      56.69,
      88.33,
      null,
      7.4,
      224.87,
      24.8,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      40.17,
      30.57,
      51.35,
      57.19,
      88.09,
      null,
      7.3,
      226.96,
      25.69,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      36.04,
      21.96,
      39.88,
      48.96,
      49.23,
      null,
      7.97,
      204.92,
      21.38,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.38,
      11.73,
      29.85,
      26.96,
      18.01,
      null,
      12.08,
      107.33,
      5.75,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      10,
      14.04,
      32.46,
      35.59,
      25.43,
      null,
      11.13,
      119.58,
      8.01,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.8,
      15.58,
      34.24,
      33.79,
      29.93,
      null,
      10.58,
      127.97,
      10.03,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.78,
      17.32,
      36.79,
      37.89,
      35.55,
      null,
      10.17,
      137.36,
      11.57,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      16.47,
      18.9,
      39.42,
      41.47,
      41.79,
      null,
      9.71,
      146.6,
      13.74,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      19.34,
      19.71,
      42.27,
      43.57,
      45.64,
      null,
      9.37,
      155.39,
      15.45,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.39,
      20.48,
      46.62,
      44.5,
      47.69,
      null,
      9.15,
      165.63,
      11.24,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.62,
      22.51,
      49.66,
      46.05,
      51.56,
      null,
      8.92,
      170.64,
      12.71,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.9,
      23.58,
      51.44,
      46.69,
      51.23,
      null,
      8.85,
      171.86,
      13.67,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.29,
      21.95,
      49.48,
      47.33,
      48.6,
      null,
      9.01,
      171.8,
      13.24,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      26.05,
      23.53,
      50.23,
      48.33,
      52.11,
      null,
      8.93,
      172.75,
      13.6,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.47,
      24.43,
      51.39,
      48.3,
      50.85,
      null,
      8.93,
      172.32,
      13.87,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      25.83,
      17.25,
      43.42,
      38.58,
      21.25,
      null,
      9.91,
      152,
      10.25,
      null,
    ],
  ],
  '16': [],
  '17': [
    [
      'MALE',
      'B1',
      1,
      6,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.34,
      17.92,
      34.09,
      38.93,
      45.06,
      null,
      9.7,
      147.96,
      18.5,
      50.17,
      134.36,
      31.54,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.68,
      19.77,
      36.79,
      43.9,
      53.53,
      null,
      9.35,
      156.03,
      21.78,
      55.9,
      139.82,
      35.35,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19.63,
      21.63,
      38.46,
      47.03,
      61.21,
      null,
      8.93,
      166.81,
      25.13,
      61.25,
      146.58,
      39.97,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      23.97,
      22.76,
      41.95,
      48.83,
      65.59,
      449.49,
      8.64,
      183.24,
      18.12,
      34.38,
      154.45,
      45.73,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.52,
      25.81,
      45.84,
      52.25,
      81.2,
      410.05,
      8.02,
      202.02,
      21.06,
      42.91,
      161.69,
      50.3,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.56,
      28.37,
      50.29,
      54.64,
      88.62,
      393.8,
      7.58,
      216.2,
      24.17,
      50.18,
      166.57,
      54.89,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.5,
      27.99,
      49.66,
      55.67,
      82.36,
      410.08,
      7.62,
      217.07,
      23.33,
      49.23,
      169.05,
      59.16,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.66,
      29.61,
      50.91,
      57.55,
      91.61,
      387.31,
      7.38,
      226.53,
      24.89,
      53.59,
      170.3,
      60.4,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      40.07,
      30.4,
      52.39,
      58.32,
      92.45,
      388.1,
      7.31,
      229.06,
      25.73,
      55.64,
      170.91,
      62.05,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.69,
      16.91,
      37.91,
      34.66,
      34.66,
      null,
      9.98,
      141.86,
      12.15,
      51.27,
      134.6,
      30.83,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      16.44,
      18.76,
      41.15,
      43.18,
      43.18,
      null,
      9.58,
      150.84,
      14.44,
      57.86,
      141.83,
      35.28,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      19.13,
      19.85,
      43.44,
      48.24,
      48.24,
      null,
      9.2,
      158.27,
      16.19,
      62.44,
      148.08,
      39.98,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.47,
      19.37,
      45.15,
      44.84,
      47.28,
      316.18,
      9.22,
      165.2,
      11.66,
      43.8,
      152.8,
      44.34,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.34,
      21.18,
      47.94,
      46.22,
      51.55,
      307.99,
      8.98,
      169.83,
      12.87,
      48.45,
      155.42,
      47.12,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.91,
      22.54,
      49.61,
      47.04,
      51.4,
      305.45,
      8.89,
      172.66,
      14,
      51.24,
      157.1,
      49.45,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      24.87,
      21.37,
      49.92,
      47.19,
      46.09,
      328.54,
      9.12,
      169.06,
      13.31,
      48.74,
      157.46,
      50.85,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      25.57,
      22.86,
      50.75,
      48.28,
      50.12,
      315.33,
      9.01,
      173.35,
      14.04,
      51.6,
      157.83,
      51.54,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.25,
      23.56,
      48.28,
      48.99,
      49.46,
      328.38,
      8.98,
      173.77,
      14.42,
      53.65,
      158.13,
      52.22,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  // 18 from 2022, 福井県, 現在のALPHAと同じ数値で問題なし
  '18': [
    [
      'MALE',
      'B1',
      1,
      6,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.54,
      17.7,
      34.51,
      40.14,
      49.24,
      null,
      9.63,
      149.53,
      19.07,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.82,
      19.97,
      36.89,
      43.97,
      57.16,
      null,
      9.3,
      158.69,
      22.52,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      20.01,
      21.63,
      38.67,
      46.46,
      65.74,
      null,
      8.87,
      169.64,
      25.72,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      24.42,
      23.17,
      42.01,
      49.38,
      72.24,
      423.04,
      8.53,
      185.2,
      18.72,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.77,
      27.12,
      46.38,
      53.01,
      85.02,
      383.06,
      7.85,
      204.98,
      22.37,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.53,
      28.97,
      49.35,
      55.12,
      92.9,
      368.22,
      7.45,
      216.71,
      25.26,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      37.22,
      29.65,
      49.28,
      56.81,
      85.37,
      388.48,
      7.4,
      219.82,
      23.84,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      39.87,
      31.17,
      51.91,
      58.49,
      93.06,
      378.69,
      7.18,
      227.36,
      25.65,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      41.38,
      32.55,
      52.8,
      59.73,
      92.35,
      372.11,
      7.09,
      230.87,
      26.85,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.93,
      16.87,
      37.87,
      38.39,
      40.32,
      null,
      9.88,
      142.71,
      13.09,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      16.51,
      19.1,
      41.27,
      42.34,
      49,
      null,
      9.48,
      153.84,
      15.24,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      19.43,
      20.38,
      42.98,
      44.56,
      53.68,
      null,
      9.12,
      161.25,
      17.27,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.66,
      20.46,
      45.77,
      46.17,
      55.42,
      297.7,
      9.03,
      169.3,
      12.28,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.91,
      22.63,
      48.38,
      47.16,
      59.9,
      287.57,
      8.78,
      173.28,
      13.63,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      25.28,
      24.08,
      50.54,
      47.45,
      59.72,
      284.5,
      8.64,
      174.83,
      14.45,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.68,
      24.18,
      50.61,
      48.7,
      54.15,
      302.26,
      8.8,
      173.69,
      13.71,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      26.7,
      25.16,
      52.17,
      50.03,
      55.04,
      303.76,
      8.72,
      175.5,
      14.24,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      27.19,
      26.49,
      53.02,
      50.56,
      56.19,
      299.2,
      8.77,
      176.37,
      14.85,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '19': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.77,
      11.3,
      26.68,
      25.88,
      15.7,
      null,
      11.83,
      112.8,
      7.8,
      29.08,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.2,
      13.69,
      28.08,
      29.5,
      23.71,
      null,
      10.92,
      122.01,
      10.78,
      35.6,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.15,
      15.65,
      29.92,
      32.67,
      32.67,
      null,
      10.39,
      132.99,
      13.81,
      41.37,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      13.74,
      17.13,
      31.15,
      36.61,
      37.64,
      null,
      9.82,
      139.31,
      16.97,
      46.49,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      15.81,
      18.85,
      33.4,
      40.04,
      43.06,
      null,
      9.48,
      149.3,
      20.08,
      51.64,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      18.85,
      20.27,
      34.91,
      43.11,
      50.15,
      null,
      9.12,
      158.62,
      2.88,
      56.72,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      24.65,
      22.72,
      41.12,
      48.93,
      64.67,
      null,
      8.6,
      181.64,
      17.63,
      34.25,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.82,
      25.95,
      44.27,
      52.5,
      78.79,
      null,
      8.01,
      198.72,
      20.32,
      42.14,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.89,
      28.57,
      48.29,
      55.03,
      86.3,
      null,
      7.58,
      212.28,
      23.39,
      49.13,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      37.43,
      27.95,
      47.59,
      57.03,
      84.91,
      null,
      7.44,
      220.29,
      22.7,
      50.31,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      39.75,
      29.92,
      49.55,
      58.5,
      89.03,
      null,
      7.33,
      226.97,
      24.09,
      54.05,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      41.27,
      30.48,
      51.56,
      58.98,
      88.59,
      null,
      7.24,
      229.07,
      24.64,
      55.54,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.26,
      11.17,
      28.82,
      25.39,
      13.41,
      null,
      12.17,
      106.55,
      5.51,
      29.41,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.55,
      13.49,
      31.12,
      28.65,
      18.44,
      null,
      11.22,
      115.14,
      7.26,
      36.25,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.45,
      15.07,
      33.31,
      31.87,
      24.18,
      null,
      10.7,
      125.22,
      9.4,
      42.29,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.15,
      16.54,
      35.13,
      35.4,
      27.89,
      null,
      10.13,
      132.56,
      11.36,
      45.78,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      15.81,
      18.13,
      38.17,
      38.78,
      32.97,
      null,
      9.73,
      143.77,
      13.61,
      53.77,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      18.67,
      18.8,
      40.08,
      37.58,
      9.38,
      null,
      9.38,
      148.5,
      15.2,
      58.05,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.83,
      19.69,
      43.28,
      44.61,
      44.28,
      null,
      9.13,
      161.1,
      11.58,
      43.2,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.61,
      21.67,
      45.77,
      46.57,
      51.8,
      null,
      8.92,
      167.41,
      12.7,
      48.43,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      25,
      22.95,
      47.98,
      47.13,
      52.34,
      null,
      8.81,
      169.55,
      13.68,
      51.05,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.23,
      21.48,
      46.84,
      48.31,
      48.28,
      null,
      8.92,
      170.67,
      12.78,
      49.39,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      26.49,
      22.6,
      48.69,
      49.27,
      49.2,
      null,
      8.9,
      172.32,
      13.38,
      51.56,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.89,
      23.23,
      49.5,
      49.53,
      49.01,
      null,
      8.87,
      173.52,
      13.57,
      52.5,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '20': [
    [
      'MALE',
      'B1',
      1,
      6,
      9.38,
      11.19,
      24.6,
      26.35,
      19.44,
      null,
      11.6,
      114.94,
      8.35,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      11.36,
      14.22,
      26.79,
      30.88,
      28.48,
      null,
      10.88,
      124.83,
      11.8,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.95,
      15.85,
      29.22,
      33.8,
      35.43,
      null,
      10.2,
      135.74,
      14.99,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.34,
      16.66,
      30.38,
      36.4,
      39.64,
      null,
      10.07,
      142.41,
      17.72,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      17.11,
      19.37,
      34.36,
      41.18,
      49.23,
      null,
      9.51,
      152.05,
      21.39,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      20.51,
      20.69,
      36.49,
      45.27,
      56.88,
      null,
      9.1,
      164.6,
      25.07,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      24.31,
      22.76,
      41.51,
      46.36,
      62.97,
      null,
      8.5,
      181.75,
      17.92,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.7,
      25.79,
      45.63,
      49.48,
      76.52,
      null,
      7.9,
      198.24,
      21.04,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.69,
      27.83,
      47.28,
      52.53,
      83.74,
      null,
      7.51,
      214.09,
      23.96,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.12,
      26.53,
      46.71,
      54,
      82.78,
      null,
      7.47,
      218.56,
      23.22,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.8,
      28.71,
      49.68,
      56.42,
      87.08,
      null,
      7.32,
      223.12,
      24.47,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      40.19,
      29.82,
      51.22,
      57.36,
      88.31,
      null,
      7.25,
      229.86,
      25.52,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.65,
      10.85,
      27.34,
      26.12,
      16.57,
      null,
      11.89,
      106.99,
      5.81,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      10.44,
      13.5,
      29.68,
      29.86,
      22.11,
      null,
      11.15,
      117.8,
      7.82,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      12.14,
      14.85,
      32.34,
      32.34,
      26.79,
      null,
      10.58,
      128.14,
      9.83,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.61,
      15.86,
      33.88,
      34.84,
      31.14,
      null,
      10.35,
      134.4,
      11.75,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      16.41,
      17.57,
      38.71,
      38.53,
      38.24,
      null,
      9.81,
      144.34,
      14.1,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      19.6,
      18.99,
      40.77,
      41.99,
      41.73,
      null,
      9.42,
      150.97,
      15.91,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.7,
      19.3,
      45.4,
      42.8,
      44.38,
      null,
      9.11,
      161.43,
      11.39,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.79,
      21.32,
      47.58,
      44.11,
      49.62,
      null,
      8.87,
      169.66,
      12.65,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      25.33,
      22.56,
      49.61,
      45.05,
      49.97,
      null,
      8.73,
      172.57,
      13.74,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.37,
      21.34,
      49.04,
      46.63,
      47.14,
      null,
      8.97,
      173.84,
      13.34,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      25.91,
      22.29,
      49.35,
      47.39,
      49.36,
      null,
      8.96,
      173.83,
      13.93,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.39,
      23.06,
      51.09,
      48.03,
      49.02,
      null,
      8.94,
      174.81,
      14.12,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '21': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.53,
      11.06,
      26.21,
      24.52,
      15.74,
      null,
      11.7,
      111.06,
      7.91,
      28.67,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.38,
      13.4,
      27.84,
      28.8,
      24.32,
      null,
      10.86,
      122.98,
      11.41,
      35.77,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.06,
      15.51,
      30.08,
      32.04,
      29.98,
      null,
      10.3,
      133.08,
      14.32,
      41.28,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.09,
      17.19,
      31.87,
      34.9,
      35.86,
      null,
      9.92,
      139.93,
      17.27,
      46.03,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.04,
      18.58,
      33.82,
      40.18,
      43.73,
      null,
      9.38,
      129.11,
      21,
      51.98,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      18.81,
      20.74,
      36.54,
      42.97,
      48.65,
      null,
      9.05,
      159.51,
      24.04,
      57.13,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      23.62,
      23.34,
      41.28,
      48.84,
      60.02,
      448.21,
      8.54,
      178.37,
      18.33,
      33.73,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.22,
      25.99,
      45.66,
      52.57,
      75.44,
      415.15,
      7.94,
      198.56,
      20.79,
      41.94,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34,
      28.31,
      49.24,
      54.96,
      86.56,
      399.9,
      7.47,
      210.71,
      24.13,
      49.49,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.5,
      28.35,
      49.46,
      57.37,
      85.36,
      393.7,
      7.38,
      221.22,
      24.47,
      51.38,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.83,
      30.77,
      52.39,
      59.22,
      91.78,
      377.82,
      7.18,
      228.48,
      25.94,
      56.01,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      40.53,
      31.81,
      54.07,
      60.03,
      92.55,
      379,
      7.1,
      232.33,
      27.11,
      58.24,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      39.93,
      25.12,
      45.39,
      52.76,
      57.64,
      498.67,
      7.63,
      211.69,
      22.45,
      45.16,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.13,
      10.62,
      28.23,
      25.06,
      13.53,
      null,
      11.97,
      104.88,
      5.54,
      29.02,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.61,
      12.13,
      30.06,
      27.71,
      18.45,
      null,
      11.27,
      113.64,
      7.43,
      35.05,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.51,
      14.96,
      32.98,
      30.82,
      22.57,
      null,
      10.59,
      126.22,
      9.37,
      41.95,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.66,
      16.45,
      35.02,
      33.82,
      28.37,
      null,
      10.11,
      133.61,
      11.8,
      47.72,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      15.95,
      17.7,
      37.95,
      38.03,
      33.47,
      null,
      9.68,
      141.93,
      13.72,
      53.47,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      18.66,
      18.97,
      41.5,
      39.97,
      38.17,
      null,
      9.36,
      149.93,
      15.39,
      58.24,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.11,
      20.3,
      44.57,
      44.91,
      41.72,
      324.73,
      9.13,
      162.63,
      12.28,
      43.48,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.77,
      21.92,
      48.17,
      47.18,
      48.75,
      300.31,
      8.9,
      167.59,
      13.34,
      48.96,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      25.03,
      23.32,
      50.7,
      47.87,
      50.94,
      307.64,
      8.75,
      170.71,
      14.51,
      52.51,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.14,
      22.27,
      50.53,
      49.02,
      49.91,
      313.7,
      8.81,
      173.84,
      14.26,
      51.89,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      26.11,
      23.75,
      52.37,
      49.78,
      50.9,
      308.11,
      8.78,
      174.18,
      14.71,
      53.85,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.56,
      24.45,
      53.96,
      50.1,
      51.16,
      311.68,
      8.77,
      174.92,
      15.18,
      55.09,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      25.21,
      17.87,
      40.84,
      42,
      30.17,
      null,
      9.78,
      155.16,
      12.6,
      40.21,
      null,
    ],
  ],
  '22': [
    [
      'MALE',
      'B1',
      1,
      6,
      9.31,
      11.74,
      26.37,
      27.73,
      19.99,
      null,
      11.61,
      117.13,
      8.72,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.81,
      14.21,
      28.12,
      31.63,
      28.16,
      null,
      10.88,
      126.96,
      11.8,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.37,
      15.68,
      29.23,
      33.52,
      33.78,
      null,
      10.32,
      135.75,
      14.42,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.12,
      17.34,
      31.1,
      41.07,
      41.5,
      null,
      9.9,
      144.83,
      17.56,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      15.97,
      19.14,
      33.1,
      41.64,
      48.32,
      null,
      9.45,
      152.1,
      20.37,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19.2,
      20.99,
      35.73,
      44.87,
      55.98,
      null,
      9,
      164.9,
      23.43,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      25.11,
      24.02,
      42.88,
      50.43,
      70.18,
      432.11,
      8.38,
      188.65,
      18.57,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      30.4,
      27.28,
      47.64,
      54.1,
      81.74,
      399.64,
      7.81,
      206.23,
      21.57,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.53,
      29.49,
      51.63,
      57.05,
      88.64,
      385.73,
      7.43,
      219.51,
      24.31,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      37.72,
      30.84,
      53.9,
      59.33,
      91.3,
      375.59,
      7.21,
      228.43,
      25.88,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      39.65,
      32.26,
      55.86,
      60.5,
      94.02,
      371.59,
      7.07,
      234.03,
      27.2,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      40.54,
      32.97,
      56.46,
      61.04,
      89.57,
      376.3,
      7.04,
      236.45,
      28.42,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      40.34,
      25.98,
      48.95,
      55.34,
      60.68,
      null,
      7.51,
      221.31,
      26.57,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.81,
      11.19,
      29.1,
      26.84,
      16.31,
      null,
      11.98,
      109.91,
      5.9,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      10.18,
      13.07,
      31.24,
      30.3,
      21.29,
      null,
      11.3,
      118.69,
      7.71,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.77,
      14.57,
      32.37,
      31.79,
      25.05,
      null,
      10.7,
      127.06,
      9.35,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.58,
      16.29,
      34.98,
      35.78,
      31.3,
      null,
      10.25,
      136.76,
      11.4,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      15.95,
      17.97,
      37.87,
      39.63,
      38.4,
      null,
      9.73,
      145.45,
      13.58,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      18.86,
      18.98,
      40.66,
      42.09,
      42.62,
      null,
      9.37,
      154.11,
      15.19,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      22.02,
      20.36,
      46.41,
      46.06,
      48.24,
      313.69,
      9.05,
      168.1,
      11.88,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      24.03,
      22.23,
      49.48,
      47.56,
      52.42,
      303.57,
      8.83,
      173.58,
      13.2,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      25.36,
      23.78,
      52.44,
      49.02,
      52.57,
      300.99,
      8.71,
      178.19,
      14.42,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.96,
      24.26,
      53.97,
      50.53,
      53.76,
      300.42,
      8.67,
      179.68,
      14.72,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      26.63,
      25.15,
      55.81,
      51.2,
      53.71,
      300.81,
      8.64,
      181.41,
      15.29,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.92,
      25.98,
      57.41,
      51.73,
      51.53,
      305.46,
      8.64,
      182.35,
      15.86,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      26.64,
      20.67,
      51.2,
      43.5,
      30.63,
      null,
      9.91,
      162.28,
      14.42,
      null,
    ],
  ],
  // 23 from 2022, 愛知県, 現在のALPHAと同じ数値で問題なし
  '23': [
    [
      'MALE',
      'B1',
      1,
      6,
      null,
      null,
      null,
      null,
      null,
      null,
      11.6,
      112.7,
      7.6,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      null,
      null,
      null,
      null,
      null,
      null,
      10.7,
      126.3,
      10.9,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      null,
      null,
      null,
      null,
      null,
      null,
      10.3,
      133.9,
      13.4,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      null,
      null,
      31.7,
      35.7,
      null,
      null,
      9.9,
      145.5,
      16.5,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.1,
      17.9,
      34.8,
      40.3,
      44.2,
      null,
      9.5,
      151,
      19.9,
      51.72,
      null,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19,
      20.6,
      36.2,
      43.3,
      53,
      null,
      9,
      162.3,
      23,
      57.53,
      null,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      24,
      23.2,
      39.6,
      48.3,
      59.5,
      444.4,
      8.7,
      179.9,
      17.7,
      32.55,
      null,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      28.8,
      25.8,
      44.8,
      52.5,
      74.1,
      410.6,
      8.1,
      196.2,
      20.1,
      40.36,
      null,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34,
      28.5,
      49.2,
      56,
      82.6,
      387.9,
      7.6,
      210.6,
      22.9,
      48.01,
      null,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.3,
      27.8,
      49.1,
      56,
      81.7,
      398.2,
      7.6,
      214.6,
      23.5,
      48.73,
      null,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.8,
      29.9,
      51.9,
      57.7,
      88.3,
      386.8,
      7.4,
      221.5,
      25.3,
      53.16,
      null,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      40.5,
      31.4,
      54,
      58.9,
      88.9,
      381,
      7.3,
      226.6,
      26.6,
      56.1,
      null,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      null,
      null,
      null,
      null,
      null,
      null,
      11.9,
      105.9,
      5.1,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      null,
      null,
      null,
      null,
      null,
      null,
      11.1,
      117.3,
      7,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      null,
      null,
      null,
      null,
      null,
      null,
      10.6,
      129.2,
      8.7,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      null,
      null,
      35.4,
      33.9,
      null,
      null,
      10.2,
      138.1,
      10.5,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      16.2,
      17.1,
      39.2,
      38.8,
      34.6,
      null,
      9.7,
      146,
      12.8,
      53.79,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      19,
      19.1,
      41.2,
      41.3,
      42.9,
      null,
      9.2,
      157.4,
      14.8,
      59.52,
      null,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21,
      19.5,
      43,
      44.6,
      40.5,
      321.1,
      9.3,
      160.1,
      11,
      40.52,
      null,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.4,
      21.7,
      47.4,
      47.7,
      49.9,
      301.9,
      8.9,
      169.7,
      12.7,
      47.84,
      null,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.9,
      23.4,
      48.4,
      49,
      51.9,
      297.7,
      8.8,
      173.1,
      13.7,
      51.22,
      null,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.4,
      22.7,
      49.6,
      48.7,
      49.5,
      310.9,
      9,
      169.9,
      13.8,
      50.56,
      null,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      26.3,
      24.1,
      50.9,
      49.3,
      51.8,
      305.8,
      8.9,
      171.3,
      14.6,
      52.78,
      null,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.8,
      25.2,
      52.2,
      49.8,
      50.7,
      308,
      8.9,
      172.7,
      15.1,
      54.04,
      null,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '24': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.65,
      10.92,
      27.02,
      26.9,
      16.98,
      null,
      11.8,
      113.03,
      8.36,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.31,
      13.42,
      28.57,
      30.61,
      25.95,
      null,
      10.93,
      124.44,
      11.22,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.06,
      15.5,
      30,
      34.25,
      33.67,
      null,
      10.32,
      133.66,
      14.42,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      13.94,
      16.72,
      32.04,
      37.44,
      40.68,
      null,
      9.89,
      142.39,
      17.44,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.12,
      18.44,
      33.35,
      41.26,
      47.55,
      null,
      9.53,
      150.31,
      20.71,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19,
      19.99,
      35.91,
      44,
      53.61,
      null,
      9.19,
      160.89,
      23.75,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      23.42,
      21.98,
      39.81,
      48.35,
      61.83,
      458.4,
      8.69,
      181.32,
      17.66,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.29,
      25.14,
      43.35,
      52.24,
      77.81,
      415.15,
      8.02,
      198.09,
      20.93,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      33.45,
      27.68,
      49.13,
      55.29,
      86.19,
      393.44,
      7.58,
      212.5,
      23.87,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.81,
      27.98,
      48.14,
      56.12,
      79.77,
      425.95,
      7.55,
      215.08,
      23.47,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.96,
      29.45,
      50.42,
      57.42,
      88.16,
      409.43,
      7.41,
      218.89,
      25.01,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      40.02,
      30.51,
      51.94,
      58.58,
      87.69,
      415.56,
      7.28,
      223.97,
      25.93,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.13,
      10.49,
      28.86,
      26.38,
      14.1,
      null,
      12.12,
      106.44,
      5.69,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.66,
      12.71,
      31,
      29.29,
      19.89,
      null,
      11.24,
      117.49,
      7.49,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.41,
      14.44,
      33.28,
      32.59,
      24.58,
      null,
      10.75,
      125.25,
      9.34,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.55,
      15.9,
      35.7,
      36.18,
      30.75,
      null,
      10.18,
      135.81,
      11.6,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      15.98,
      17.54,
      37.91,
      39.1,
      37.14,
      null,
      9.83,
      144.08,
      13.57,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      18.7,
      18.51,
      40.96,
      41.65,
      41.34,
      null,
      9.52,
      151.27,
      15.3,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.01,
      18.82,
      43.35,
      44.31,
      42.95,
      336.66,
      9.27,
      162.23,
      11.36,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.51,
      20.81,
      46.12,
      46.23,
      50.57,
      316.87,
      9.01,
      167.27,
      12.74,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.55,
      22.27,
      49.49,
      47.8,
      52.09,
      308.8,
      8.85,
      171.72,
      13.81,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.67,
      21.89,
      48.72,
      48.53,
      47.71,
      331.01,
      9.04,
      168.02,
      13.08,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      26.16,
      23.4,
      49.54,
      49.19,
      51.6,
      327.59,
      9,
      168.21,
      13.9,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.61,
      23.91,
      51.39,
      48.96,
      49.62,
      331.19,
      9.01,
      169.57,
      14.2,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '25': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.54,
      11.19,
      25.25,
      26.45,
      15.85,
      null,
      11.8,
      111.51,
      7.63,
      28.6,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.25,
      13.55,
      26.58,
      29.85,
      23.68,
      null,
      10.86,
      124.13,
      10.92,
      35.44,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.02,
      15.43,
      29,
      32.61,
      31.21,
      null,
      10.37,
      134.84,
      14.21,
      41.33,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      13.79,
      16.73,
      30.69,
      35.59,
      37.32,
      null,
      9.88,
      143.47,
      17.18,
      46.23,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      15.89,
      18.75,
      32.71,
      40.16,
      46.47,
      null,
      9.5,
      153.07,
      20.37,
      52.06,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      18.68,
      20.47,
      34.33,
      43.46,
      55.38,
      null,
      9.05,
      163.04,
      23.43,
      57.44,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      23.05,
      22.89,
      39.68,
      48.36,
      65.61,
      442.73,
      8.57,
      183.23,
      17.07,
      33.35,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      28.89,
      26.17,
      44.55,
      52.17,
      78.19,
      409.67,
      7.99,
      197.74,
      20,
      42.04,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      33.34,
      28.05,
      48.26,
      54.98,
      85.31,
      390.72,
      7.58,
      213.74,
      22.53,
      48.65,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      35.5,
      27.99,
      47.62,
      55.41,
      78.56,
      401.52,
      7.52,
      216.98,
      22.13,
      47.99,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      37.74,
      29.54,
      50.33,
      56.64,
      82.88,
      390.56,
      7.38,
      224.26,
      23.18,
      51.08,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      39.28,
      30.89,
      51.97,
      57.58,
      81.56,
      385.6,
      7.25,
      227.18,
      24.23,
      53.75,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      38.56,
      22.2,
      40.65,
      45.76,
      44.44,
      999,
      11.19,
      202.81,
      20.78,
      34.5,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.07,
      10.88,
      27.55,
      25.1,
      13.01,
      null,
      12.18,
      103.39,
      5.27,
      28.2,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.65,
      13.18,
      29.37,
      28.56,
      18.42,
      null,
      11.27,
      115.25,
      6.95,
      35.51,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.4,
      14.77,
      31.78,
      31.01,
      23.16,
      null,
      10.69,
      125.76,
      8.83,
      41.39,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.26,
      15.46,
      34.3,
      33.66,
      27.01,
      null,
      10.22,
      134.75,
      10.77,
      46.38,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      15.57,
      17.43,
      36.77,
      37.63,
      35.11,
      null,
      9.78,
      144.32,
      12.79,
      52.64,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      18.56,
      18.64,
      38.99,
      40.46,
      40.97,
      null,
      9.41,
      151.94,
      14.52,
      57.89,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      20.97,
      19.46,
      42.89,
      44.24,
      44.64,
      315.47,
      9.2,
      163.86,
      10.55,
      41.78,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.02,
      21.39,
      46.1,
      45.91,
      50.05,
      305.92,
      9,
      168.71,
      11.82,
      46.96,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.4,
      22.3,
      48.1,
      46.93,
      49.82,
      300.84,
      8.95,
      170.41,
      12.62,
      49.46,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      24.61,
      22.11,
      48.06,
      47.15,
      43.21,
      323.27,
      9.01,
      170.41,
      12.52,
      47.68,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      25.24,
      23.04,
      49.36,
      47.39,
      42.09,
      322.7,
      9.06,
      171.92,
      12.73,
      48.75,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      25.74,
      23.7,
      49.91,
      47.4,
      41.35,
      326.2,
      9.08,
      170.76,
      13.03,
      49.19,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      24.08,
      17.36,
      34.54,
      35.17,
      11.13,
      376,
      13.96,
      132.54,
      10.31,
      53,
      null,
    ],
  ],
  '26': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.83,
      11.77,
      25.32,
      27.17,
      20.15,
      null,
      11.37,
      116.11,
      8.43,
      null,
      117.5,
      21.68,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.28,
      13.95,
      26.59,
      30.61,
      29.04,
      null,
      10.58,
      127.32,
      12.04,
      null,
      123.09,
      24.49,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.26,
      16.85,
      29.05,
      34.75,
      39.15,
      null,
      9.96,
      140.49,
      15.82,
      null,
      128.95,
      27.37,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      13.92,
      17.89,
      30.37,
      37.61,
      44.85,
      null,
      9.58,
      146.14,
      19.04,
      null,
      134.46,
      30.97,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      15.82,
      19.52,
      31.35,
      40.28,
      51.98,
      null,
      9.28,
      153.39,
      21.95,
      null,
      139.87,
      34.64,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      18.87,
      20.98,
      33.01,
      43.63,
      60.98,
      null,
      8.74,
      163.66,
      26.13,
      null,
      146.53,
      39.05,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      23.97,
      23,
      38.57,
      48.79,
      69.19,
      null,
      8.33,
      181.14,
      17.62,
      null,
      153.73,
      44.18,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.8,
      26.07,
      42.58,
      51.9,
      82.4,
      null,
      7.85,
      198.01,
      20.01,
      null,
      161.43,
      50.01,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.25,
      28.82,
      45.82,
      55.71,
      91.56,
      null,
      7.39,
      213.29,
      22.68,
      null,
      165.95,
      54.21,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.01,
      27.87,
      46.57,
      55.56,
      85.91,
      null,
      7.4,
      217.96,
      23.87,
      null,
      168.42,
      58.41,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.98,
      29.7,
      49.38,
      56.84,
      91.89,
      null,
      7.23,
      225.33,
      25.33,
      null,
      170.14,
      61.1,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      40.86,
      30.72,
      52.26,
      58.17,
      95.39,
      null,
      7.13,
      230.34,
      26.51,
      null,
      171,
      62.48,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      39.48,
      22,
      39.72,
      45.72,
      59.12,
      null,
      8.95,
      197.52,
      21.96,
      null,
      171.1,
      63.46,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.27,
      11.61,
      28.46,
      26.01,
      16.91,
      null,
      11.72,
      108.84,
      5.91,
      null,
      116.4,
      21.29,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.71,
      13.65,
      29.67,
      29.81,
      23.61,
      null,
      10.89,
      119.69,
      7.88,
      null,
      122.34,
      23.93,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.55,
      15.62,
      32.12,
      33.06,
      29.42,
      null,
      10.36,
      130.33,
      10.13,
      null,
      127.72,
      26.58,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.37,
      17.44,
      33.91,
      35.63,
      34.76,
      null,
      9.92,
      137.56,
      12.28,
      null,
      134.19,
      30.66,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      15.74,
      18.7,
      35.63,
      39.19,
      42.27,
      null,
      9.43,
      147.06,
      14.72,
      null,
      140.98,
      34.27,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      18.56,
      19.42,
      38.37,
      41.28,
      46.98,
      null,
      9.16,
      154.42,
      16.5,
      null,
      147.73,
      39.78,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      20.86,
      20.57,
      42.92,
      45.08,
      48.96,
      null,
      8.97,
      162.82,
      11.76,
      null,
      151.95,
      43.59,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.79,
      22.58,
      45.24,
      47.18,
      56.69,
      null,
      8.68,
      171.79,
      13.17,
      null,
      155.33,
      47.59,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.99,
      23.61,
      47.25,
      48.89,
      57.96,
      null,
      8.62,
      174.65,
      14.15,
      null,
      156.75,
      49.22,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      24.25,
      21.96,
      47.48,
      47.69,
      51.42,
      null,
      8.83,
      172.29,
      14.25,
      null,
      157.24,
      51.01,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      26.07,
      23.44,
      47.18,
      47.92,
      54.42,
      null,
      8.78,
      174.15,
      14.86,
      null,
      157.81,
      52.23,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.58,
      24.19,
      50.12,
      48.42,
      55.64,
      null,
      8.79,
      174.17,
      15.03,
      null,
      157.96,
      52.88,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      24.09,
      14.64,
      39.73,
      41.09,
      26.82,
      null,
      10.09,
      141.73,
      13,
      null,
      156.97,
      57.22,
    ],
  ],
  '27': [],
  '28': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.71,
      10.72,
      25.33,
      26.68,
      16.51,
      null,
      11.72,
      113.87,
      7.71,
      29.01,
      116.41,
      21.07,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.25,
      13.56,
      26.98,
      30.12,
      25.67,
      null,
      10.78,
      125.94,
      10.9,
      36.25,
      122.74,
      24.02,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      11.89,
      15.14,
      28.49,
      33.3,
      33.07,
      null,
      10.2,
      136.31,
      14.46,
      41.95,
      128.51,
      26.83,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      13.64,
      17.6,
      30.37,
      37.22,
      41.13,
      null,
      9.74,
      144.85,
      17.98,
      47.74,
      133.7,
      30.14,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      15.68,
      18.93,
      31.86,
      40.72,
      49.07,
      null,
      9.35,
      153.27,
      21.21,
      52.73,
      139.35,
      34.1,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      18.56,
      20.86,
      33.83,
      44.38,
      56.71,
      null,
      8.96,
      164,
      24.65,
      58.76,
      145.44,
      38.59,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      23.1,
      22.34,
      38.17,
      48.86,
      65.22,
      429.94,
      8.46,
      179.23,
      17.01,
      32.86,
      161.38,
      43.83,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      28.67,
      25.54,
      41.58,
      52.41,
      81.42,
      391.94,
      7.85,
      197.75,
      20.02,
      41.37,
      161.08,
      49.03,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      33.36,
      27.74,
      45.34,
      55.39,
      88.63,
      379.17,
      7.49,
      212.54,
      22.22,
      47.84,
      160.67,
      53.33,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.43,
      27.5,
      47.36,
      56.53,
      82.2,
      386.51,
      7.42,
      221.27,
      23.47,
      49.97,
      157.34,
      58.26,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      37.91,
      29.28,
      48.63,
      58.01,
      88.43,
      372.43,
      7.29,
      226.4,
      24.41,
      53.24,
      156.79,
      59.44,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      40.03,
      31.29,
      51.85,
      59.74,
      91.13,
      363.3,
      7.19,
      231.45,
      25.6,
      56.94,
      157.27,
      61.05,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.29,
      10.73,
      27.56,
      25.87,
      13.82,
      null,
      12.06,
      105.71,
      5.24,
      29.04,
      115.46,
      20.73,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.71,
      12.96,
      29.62,
      29.26,
      19.87,
      null,
      11.15,
      118.16,
      6.93,
      36.23,
      121.5,
      23.48,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.16,
      14.38,
      31.39,
      31.97,
      24.81,
      null,
      10.58,
      127.24,
      8.93,
      41.79,
      127.4,
      26.5,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.13,
      16.68,
      33.41,
      35.37,
      30.62,
      null,
      10.1,
      136.05,
      11.1,
      47.91,
      133.87,
      30.07,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      15.44,
      17.95,
      36.55,
      39.05,
      38.96,
      null,
      9.61,
      146.82,
      13.38,
      54.46,
      140.55,
      34.44,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      18.1,
      18.98,
      38.69,
      41.52,
      43.44,
      null,
      9.35,
      152.02,
      14.62,
      58.75,
      146.73,
      39.22,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.02,
      18.85,
      42.13,
      44.99,
      47.02,
      311.92,
      9.13,
      161.64,
      11,
      41.87,
      161.03,
      43.21,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.17,
      20.85,
      44.59,
      46.36,
      50.78,
      301.03,
      8.89,
      165.49,
      12.04,
      46.72,
      160.66,
      46.32,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.74,
      22.49,
      47.08,
      47.53,
      53.39,
      295.07,
      8.78,
      170.44,
      12.99,
      50.15,
      161.74,
      48.42,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      24.95,
      21.73,
      46.94,
      48.39,
      45.58,
      311.76,
      8.9,
      173.63,
      13.07,
      49,
      158.12,
      50.51,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      25.69,
      22.86,
      48.61,
      49.07,
      49.24,
      307.39,
      8.87,
      176.48,
      13.78,
      51.47,
      156.94,
      51.36,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      25.79,
      23.41,
      50.03,
      49.23,
      49.42,
      298.37,
      8.94,
      174.65,
      14.05,
      52.4,
      156.98,
      51.6,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '29': [],
  '30': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.87,
      11.48,
      27.43,
      27.59,
      17.29,
      null,
      11.62,
      113.94,
      8.04,
      30.27,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.57,
      13.86,
      28.72,
      31.4,
      26.01,
      null,
      10.74,
      124.94,
      11.24,
      37.17,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.44,
      16,
      30.76,
      34.91,
      33.53,
      null,
      10.17,
      134.56,
      14.67,
      43.16,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.09,
      17.85,
      32.23,
      38.45,
      39.87,
      null,
      9.76,
      142.07,
      17.92,
      48.15,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.33,
      19.7,
      34.37,
      42.38,
      48.45,
      null,
      9.37,
      151.64,
      21.09,
      53.74,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19.15,
      21.45,
      37.11,
      45.37,
      54.54,
      null,
      8.94,
      162.51,
      24.62,
      59.34,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      23.49,
      23.26,
      41.49,
      49.18,
      60.85,
      438.26,
      8.53,
      179.62,
      17.82,
      33.29,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.07,
      26.19,
      45.68,
      52.56,
      75.44,
      403.87,
      7.88,
      197.26,
      20.75,
      41.88,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      33.69,
      28.14,
      48.73,
      55.46,
      84.62,
      388.8,
      7.53,
      211.76,
      23.53,
      48.37,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.3,
      28.16,
      47.16,
      55.61,
      81.59,
      414.68,
      7.46,
      213.22,
      23.07,
      47.87,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.96,
      29.99,
      51.34,
      57.47,
      89.64,
      392.96,
      7.27,
      221.32,
      24.92,
      52.67,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      40.76,
      30.91,
      52.56,
      58.35,
      88.13,
      391.5,
      7.2,
      223.98,
      25.45,
      54.44,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      36.33,
      19.05,
      39.55,
      41.18,
      31.65,
      null,
      8.38,
      186.7,
      19.11,
      30,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.32,
      11.11,
      29.41,
      26.72,
      14.47,
      null,
      11.95,
      105.99,
      5.62,
      30.27,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      10.1,
      13.54,
      31.61,
      30.44,
      20.82,
      null,
      11.08,
      117.41,
      7.41,
      37.74,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.8,
      15.83,
      34.16,
      33.83,
      25.97,
      null,
      10.55,
      127.01,
      9.33,
      44.06,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.59,
      17.24,
      35.72,
      37.05,
      31.01,
      null,
      10.09,
      135.42,
      11.27,
      49.39,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      16.13,
      18.71,
      38.47,
      40.57,
      38.03,
      null,
      9.67,
      144.06,
      13.51,
      55.29,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      19.01,
      19.86,
      41.48,
      42.51,
      41.75,
      null,
      9.34,
      151.62,
      15.2,
      59.86,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.28,
      20.2,
      44.47,
      45.22,
      43.28,
      322.82,
      9.16,
      160.86,
      11.36,
      42.48,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.1,
      21.76,
      47.7,
      46.65,
      48.33,
      308.2,
      8.93,
      166.2,
      12.68,
      47.35,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.42,
      22.93,
      49.1,
      47.5,
      50.35,
      309.67,
      8.85,
      168.85,
      13.63,
      49.95,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.06,
      22.3,
      47.7,
      47.19,
      46.67,
      326.79,
      9,
      165.65,
      13.4,
      47.93,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      25.93,
      22.78,
      49.66,
      47.49,
      47.81,
      323.76,
      8.96,
      166.69,
      13.72,
      49.13,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.49,
      23.8,
      51.11,
      48.37,
      47.24,
      323.04,
      8.98,
      168.37,
      14.23,
      50.71,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      23.58,
      19.05,
      39.89,
      41.18,
      18.81,
      null,
      null,
      130.44,
      9.81,
      31.17,
      null,
    ],
  ],
  '31': [
    [
      'MALE',
      'B1',
      1,
      6,
      9.12,
      10.74,
      26.83,
      27.44,
      20.28,
      null,
      11.7,
      115.89,
      8.25,
      30.41,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.76,
      13.53,
      27.8,
      31.42,
      28.96,
      null,
      10.81,
      124.58,
      11.3,
      37.09,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.54,
      15.7,
      20.01,
      34.72,
      38.49,
      null,
      10.22,
      135.11,
      14.77,
      43.31,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.27,
      17.26,
      31.48,
      38.46,
      47.46,
      null,
      9.77,
      142.28,
      18.04,
      48.38,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.28,
      19.13,
      22.15,
      41.87,
      53.69,
      null,
      9.45,
      152.16,
      21.49,
      53.44,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19.33,
      20.59,
      34.9,
      44.79,
      62.31,
      null,
      9.04,
      161.7,
      24.87,
      58.6,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      23.79,
      22.48,
      39,
      48.63,
      69.44,
      null,
      8.56,
      182.21,
      17.22,
      33.43,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.8,
      25.97,
      43.73,
      52.68,
      83.58,
      null,
      7.85,
      202.44,
      20.58,
      42.88,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.08,
      28.29,
      46.65,
      55.81,
      90.97,
      null,
      7.48,
      216.17,
      23.07,
      49.41,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.14,
      27.82,
      49.98,
      56.08,
      84.88,
      null,
      7.4,
      223.71,
      23.81,
      51.02,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.28,
      28.86,
      51.17,
      57.19,
      89.6,
      null,
      7.21,
      227.52,
      25.58,
      54.23,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      39.73,
      30.08,
      53.89,
      58.6,
      91.35,
      null,
      7.16,
      233.15,
      26.53,
      57.08,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.49,
      10.64,
      28.86,
      26.76,
      17.33,
      null,
      11.98,
      107.77,
      5.53,
      30.63,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      10.2,
      12.97,
      30.65,
      30.17,
      22.83,
      null,
      11.16,
      115.89,
      7.26,
      37.26,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.84,
      14.93,
      32.97,
      33.55,
      30.13,
      null,
      10.55,
      127.22,
      9.34,
      43.86,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.67,
      16.26,
      34.84,
      36.79,
      36.71,
      null,
      10.07,
      134.55,
      11.42,
      49.3,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      16.05,
      18.19,
      37.4,
      40.27,
      43.76,
      null,
      9.73,
      144.6,
      13.65,
      55.09,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      19.06,
      19.11,
      38.96,
      42.32,
      48.79,
      null,
      9.42,
      150.34,
      15.52,
      59.18,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.16,
      19.12,
      42.05,
      45.16,
      50.78,
      null,
      9.19,
      163.18,
      10.92,
      42.62,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.37,
      21.95,
      45.8,
      47.87,
      55.6,
      null,
      8.85,
      169.07,
      12.45,
      48.75,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.63,
      22.65,
      47.65,
      48.24,
      54.35,
      null,
      8.81,
      171.76,
      12.95,
      50.52,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      24.83,
      22.35,
      49.66,
      48.61,
      51.39,
      null,
      8.83,
      174.86,
      13.22,
      51.27,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      25.53,
      22.12,
      50.26,
      48.24,
      50.97,
      null,
      8.81,
      175.4,
      13.76,
      51.79,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.07,
      23.57,
      51.99,
      49.28,
      51.93,
      null,
      8.76,
      177.12,
      14.59,
      54.11,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '32': [],
  '33': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.54,
      10.73,
      25.19,
      27.18,
      16.71,
      null,
      11.83,
      113.32,
      7.62,
      28.6,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.01,
      13.16,
      26.83,
      30.6,
      24.95,
      null,
      10.96,
      125.74,
      10.75,
      35.12,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      11.79,
      15.48,
      28.62,
      34.33,
      33.06,
      null,
      10.37,
      136.16,
      14.13,
      40.93,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      13.6,
      17.36,
      30.65,
      37.78,
      39.88,
      null,
      9.8,
      145.31,
      17.42,
      46.97,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      15.6,
      19.12,
      32.89,
      41.91,
      46.81,
      null,
      9.56,
      153.24,
      20.31,
      51.87,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      18.64,
      21.2,
      35.57,
      45.21,
      54.63,
      null,
      9,
      164.78,
      23.73,
      57.56,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      23.72,
      22.49,
      40.11,
      48.77,
      62.37,
      null,
      8.53,
      181.91,
      17.78,
      33.62,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.13,
      24.79,
      44.5,
      51.32,
      75.58,
      null,
      7.95,
      198.09,
      20.78,
      41.54,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      33.93,
      27.52,
      47.95,
      55.11,
      84.08,
      null,
      7.57,
      212.53,
      23.6,
      48.82,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.66,
      28.11,
      50.1,
      56.06,
      82,
      null,
      7.45,
      218.61,
      24.62,
      50.43,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.66,
      29.74,
      52,
      57.32,
      86.81,
      null,
      7.27,
      224.89,
      26.08,
      53.84,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      40.33,
      31.16,
      53.27,
      58.73,
      88.58,
      null,
      7.2,
      229.1,
      27.24,
      56.59,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      42.66,
      24.53,
      43.44,
      49.52,
      53.38,
      null,
      7.79,
      200.58,
      21.19,
      42.77,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.11,
      10.29,
      27.25,
      26.09,
      14.28,
      null,
      12.18,
      105.61,
      5.33,
      28.68,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.56,
      12.73,
      29.55,
      29.58,
      19.8,
      null,
      11.25,
      117.59,
      7.13,
      35.91,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.21,
      14.63,
      31.4,
      32.87,
      25.09,
      null,
      10.63,
      127.77,
      9.23,
      42.24,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.06,
      16.51,
      33.92,
      36.32,
      30.38,
      null,
      10.13,
      137.89,
      11.34,
      48.34,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      15.57,
      18.11,
      36.87,
      20.01,
      36.77,
      null,
      9.69,
      147.16,
      13.47,
      54.42,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      18.49,
      19.35,
      39.72,
      42.48,
      41.54,
      null,
      9.33,
      154.39,
      15.23,
      59.37,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.27,
      19.3,
      43.85,
      44.52,
      42.62,
      null,
      9.15,
      162.73,
      11.25,
      42.53,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.54,
      20.99,
      46.88,
      45.74,
      48.82,
      null,
      8.9,
      167.15,
      12.73,
      47.6,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.82,
      21.93,
      49.21,
      47.23,
      49.48,
      null,
      8.81,
      170.58,
      13.55,
      50.51,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.15,
      22.41,
      51.3,
      47.58,
      46.21,
      null,
      8.93,
      169.93,
      14.07,
      50.16,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      25.68,
      23.22,
      52.27,
      48.09,
      47.76,
      null,
      8.86,
      172.45,
      14.74,
      51.95,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.51,
      24.23,
      53.49,
      48.79,
      47.8,
      null,
      8.84,
      170.77,
      15.25,
      53.15,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      26.81,
      15.41,
      41.79,
      39.52,
      22.11,
      null,
      10.17,
      134.1,
      10.52,
      32.84,
      null,
    ],
  ],
  '34': [],
  '35': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.9,
      11.3,
      26,
      26.6,
      18.8,
      null,
      11.6,
      115.2,
      7.6,
      29.8,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10,
      13.2,
      26.3,
      30.8,
      26.9,
      null,
      11,
      123.7,
      10.6,
      35.6,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      11.9,
      15.1,
      28.2,
      33.2,
      34.7,
      null,
      10.2,
      133.8,
      13.8,
      41.2,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      13.8,
      16.6,
      29,
      36.7,
      38.4,
      null,
      10,
      141.8,
      17.1,
      45.3,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      15.6,
      18.5,
      32.3,
      40,
      49.5,
      null,
      9.5,
      147.7,
      19.7,
      51.6,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      18.5,
      19.7,
      33.9,
      43.5,
      57.8,
      null,
      9.1,
      158.8,
      23.8,
      56.8,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      23.8,
      22.5,
      39.1,
      49.2,
      66.6,
      null,
      8.6,
      179.6,
      16.6,
      32.9,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      28.8,
      25.8,
      45.4,
      52.5,
      79.5,
      null,
      8,
      197.7,
      19.8,
      41.8,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      33.2,
      27.5,
      46.6,
      54.4,
      90.8,
      null,
      7.7,
      209.6,
      21.4,
      47.2,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      35.6,
      27.8,
      45.3,
      56.1,
      86,
      401.1,
      7.6,
      211.9,
      22.9,
      47.9,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.4,
      30.7,
      48.7,
      58.7,
      97.4,
      381.5,
      7.3,
      221.5,
      24.9,
      53.9,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      39.5,
      31.5,
      50.7,
      59,
      95.3,
      378.8,
      7.2,
      224.4,
      25.4,
      55.4,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.5,
      10.6,
      27.5,
      26.4,
      15.6,
      null,
      11.9,
      104.1,
      4.9,
      29.2,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.6,
      13.2,
      28.9,
      29.8,
      21.8,
      null,
      11.2,
      115.1,
      7.1,
      36,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.5,
      14.8,
      31.2,
      31.6,
      28.5,
      null,
      10.6,
      126.5,
      9.1,
      42.6,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.5,
      16.2,
      32,
      35.9,
      31.4,
      null,
      10.2,
      134.3,
      11.2,
      47.3,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      15.6,
      17.8,
      37.4,
      38.7,
      40.7,
      null,
      9.6,
      141.7,
      13,
      54.2,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      18.3,
      17.8,
      38.5,
      41,
      45.7,
      null,
      9.4,
      148.6,
      14.7,
      57.6,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.5,
      19.5,
      42.6,
      44.9,
      49.5,
      null,
      9.1,
      163.1,
      10.7,
      42.7,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.2,
      21.5,
      47.5,
      46.8,
      53.4,
      null,
      8.9,
      169.6,
      12.4,
      48.5,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.3,
      22.5,
      47.7,
      48.4,
      57.4,
      null,
      8.8,
      172.9,
      12.7,
      50.8,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      24.9,
      22.4,
      46.8,
      47.6,
      50.8,
      311.4,
      9,
      165.1,
      13,
      48.7,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      26.1,
      24.4,
      49.4,
      49.5,
      57.9,
      309.7,
      8.8,
      171.6,
      14.2,
      53,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.5,
      25,
      50.6,
      49.7,
      56.7,
      301.9,
      8.8,
      172.2,
      14.7,
      53.9,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '36': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.78,
      10.78,
      27.06,
      26.68,
      16.81,
      null,
      12.02,
      115.37,
      7.92,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.39,
      13.25,
      28.2,
      31.02,
      24.7,
      null,
      11.04,
      126.37,
      10.96,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      11.97,
      15.55,
      29.42,
      33.81,
      31.34,
      null,
      10.4,
      133.6,
      14.43,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      13.86,
      16.63,
      31.25,
      36.82,
      38.08,
      null,
      9.96,
      139.87,
      17.56,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      15.88,
      18.5,
      32.78,
      41.53,
      45.75,
      null,
      9.5,
      150.54,
      21.23,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      18.68,
      20.51,
      35.02,
      44.34,
      54.44,
      null,
      9.03,
      160.8,
      24.46,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      24.17,
      22.21,
      40.83,
      48.8,
      61.06,
      463.58,
      8.64,
      180.92,
      17.51,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.76,
      25.22,
      44.43,
      52.36,
      78.92,
      413.26,
      7.9,
      198.07,
      20.61,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.46,
      26.96,
      48.3,
      54.45,
      84.17,
      401.36,
      7.58,
      210.11,
      22.9,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.98,
      26.72,
      47.17,
      55.97,
      79.6,
      null,
      7.55,
      214.77,
      23.81,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.86,
      28.31,
      47.55,
      57.03,
      87.6,
      null,
      7.41,
      219.56,
      24.69,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      40.18,
      29.34,
      50.39,
      58.05,
      86.2,
      null,
      7.39,
      222.75,
      25.65,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.32,
      10.1,
      28.83,
      25.81,
      14.19,
      null,
      12.32,
      106.22,
      5.53,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.72,
      12.45,
      30.42,
      29.48,
      19.06,
      null,
      11.49,
      116.23,
      7.21,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.51,
      14.75,
      32.17,
      32,
      23.67,
      null,
      10.71,
      125.77,
      9.31,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.47,
      15.82,
      35.21,
      35.1,
      29.64,
      null,
      10.22,
      135.33,
      11.54,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      15.79,
      17.72,
      37.71,
      39.36,
      36.62,
      null,
      9.73,
      144.78,
      13.81,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      18.69,
      18.79,
      40.58,
      41.4,
      41.18,
      null,
      9.37,
      151.8,
      15.56,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.74,
      18.95,
      43.62,
      44.05,
      41.71,
      326.98,
      9.14,
      162.48,
      11.12,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.62,
      20.75,
      47.21,
      46.32,
      50.14,
      320.5,
      8.88,
      168.46,
      12.5,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.87,
      21.78,
      48.71,
      47.12,
      50.44,
      313.75,
      8.81,
      170.17,
      13.51,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.16,
      20.64,
      47.14,
      47.72,
      45.13,
      null,
      9.15,
      166,
      13.43,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      25.79,
      22.42,
      48.29,
      47.64,
      48.67,
      null,
      9.07,
      167.19,
      13.66,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.45,
      22.41,
      49.81,
      47.78,
      48.72,
      null,
      9.11,
      164.57,
      14.21,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '37': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.59,
      10.92,
      25.54,
      25.77,
      16.7,
      null,
      11.94,
      112.83,
      8.05,
      28.72,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.14,
      13.52,
      27.37,
      29.19,
      23.91,
      null,
      10.93,
      123.41,
      11.66,
      35.3,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      11.76,
      15.76,
      28.99,
      33.12,
      32.82,
      null,
      10.33,
      133.78,
      14.16,
      41.47,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      13.51,
      17.33,
      31.28,
      37.53,
      39.3,
      null,
      9.78,
      141.6,
      17.43,
      47.07,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      15.41,
      18.9,
      33.15,
      41.19,
      46.87,
      null,
      9.44,
      151.09,
      20.73,
      51.52,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      18.29,
      20.53,
      34.97,
      44.07,
      54.25,
      null,
      9.18,
      160.85,
      24.22,
      57.66,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      23.3,
      22.39,
      39.81,
      48.43,
      61.67,
      null,
      8.64,
      181.99,
      17.52,
      32.88,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      28.92,
      25.43,
      44.12,
      52.55,
      79.35,
      null,
      7.94,
      200.34,
      20.61,
      42.08,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      33.56,
      27.09,
      47.96,
      55.03,
      85.95,
      null,
      7.62,
      214.12,
      22.96,
      48.16,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      37.41,
      28.21,
      48.84,
      57.41,
      84.8,
      null,
      7.41,
      219.11,
      24.77,
      51.56,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      39.65,
      29.58,
      51.38,
      58.4,
      89.72,
      null,
      7.26,
      224.77,
      26.06,
      55.05,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      41.15,
      30.42,
      52.53,
      58.9,
      89.66,
      null,
      7.18,
      226.3,
      26.86,
      56.78,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      7.97,
      10.21,
      27.9,
      24.83,
      13.95,
      null,
      12.42,
      103.58,
      5.37,
      28.09,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.66,
      12.99,
      30.5,
      28.6,
      19.62,
      null,
      11.25,
      116.06,
      7.33,
      36.01,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.12,
      14.94,
      32.62,
      31.71,
      24.74,
      null,
      10.67,
      125.48,
      9.28,
      41.93,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.09,
      16.55,
      34.91,
      35.91,
      31.3,
      null,
      10.07,
      133.37,
      11.56,
      48.3,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      15.33,
      17.74,
      37.7,
      39.18,
      36.99,
      null,
      10.03,
      144.02,
      14.1,
      53.97,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      18.12,
      19,
      40.48,
      41.87,
      42.31,
      null,
      9.25,
      151.15,
      15.78,
      58.97,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      20.99,
      18.85,
      43.31,
      44.09,
      43.14,
      null,
      9.23,
      162.68,
      11.23,
      41.82,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.08,
      21.24,
      46.42,
      46.57,
      51.57,
      null,
      8.96,
      169.71,
      12.49,
      47.74,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.54,
      22.28,
      48.75,
      47.72,
      52.17,
      null,
      8.86,
      172.66,
      13.3,
      50.59,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.63,
      22.23,
      50.11,
      48.4,
      48.51,
      null,
      8.96,
      172.38,
      13.87,
      51.22,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      26.53,
      23.33,
      52.44,
      49.11,
      49.58,
      null,
      8.9,
      172.82,
      14.3,
      53.02,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.88,
      24.04,
      53.4,
      49.2,
      50.63,
      null,
      8.88,
      174.1,
      14.75,
      54.14,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '38': [],
  '39': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.53,
      11.38,
      27.06,
      26.19,
      17.78,
      null,
      11.65,
      114.83,
      8.86,
      29.98,
      116.1,
      21.35,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.17,
      14.53,
      29.35,
      30.25,
      26.45,
      null,
      10.82,
      124.7,
      11.51,
      37.11,
      121.78,
      24.3,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      11.77,
      16.14,
      31.86,
      33.5,
      33.59,
      null,
      10.36,
      132.47,
      14.18,
      42.43,
      127.73,
      27.7,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      13.59,
      17.51,
      32.35,
      36.01,
      38.49,
      null,
      9.81,
      142.04,
      17.29,
      46.79,
      133.25,
      31.39,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      15.99,
      19.28,
      34.63,
      40.83,
      44.97,
      null,
      9.56,
      152.38,
      20.68,
      52.1,
      138.99,
      35.02,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      18.55,
      20.79,
      36.01,
      42.83,
      51.47,
      null,
      9.17,
      159.32,
      23.46,
      56.77,
      145.65,
      39.53,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      24.6,
      23.41,
      41.44,
      48.16,
      62.87,
      457.6,
      8.56,
      183.64,
      18.11,
      33.74,
      153.46,
      46.11,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.75,
      26.08,
      44.11,
      50.97,
      74.58,
      413.66,
      7.99,
      199.47,
      20.82,
      40.9,
      160.05,
      50.17,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.91,
      27.89,
      47.21,
      53.85,
      82.64,
      413.11,
      7.58,
      214.05,
      23.21,
      47.34,
      165.23,
      55.18,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.54,
      27.15,
      44.46,
      54.63,
      75.03,
      419.07,
      7.64,
      212.87,
      22.86,
      45.18,
      167.18,
      59.55,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.52,
      28.61,
      46.54,
      56.38,
      80.28,
      400.85,
      7.46,
      218.91,
      23.65,
      49.45,
      168.56,
      60.31,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      40.34,
      29.34,
      47.66,
      57.45,
      77.44,
      394.09,
      7.44,
      221.69,
      24.95,
      51.24,
      169.41,
      62.85,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.01,
      11.03,
      28.9,
      25.51,
      15.33,
      null,
      11.99,
      106.64,
      5.69,
      29.96,
      115.38,
      21.03,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.46,
      13.82,
      31.5,
      29.6,
      21.61,
      null,
      11.12,
      116.15,
      7.52,
      37.56,
      121.41,
      24.05,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.09,
      15.01,
      33.87,
      32.48,
      28.12,
      null,
      10.62,
      126.55,
      9.6,
      43.05,
      127.07,
      26.76,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.28,
      16.51,
      35.02,
      34.75,
      30.86,
      null,
      10.1,
      134.97,
      11.52,
      48,
      134.03,
      30.9,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      15.77,
      18.56,
      37.94,
      39.22,
      37.22,
      null,
      9.8,
      144.42,
      13.9,
      53.9,
      140.76,
      35.59,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      18.07,
      19.33,
      39.79,
      40.59,
      50.99,
      null,
      9.5,
      149.77,
      15.47,
      57.61,
      146.87,
      40.08,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.51,
      20.42,
      44.18,
      44.94,
      46.28,
      331.42,
      9.12,
      164.43,
      11.54,
      43.29,
      151.66,
      44.47,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.77,
      22.08,
      46.02,
      45.58,
      50.11,
      327.88,
      9,
      168.11,
      12.64,
      46.71,
      154,
      47.41,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      25.13,
      23.35,
      47.9,
      47.03,
      53.4,
      317.86,
      8.86,
      173.9,
      14.38,
      50.39,
      155.53,
      49.89,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      24.43,
      21.19,
      44.75,
      46.6,
      44.01,
      336.77,
      9.23,
      164.49,
      12.88,
      45.28,
      155.8,
      51.49,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      25.7,
      21.78,
      46.77,
      47.47,
      45.75,
      329.8,
      9.25,
      166.4,
      13.39,
      47.72,
      156.3,
      52.34,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.1,
      22.71,
      46.48,
      47.8,
      44.55,
      333.29,
      9.25,
      165.69,
      13.71,
      48.12,
      156.67,
      52.35,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  // 40 from 2022, 福岡県, 現在のALPHAと同じ数値で問題なし
  '40': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.68,
      10.94,
      27.59,
      26.25,
      18.17,
      null,
      11.81,
      114.74,
      8.15,
      29.95,
      116.59,
      21.61,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.38,
      13.68,
      29.13,
      30.24,
      26.81,
      null,
      10.89,
      124.78,
      11.43,
      36.86,
      122.52,
      24,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.1,
      15.57,
      30.59,
      33.26,
      33.98,
      null,
      10.31,
      134.13,
      14.72,
      42.36,
      128.27,
      27.83,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      13.94,
      17.22,
      32.4,
      36.99,
      40.71,
      null,
      9.89,
      14.5,
      17.98,
      47.53,
      133.72,
      31.59,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.1,
      19.03,
      34.24,
      40.81,
      48.45,
      null,
      9.47,
      151.37,
      21.5,
      53.11,
      139.45,
      35.29,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19.02,
      20.74,
      36.16,
      43.8,
      55.94,
      null,
      9.08,
      162.24,
      25.06,
      58.49,
      145.8,
      40.02,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      24.01,
      22.72,
      39.84,
      48.88,
      66.79,
      null,
      8.58,
      183.03,
      17.96,
      34.22,
      153.81,
      45.4,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.27,
      25.88,
      43.62,
      51.99,
      81.23,
      null,
      8,
      199.86,
      20.87,
      42.31,
      161.07,
      49.88,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.12,
      28.48,
      48.37,
      55.53,
      90.07,
      null,
      7.56,
      215.65,
      23.43,
      50.16,
      165.5,
      54.69,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.58,
      28.14,
      47.46,
      56.01,
      82.43,
      null,
      7.58,
      216.43,
      23.36,
      49.63,
      168.07,
      58.4,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.78,
      30.26,
      50.25,
      57.56,
      89.15,
      null,
      7.36,
      223.44,
      24.79,
      54.22,
      169.43,
      59.51,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      26.1,
      23.48,
      49.15,
      47.86,
      49.94,
      null,
      9,
      169.82,
      13.55,
      51.43,
      170.32,
      61.59,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.16,
      10.59,
      29.63,
      25.18,
      15.22,
      null,
      12.14,
      106.39,
      5.51,
      29.64,
      115.68,
      21.11,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.78,
      13,
      31.69,
      29.06,
      20.88,
      null,
      11.22,
      115.5,
      7.9,
      36.71,
      121.65,
      24,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.45,
      14.76,
      33.53,
      31.63,
      25.47,
      null,
      10.67,
      124.79,
      9.2,
      42.34,
      127.58,
      27.23,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.42,
      16.31,
      35.9,
      35.22,
      30.96,
      null,
      10.2,
      134.11,
      11.18,
      48.15,
      134.12,
      31.05,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      16.05,
      17.9,
      38.51,
      38.6,
      37.26,
      null,
      9.74,
      143.55,
      13.35,
      54.21,
      141.35,
      35.43,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      18.79,
      18.96,
      40.76,
      40.92,
      41.58,
      null,
      9.41,
      151.11,
      15.07,
      58.71,
      147.57,
      40.66,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.31,
      19.45,
      43.1,
      44.71,
      45.93,
      null,
      9.22,
      163.9,
      11.05,
      42.85,
      152.04,
      44.34,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.2,
      21.39,
      45.56,
      46.22,
      52.57,
      null,
      8.98,
      168.82,
      12.42,
      47.68,
      154.82,
      46.52,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.59,
      22.79,
      48.16,
      47.57,
      53.99,
      null,
      8.84,
      173.58,
      13.35,
      51.25,
      155.97,
      49.31,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.26,
      21.94,
      47.22,
      47.49,
      47.22,
      null,
      9.11,
      168.3,
      13,
      48.95,
      156.6,
      50.32,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      26.1,
      23.48,
      49.15,
      47.86,
      49.94,
      null,
      9,
      169.82,
      13.55,
      51.43,
      157.12,
      51.05,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.6,
      24.26,
      50.38,
      48.33,
      49.83,
      null,
      9.02,
      170.93,
      13.86,
      52.39,
      157.54,
      51.43,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '41': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.6,
      10.6,
      27.83,
      26.11,
      16.86,
      null,
      11.73,
      113.01,
      8.47,
      29.51,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.32,
      13.35,
      29.5,
      29.83,
      26.27,
      null,
      10.79,
      124.03,
      11.64,
      36.54,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.13,
      15.18,
      30.42,
      33.52,
      35.02,
      null,
      10.19,
      133.92,
      15.33,
      42.37,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      13.86,
      17.12,
      32.23,
      37.22,
      41.93,
      null,
      9.73,
      141.4,
      18.43,
      47.57,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.03,
      18.75,
      34.65,
      40.61,
      48.76,
      null,
      9.33,
      149.83,
      21.91,
      52.86,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19.07,
      20.59,
      36.03,
      44.11,
      57.38,
      null,
      8.9,
      161.36,
      25.21,
      58.57,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      24,
      22.61,
      40.2,
      48.23,
      66.08,
      491.87,
      8.62,
      180.81,
      18.01,
      33.37,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.91,
      25.8,
      43.88,
      52.36,
      83.22,
      411.91,
      7.9,
      200.21,
      21.45,
      42.6,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.55,
      27.85,
      47.74,
      54.79,
      91.17,
      388.64,
      7.48,
      214.34,
      23.59,
      49.42,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.32,
      27.6,
      47.61,
      54.99,
      83.08,
      428.6,
      7.42,
      217.66,
      23.48,
      49.92,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.86,
      29.1,
      49.87,
      57.26,
      91.16,
      379.23,
      7.16,
      224.78,
      24.94,
      53.62,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      40.26,
      30.39,
      52,
      57.84,
      89.9,
      388.97,
      7.19,
      224.8,
      25.52,
      56.49,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8,
      10.35,
      29.25,
      25.28,
      14.44,
      null,
      12.04,
      105.5,
      5.62,
      29.29,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.58,
      12.64,
      31.89,
      28.68,
      20.08,
      null,
      11.17,
      114.56,
      7.38,
      36.25,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.38,
      14.74,
      33.59,
      32.21,
      25.72,
      null,
      10.53,
      125.63,
      9.34,
      42.69,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.41,
      16.58,
      35.61,
      35.59,
      32.63,
      null,
      10,
      135.16,
      11.75,
      48.84,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      15.97,
      17.86,
      38.53,
      38.79,
      38.31,
      null,
      9.59,
      143.48,
      13.87,
      54.41,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      19.07,
      19.2,
      40.58,
      41.32,
      42.55,
      null,
      9.24,
      152.03,
      15.59,
      59.32,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.51,
      19.01,
      42.85,
      43.83,
      45.14,
      369.37,
      9.26,
      160.44,
      11.2,
      41.45,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.66,
      21.26,
      45.16,
      46.26,
      52.24,
      311.2,
      8.93,
      168.33,
      12.6,
      47.38,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      25.27,
      22.77,
      47.54,
      47.2,
      55.03,
      302.27,
      8.8,
      171.9,
      13.79,
      51.13,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.3,
      21.89,
      47.56,
      47.3,
      46.49,
      322.3,
      8.94,
      169.05,
      13.07,
      48.83,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      26.22,
      22.59,
      48.74,
      47.83,
      49.83,
      313.4,
      8.85,
      170.15,
      13.61,
      50.74,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.72,
      23.53,
      51.1,
      48.45,
      51.17,
      318.28,
      8.8,
      171.21,
      14.37,
      52.65,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '42': [],
  '43': [
    [
      'MALE',
      'B1',
      1,
      6,
      9.12,
      11.35,
      28.89,
      26.63,
      18.99,
      null,
      11.76,
      113.66,
      8.46,
      null,
      116.49,
      21.7,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.77,
      13.98,
      29.59,
      30.62,
      28.4,
      null,
      10.84,
      122.93,
      11.67,
      null,
      122.46,
      24.65,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.36,
      15.73,
      29.87,
      32.98,
      34.04,
      null,
      10.36,
      132.63,
      14.91,
      null,
      128.25,
      28,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.23,
      17.27,
      31.7,
      37.27,
      41.54,
      null,
      9.94,
      140.92,
      18.31,
      null,
      133.86,
      31.74,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.39,
      19.03,
      33.55,
      40.83,
      49.04,
      null,
      9.52,
      149.3,
      21.5,
      null,
      139.16,
      35.65,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19.36,
      21.05,
      35.74,
      44.44,
      57.5,
      null,
      9.07,
      160.6,
      25.12,
      null,
      145.71,
      39.85,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      24.2,
      23.4,
      39.92,
      49.3,
      68.38,
      441.43,
      8.65,
      182.27,
      18.43,
      null,
      153.69,
      45.35,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.84,
      26.53,
      44.68,
      53.44,
      84.04,
      402.81,
      8.03,
      200.34,
      21.3,
      null,
      160.9,
      50.56,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.55,
      28.72,
      48.84,
      56.56,
      92.61,
      384.74,
      7.59,
      214.72,
      24.97,
      null,
      165.66,
      55.04,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.08,
      28.56,
      48.81,
      56.34,
      84.25,
      394.98,
      7.54,
      218.6,
      22.77,
      null,
      168.23,
      59.16,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      38.89,
      30.83,
      52.32,
      58.62,
      91.6,
      385.32,
      7.35,
      225.74,
      24.56,
      null,
      169.26,
      60.71,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      40.35,
      31.79,
      53.76,
      59.6,
      93.87,
      380.21,
      7.23,
      231.29,
      25.71,
      null,
      170.17,
      62.34,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.52,
      10.9,
      30.42,
      25.77,
      15.87,
      null,
      12.11,
      105.18,
      5.6,
      null,
      115.5,
      21.29,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      10.1,
      13.29,
      31.76,
      29.42,
      22.09,
      null,
      11.17,
      113.57,
      7.37,
      null,
      121.54,
      24.09,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.62,
      14.89,
      32.74,
      31.33,
      26.02,
      null,
      10.68,
      123.69,
      9.32,
      null,
      127.66,
      27.25,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.67,
      16.52,
      35,
      35.45,
      32.67,
      null,
      10.21,
      132.24,
      11.35,
      null,
      134.07,
      31.02,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      16.19,
      17.98,
      36.98,
      38.85,
      39.3,
      null,
      9.77,
      141.54,
      13.49,
      null,
      141.13,
      35.55,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      19.29,
      19.29,
      40.17,
      41.85,
      45.1,
      null,
      9.37,
      149.65,
      15.49,
      null,
      147.57,
      40.55,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.64,
      20.09,
      42.37,
      45.22,
      49.81,
      314.47,
      9.17,
      164.29,
      11.7,
      null,
      152.13,
      44.67,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.63,
      21.96,
      44.92,
      47.2,
      55.97,
      302.83,
      8.93,
      170.02,
      12.84,
      null,
      154.7,
      47.63,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.78,
      22.8,
      46.81,
      48.23,
      56.96,
      301.41,
      8.83,
      173.41,
      13.76,
      null,
      156.04,
      49.8,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.09,
      22.05,
      47.43,
      47.48,
      50.97,
      315.74,
      9.02,
      171.63,
      13.21,
      null,
      156.66,
      51.41,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      26.15,
      23.59,
      49.41,
      48.75,
      55.43,
      213.01,
      8.89,
      174.53,
      14,
      null,
      156.97,
      51.94,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.64,
      24.03,
      50.59,
      49.12,
      53.51,
      313.5,
      8.93,
      175.6,
      14.53,
      null,
      157.57,
      52.85,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '44': [
    [
      'MALE',
      'B1',
      1,
      6,
      9.06,
      12.53,
      28.67,
      27.85,
      19.77,
      null,
      11.72,
      117.53,
      8.94,
      32.3,
      null,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.86,
      15.14,
      30.7,
      32.22,
      30.25,
      null,
      10.77,
      129.12,
      12.24,
      39.55,
      null,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.62,
      16.95,
      33.04,
      36.21,
      39.49,
      null,
      10.19,
      138.62,
      16,
      45.73,
      null,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.42,
      18.72,
      34,
      39.97,
      45.25,
      null,
      9.74,
      145.37,
      19.41,
      50.55,
      null,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.82,
      20.36,
      36.82,
      43.26,
      52.38,
      null,
      9.38,
      155.05,
      22.8,
      55.77,
      null,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19.81,
      22.13,
      38.98,
      46.46,
      59.1,
      null,
      9.01,
      164.72,
      26.07,
      60.98,
      null,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      24.41,
      23.68,
      43.22,
      49.96,
      67.06,
      null,
      8.55,
      182.91,
      18.77,
      35.95,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      30.12,
      26.99,
      45.88,
      53.92,
      81.88,
      null,
      7.97,
      201.99,
      22.11,
      44.71,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.65,
      28.55,
      48.71,
      56.41,
      89.15,
      null,
      7.51,
      2154.79,
      24.61,
      50.85,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      36.86,
      27.96,
      47.3,
      55.65,
      80.93,
      null,
      7.6,
      213.72,
      23.44,
      48.52,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      39.01,
      29.7,
      48.06,
      56.73,
      86.9,
      null,
      7.32,
      218.07,
      24.45,
      52.14,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      40.54,
      30.6,
      49.8,
      57.61,
      89.09,
      null,
      7.29,
      221.9,
      25.12,
      54,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.52,
      12.23,
      30.4,
      27.14,
      16.78,
      null,
      12.02,
      109.83,
      6.2,
      32.42,
      null,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      10.27,
      14.7,
      33.09,
      31.14,
      23.99,
      null,
      11.09,
      121.52,
      8.2,
      40.05,
      null,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.88,
      16.11,
      35.76,
      34.39,
      29.57,
      null,
      10.51,
      129.73,
      10.36,
      46,
      null,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.9,
      17.85,
      37.54,
      38.11,
      35.09,
      null,
      10.03,
      137.48,
      12.5,
      51.55,
      null,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      16.48,
      19.14,
      40.97,
      41.06,
      40.61,
      null,
      9.67,
      147.41,
      14.8,
      57.09,
      null,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      19.33,
      20.29,
      43.37,
      43.47,
      44.92,
      null,
      9.35,
      154.23,
      16.56,
      61.44,
      null,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.68,
      20.03,
      45.64,
      45.45,
      46.26,
      null,
      9.16,
      163.59,
      11.84,
      44.51,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.87,
      21.95,
      47.48,
      47.84,
      53.16,
      null,
      8.88,
      169.94,
      13.3,
      49.95,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.96,
      22.42,
      49.75,
      48.18,
      52.71,
      null,
      8.81,
      171.94,
      13.99,
      51.8,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.14,
      20.96,
      47.81,
      46.61,
      44.51,
      null,
      9.12,
      166.01,
      12.91,
      47.23,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      25.93,
      22.25,
      47.74,
      47.01,
      47.27,
      null,
      9.01,
      167.71,
      13.51,
      49.14,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.39,
      22.69,
      49.17,
      47.15,
      48.06,
      null,
      8.95,
      169.34,
      14.08,
      50.2,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
  '45': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.66,
      11.19,
      27.11,
      28.29,
      17.85,
      null,
      11.88,
      113.81,
      8.33,
      30.2,
      116.28,
      21.46,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.34,
      13.91,
      28.83,
      31.71,
      26.87,
      null,
      10.89,
      125.71,
      11.95,
      37.38,
      122.45,
      24.46,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.05,
      16.16,
      30.52,
      35.56,
      35.02,
      null,
      10.29,
      134.85,
      15.38,
      43.36,
      128.1,
      27.75,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.02,
      17.53,
      32,
      38.83,
      41.83,
      null,
      9.88,
      142.61,
      18.6,
      48.26,
      133.49,
      31.62,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.13,
      19.37,
      34.6,
      42.21,
      48.42,
      null,
      9.47,
      151.37,
      22.03,
      53.68,
      139.17,
      35.7,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19.3,
      21.55,
      37.03,
      45.59,
      57.08,
      null,
      9.09,
      162.46,
      25.32,
      59.6,
      145.62,
      40.15,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      24.13,
      23.61,
      41.89,
      48.6,
      65.83,
      444.45,
      8.75,
      182.8,
      18.28,
      34.53,
      153.37,
      45.72,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.82,
      26.5,
      46.16,
      52.48,
      82.16,
      410.27,
      8.06,
      201.49,
      21.54,
      43.4,
      160.71,
      50.87,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.55,
      28.52,
      49.36,
      55.41,
      90.45,
      405.63,
      7.64,
      215.76,
      24.1,
      49.99,
      165.28,
      55.28,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      38.07,
      28.16,
      50.58,
      56.66,
      84.41,
      413.29,
      7.42,
      223.54,
      25.45,
      52.33,
      167.78,
      59.48,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      40.53,
      29.87,
      53.04,
      59.11,
      89.16,
      376.84,
      7.26,
      230.32,
      27.25,
      56.49,
      169.27,
      60.83,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      41.97,
      31.18,
      54.46,
      59.87,
      91.41,
      365.44,
      7.14,
      232.71,
      28.24,
      58.84,
      169.93,
      62.44,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      43.21,
      20.21,
      40,
      42.21,
      46.14,
      null,
      8.83,
      192.5,
      27.5,
      40.43,
      168.79,
      64.47,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.12,
      10.73,
      29.09,
      27.36,
      15,
      null,
      12.24,
      104.98,
      5.6,
      29.83,
      115.49,
      21.19,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.69,
      13.4,
      31.04,
      30.79,
      21.18,
      null,
      11.19,
      116.76,
      7.56,
      37.41,
      121.45,
      23.85,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.28,
      15.22,
      33.11,
      34.39,
      26.79,
      null,
      10.63,
      125.95,
      9.53,
      43.48,
      127.43,
      27.24,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.37,
      16.89,
      35.15,
      37.4,
      32.96,
      null,
      10.1,
      134.51,
      11.75,
      49.34,
      133.82,
      30.86,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      15.81,
      18.5,
      38,
      40.45,
      38.96,
      null,
      9.62,
      144.58,
      13.79,
      55.25,
      140.76,
      35.54,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      18.78,
      19.53,
      40.72,
      43.03,
      44.25,
      null,
      9.35,
      151.83,
      15.92,
      60.08,
      147.35,
      40.61,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.36,
      19.83,
      44.08,
      44.17,
      46.84,
      314.36,
      9.3,
      161.33,
      11.17,
      42.64,
      151.8,
      44.89,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.57,
      21.87,
      46.76,
      46.76,
      54,
      299.74,
      8.96,
      170.15,
      12.78,
      48.72,
      154.41,
      47.67,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      24.89,
      22.57,
      48.44,
      47.63,
      54.46,
      307.78,
      8.91,
      172.2,
      13.62,
      51.02,
      155.59,
      49.95,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.7,
      21.3,
      48.47,
      47.66,
      47.79,
      326.28,
      8.91,
      173.2,
      14.03,
      50.43,
      156.26,
      50.83,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      26.28,
      22.34,
      50.52,
      48.73,
      50.39,
      304.55,
      8.89,
      175.53,
      14.76,
      52.68,
      156.68,
      52.13,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      27.16,
      23.04,
      51.36,
      49.25,
      51.15,
      302.19,
      8.84,
      176.38,
      15.36,
      54.12,
      157.02,
      52.69,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      25.57,
      14.14,
      42.43,
      36.43,
      21.43,
      600,
      10.74,
      131,
      11.43,
      31,
      154.49,
      49.21,
    ],
  ],
  '46': [],
  // 47 from 2022, 沖縄県, 現在のALPHAと同じ数値で問題なし
  '47': [
    [
      'MALE',
      'B1',
      1,
      6,
      8.82,
      10.25,
      26.31,
      27.19,
      15.99,
      null,
      12.01,
      113.72,
      8.87,
      null,
      115.83,
      21.54,
    ],
    [
      'MALE',
      'B1',
      2,
      7,
      10.54,
      12.86,
      27.87,
      30.13,
      21.18,
      null,
      11.15,
      125.89,
      12.12,
      null,
      121.67,
      24.24,
    ],
    [
      'MALE',
      'B1',
      3,
      8,
      12.52,
      15.1,
      29.23,
      34.13,
      30.06,
      null,
      10.58,
      136.4,
      15.67,
      null,
      127.17,
      27.42,
    ],
    [
      'MALE',
      'B1',
      4,
      9,
      14.42,
      16.83,
      31.13,
      37.08,
      36.61,
      null,
      10.03,
      143.5,
      19.63,
      null,
      132.77,
      31.21,
    ],
    [
      'MALE',
      'B1',
      5,
      10,
      16.73,
      18.48,
      33.3,
      40.04,
      43.54,
      null,
      9.59,
      152.1,
      21.1,
      null,
      138.33,
      34.88,
    ],
    [
      'MALE',
      'B1',
      6,
      11,
      19.69,
      20.33,
      35.33,
      43.6,
      32.13,
      null,
      9.11,
      164.08,
      26.69,
      null,
      144.87,
      39.2,
    ],
    [
      'MALE',
      'C1',
      1,
      12,
      24.22,
      22.39,
      39.1,
      36.83,
      61.12,
      469.19,
      8.62,
      183.23,
      18.7,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'C1',
      2,
      13,
      29.97,
      25.46,
      42.8,
      50.29,
      76.94,
      434.54,
      8.02,
      201.44,
      22.32,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'C1',
      3,
      14,
      34.77,
      27.47,
      46.96,
      53.22,
      83.37,
      411.71,
      7.65,
      215.15,
      25.16,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'D1',
      1,
      15,
      37.25,
      26.67,
      45.85,
      52.68,
      74.79,
      438.6,
      7.75,
      218.96,
      24.91,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'D1',
      2,
      16,
      39.92,
      28.36,
      48.44,
      45.48,
      81.52,
      422.87,
      7.56,
      224.84,
      26.34,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'D1',
      3,
      17,
      41.75,
      29.39,
      50.78,
      55.89,
      83.76,
      432.48,
      7.48,
      229.41,
      27.6,
      null,
      null,
      null,
    ],
    [
      'MALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'B1',
      1,
      6,
      8.14,
      10.08,
      27.62,
      26.45,
      14.05,
      null,
      12.49,
      105.56,
      5.73,
      null,
      115.18,
      21.13,
    ],
    [
      'FEMALE',
      'B1',
      2,
      7,
      9.82,
      12.4,
      30.09,
      29.34,
      18.29,
      null,
      11.52,
      116.72,
      7.54,
      null,
      121.06,
      23.84,
    ],
    [
      'FEMALE',
      'B1',
      3,
      8,
      11.7,
      14.26,
      31.6,
      32.9,
      23.54,
      null,
      10.87,
      126.65,
      9.36,
      null,
      127.26,
      27.17,
    ],
    [
      'FEMALE',
      'B1',
      4,
      9,
      13.83,
      16.08,
      34.22,
      35.86,
      29.05,
      null,
      10.34,
      135.49,
      11.89,
      null,
      133.94,
      31.1,
    ],
    [
      'FEMALE',
      'B1',
      5,
      10,
      16.54,
      17.67,
      37.24,
      38.66,
      35.43,
      null,
      9.83,
      144.51,
      14.24,
      null,
      140.77,
      35.44,
    ],
    [
      'FEMALE',
      'B1',
      6,
      11,
      19.35,
      18.57,
      39.45,
      41.24,
      40.31,
      null,
      9.48,
      152.53,
      16.25,
      null,
      146.87,
      40.41,
    ],
    [
      'FEMALE',
      'C1',
      1,
      12,
      21.45,
      18.95,
      42.45,
      43.55,
      43.09,
      342.11,
      9.25,
      162.69,
      11.94,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'C1',
      2,
      13,
      23.56,
      21.45,
      45.3,
      46.41,
      50.37,
      324.53,
      9.06,
      170.11,
      3.7,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'C1',
      3,
      14,
      25.21,
      22.63,
      48.61,
      47.59,
      51.62,
      321.52,
      8.92,
      173.51,
      14.91,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'D1',
      1,
      15,
      25.07,
      21.52,
      46.55,
      46.66,
      42.42,
      344.84,
      9.26,
      170.87,
      14.34,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'D1',
      2,
      16,
      25.96,
      22.46,
      48.48,
      47.59,
      46.04,
      343.19,
      9.21,
      172.8,
      15.03,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'D1',
      3,
      17,
      26.5,
      22.99,
      50.01,
      47.68,
      45.79,
      342.45,
      9.23,
      172.77,
      15.23,
      null,
      null,
      null,
    ],
    [
      'FEMALE',
      'D1',
      4,
      18,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
  ],
}
